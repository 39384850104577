//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React, {
  useEffect,
  useState,
} from "react"

import {
  useParams,
  useLocation,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import {
  rsIsLoading,
  rsIsError,
} from '../../remote-state/core'

import {
  capabilities,
  checkCapabilities,
} from '../../remote-state/authorization'

import {
  facetID,
  useFacet
} from "../../remote-state/facets"

import {
  useCommunity,
  communityMemCnt,
  communityOwnCnt,
  useCommunityJoin,
  useCommunityLeave,
  communityAuthz,

  useCommunityRolesModify,

  ROLE_OWN,
} from '../../remote-state/communities'

import {
  useDesktop,
} from '../../components/ALScreenSizing'

import {
  ALSide,
  ALSideButton,
  ALSideList,
} from '../../components/ALSide'

import { ALHeroSide } from './ALComponents'

import ALLoading from '../../components/ALLoading'

//

export default function ALSideCID() {
  const { t } = useTranslation()
  const desktop = useDesktop()
  const location = useLocation()

  const facet_id = facetID( useFacet() )

  const { cid } = useParams()

  const cmty = useCommunity( cid )
  const count_own = communityOwnCnt( cmty )

  const cAuthz = communityAuthz( cmty )
  const mayEdit = checkCapabilities( cAuthz, capabilities.edit )
  const mayJoin = checkCapabilities( cAuthz, capabilities.join )
  const mayLeave = checkCapabilities( cAuthz, capabilities.leave )
  const mayAssumeOwner = ( count_own === 0 ) && ( ! mayJoin )

  const path = `/communities/${ cid }`

  const [ sel, setSel ] = useState('')

  const communityJoin = useCommunityJoin( cmty )
  const communityLeave = useCommunityLeave( cmty )
  const communityRoleModify = useCommunityRolesModify( cmty )

  useEffect( () => {
    const pathComp = location.pathname.split('/')
    if ( ! pathComp[3] && desktop ) { setSel('posts') }
    else if( desktop ) { setSel(pathComp[3]) }
    else { setSel('') }
  }, [ location, desktop ] )

  if( rsIsLoading( cmty ) ) return ( <ALSide><ALLoading /></ALSide>)
  if( rsIsError( cmty ) ) return ( <ALSide><p>t('err.error')</p></ALSide>)

  const listItems = [
    { label: t( 'mod.community.rules' ), to: `${path}/article/ENTER_ID_HERE`, selected: sel === 'article/ENTER_ID_HERE', disabled:true },
    { label: t( 'mod.community.posts' ), to: `${path}/posts`, selected: sel === 'posts' },
    { label: t( 'mod.community.articles' ), to: `${path}/articles`, selected: sel === 'articles', disabled: true },
    { label: t( 'mod.community.about' ), to: `${path}/about`, selected: sel === 'about' },
    { label: `${ t( 'rec.community.mem', { count: communityMemCnt( cmty ) } ) } (${ communityMemCnt( cmty ) })`, to: `${path}/members`, selected: sel === 'members' },
    ...( mayEdit ? [ { label: t( 'mod.community.admin.name' ), to: `${path}/admin`, selected: sel === 'admin' } ] : [] ),
  ]

  return (
    <ALSide>
      <ALHeroSide cmty={ cmty } />

      <ALSideList items={listItems} />
        { mayLeave && ( <ALSideButton onClick={ () => { communityLeave.mutate( {} ) } } label={ `${ t( 'act.leave' ) } ${ t( 'mod.community.name' ) }`} variant='outlined' /> ) }
        { mayJoin && ( <ALSideButton onClick={ () => { communityJoin.mutate() } }  label={ `${ t( 'act.join' ) } ${ t( 'mod.community.name' ) }`} /> ) }
        { mayAssumeOwner && ( <ALSideButton onClick={ () => { communityRoleModify.mutate( { op : 'add', role : ROLE_OWN, subject_id : facet_id } ) } }  label={ t( 'act.assume_owner' ) } /> ) }
    </ALSide>
  )
}
