//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'

//

export const useStyles = makeStyles({
  chip_group: props => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  }),
  chip: props => ({
    margin: '0.25rem',
  }),
})

function ALChip ( { ...props } ) {
  const classes = useStyles()

  return (
    <Chip
    className={classes.chip}
    variant='outlined'
    size='small'
    {...props}
    />
  )
}

function ALChipGroup( { children } ) {
  const classes = useStyles()

  return(
    <Box className={classes.chip_group}>
      {children}
    </Box>
  )
}

export {
  ALChip,
  ALChipGroup,
}