//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React from "react"

import ALNavbar from './ALNavbar.js'

export default function ALHeader() {
  return (
    <React.Fragment>
      <header>
        <ALNavbar />
      </header>
    </React.Fragment>
  )
}
