//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React, {
  useEffect,
  useState,
  useCallback,
} from "react"

import {
  useMutation,
} from 'react-query'

import { useTranslation } from 'react-i18next'

import { paramsForServer } from 'feathers-hooks-common'

import {
  useDropzone
} from 'react-dropzone'
import DropZone from 'react-dropzone'

import {
  cloneDeep,
  remove,
  isEqual,
} from "lodash"

import { useTheme } from "@emotion/react"

import { styled } from '@mui/system'

import {
  Box,
  Typography,
  Divider,

  IconButton,

  FormControl,
  FormLabel,
  TextField,
  FormControlLabel,
  FormHelperText,
  Switch,
  Checkbox,

  MenuItem,

  Alert,
  AlertTitle,

  Badge,

  Avatar,

  CircularProgress,
} from '@mui/material'

import {
  QuestionMark as QuestionMarkIcon,
  Help as HelpIcon,
  Search as SearchIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  ErrorOutlined as ErrorOutlineOutlinedIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  RemoveCircle as RemoveCircleIcon,

  Cloud as CloudIcon,
  CloudQueue as CloudQueueIcon,
  CloudSyncOutlined as CloudSyncOutlinedIcon,
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  CloudDoneOutlined as CloudDoneOutlinedIcon,
  SyncDisabled as SyncDisabledIcon,
} from '@mui/icons-material'

import { LoadingButton } from '@mui/lab'

import { rsIsLoading } from "../../remote-state/core"

import {
  useImage,
  imageUrl,

  getImageLimits,
  imageLimitsRatio,
} from '../../remote-state/images'

import {
  useFacet,
  facetID,
} from '../../remote-state/facets'

import {
  useFileLimits,
  fileLimitsList,

  useFileCreate,
  useFileUIPKeepAlive,
  useFileUIPClear,

  FilesUploader,
} from '../../remote-state/files'

import {
  useALService,
} from '../../components/client'

import feathersClient from '../../feathersClient/feathersClient.js'

import {
  useMobile,
} from '../ALScreenSizing'

import {
  fieldTextLangs,
} from '../ALI18N'

import {
  ALEditorField,
} from '../../components/ALDraftJS'

import {
  ALTile,
} from '../ALTile'

import ALLoading from "../ALLoading"

//

function uploadStatusIcon( status ) {
  switch( status?.code ) {
    case 'accepted' : {
      switch (status.message) {
        case 'preprocessing' : return <SearchIcon />
        case 'queued' : return <CloudQueueIcon />
        case 'uploading' : return <CloudSyncOutlinedIcon />
        case 'uploaded' : return <CloudIcon />
        default : return <QuestionMarkIcon />
      }
    }

    case 'ok' : {
      switch (status.message) {
        case 'finalized' : return <CloudDoneOutlinedIcon />
        default : return <QuestionMarkIcon />
      }
    }

    case 'canceled' : {
      switch (status.message) {
        case 'canceled' : return <SyncDisabledIcon />
        default : return <QuestionMarkIcon />
      }
    }

    case 'error' : {
      switch (status.message) {
        case 'canceled' : return <SyncDisabledIcon />
        default : return <ErrorOutlineOutlinedIcon />
      }
    }

    default : return <QuestionMarkIcon />
  }
}

function uploadStatusColor( status ) {
  switch( status?.code ) {
    case 'accepted' : return 'info'
    case 'ok' : return 'success'
    case 'canceled' : return 'warning'
    case 'error' : return 'error'
    default : return 'info'
  }
}

function CircularProgressWithLabel( { min=1, value=0, color, ...props } ) {
  const rvalue = Math.floor( value )
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant={ rvalue >= min ? "determinate" : 'indeterminate' } value={ value } { ...props }/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color={ color || 'text.info' } fontSize='0.4rem' >
          {`${ rvalue }%`}
        </Typography>
      </Box>
    </Box>
  );
}

function uploadStatusIcon2( status ) {
  switch( status?.code ) {
    case 'ready' : {
      switch( status.message ) {
        case 'ready' : return <CircularProgress color='info' size='1.8rem' />
        default : return <HelpIcon color='info' size='small' />
      }
    }

    case 'accepted' : {
      switch( status.message ) {
        case 'preprocessing' : return <CircularProgress color='info' size='1.8rem' />
        case 'queued' : return <CircularProgress color='info' size='1.8rem' />
        case 'uploading' : return <CircularProgressWithLabel value={ status.data.progress } size='1.8rem' />
        case 'uploaded' : return null
        default : return <HelpIcon color='info' size='small' />
      }
    }

    case 'ok' : {
      switch( status.message ) {
        case 'finalized' : return null
        default : return <HelpIcon color='info' size='small' />
      }
    }

    case 'live' : {
      switch( status.message ) {
        default : return null
      }
    }

    case 'canceled' : {
      switch( status.message ) {
        default : return <CancelIcon color='info' size='small' />
      }
    }

    case 'error' : {
      switch( status.message ) {
        default : return <ErrorIcon color='error' size='small' />
      }
    }

    default : break
  }

  return <HelpIcon color='error' size='small' />
}


function useUploadStatusMessage2( status ) {
  const { t } = useTranslation()
  switch( status?.code ) {
    case 'ready' :
    case 'accepted' :
    case 'ok' :
    case 'live' :
    case 'canceled' :
      return t( `status.${ status.code }.${ status.message }`, status.data)

    case 'error' :
      return t( 'status.error.error' )

    default : break
  }

  return t( 'status.error.unknown' )
}

//

function fieldChanged( state, id, setError, sub ) {
  return ! isEqual( state.values[ id ], state.ovalues[ id ] )
}

function fieldChangedOne( state, id, setError, sub ) {
  const fields = [ ...new Set( [ ...Object.keys( state.values ), ...Object.keys( state.ovalues ) ] ) ]
  const result = fields.reduce( ( acc, field ) => { if ( ! isEqual( state.values[ field ], state.ovalues[ field ] ) ) return true; return acc }, false )
  return result
}

function isRequired( check ) {
  return check && ( check.includes( fieldRequired ) || check.includes( fieldRequiredArray ) )
}

// TODO #401 replace all the form validation methods with Joi.  use befe/src/utilities/schema for field definitions.

function fieldRequired( state, id, setError, sub ) {
  const ok = ( ! ( state.values[ id ] == null ) ) && ( sub
    ? Object.keys( state.values[ id ] ).reduce( ( acc, sub ) => acc && ( state.values[ id ][ sub ] != null ), true )
    : true )

  if( ! ok && setError ) state.errors[ id ] = [ 'err.required' ]
  return ok
}

function useFieldRequiredOneOf( ids ) {
  return ( state, id, setError ) => {
    return ( ids || [] ).reduce( ( acc, elem ) => {
      const value = state.values[ elem ]

      if( Boolean( value?.length ) ) return true

      if( typeof value === 'object' ) {
        const keys = Object.keys( value ) || []
        const res = keys.reduce( ( prev, key ) => {
          return prev || Boolean( value[ key ].length )
        }, false )
        return acc || res
      }

      return acc
    }, false )
  }
}

function fieldRequiredArray( state, id, setError, sub ) {
  if( sub ) throw new Error()

  if( ! Array.isArray( state.values[ id ] ) || state.values[ id ]?.length < 1 ) {
    if( setError ) state.errors[id] = [ 'err.required' ]
    return false
  }
  return true
}

function useFieldStringMax( max ) {
  return( state, id, setError, sub ) => {
    const ok = sub
    ? Object.keys( state.values[ id ] ?? {} ).reduce( ( acc, sub ) => acc && ( state.values[ id ][ sub ] ? state.values[ id ][ sub ]?.length <= max : true ), true)
    : state.values[ id ] ? state.values[ id ]?.length <= max : true

    if( ! ok && setError ) {
      if( sub ) state.errors[ id ] = [ 'err.avail_char', { count : 'TO BE FIXED' } ] // #259 - how to report multiple errors on a single line?
      else state.errors[ id ] = [ 'err.avail_char', { count: ( max - state.values[id].length ) } ]
    }

    return ok
  }
}

function fieldEmail( state, id, setError, sub ) {
  if( sub ) throw new Error()

  if ( /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test( state.values[ id ] ) ) return true

  if( setError ) state.errors[id] = [ 'err.email_inval' ]
  return false
}

function fieldMatch( field, error_code ) {
  return (state, id, setError) => {
    if( ! field ) return false

    if( state.values[ field ] !== state.values[ id ] ) {
      if( setError ) {
        state.errors[ id ] = [ error_code ]
      }
      return false
    }
    return true
  }
}

//

function formOk( elems, state ) {
  for( let ei=0; ei<elems.length; ei++ ) {
    const elem = elems[ ei ]
    if( elem.check ) {
      for( let ci=0; ci < elem.check.length; ci++ ) {
        if( ! elem.check[ ci ]( state, elem.id, false, Boolean( elem.sub ) ) ) return false
      }
    }
  }
  return true
}

//

function ALFormSwitch( { id, name, checked, state, setState, resetForm, onChange, color, check, isSubmitting } ) {
  const required = isRequired( check )

  const handleChange = e => {
    let tState = cloneDeep( state )
    tState.values[ id ] = e.target.checked
    setState(tState)
    resetForm()
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={ state.values[id] || ( state.values[id] === undefined && checked ) ? state.values[id] : false }
          onChange={ onChange ? ( e ) => { onChange( e, handleChange) } : handleChange }
          id={id}
          name={id}
          color={ color ? color : 'default' }
          disabled={ isSubmitting ? true : state.submitSuccess ? true : false }
          required={ required ? true : false }
        />
      }
      label={ <>{ name }{ required ? ' *' : '' }</> }
    />
  )
}

function ALFormCheckbox( { id, name, state, checked, setState, resetForm, onChange, color, check, isSubmitting } ) {
  const required = isRequired( check )

  const handleChange = e => {
    let tState = cloneDeep( state )
    delete tState.values[ id ]
    if( e.target.checked ) tState.values[ id ] = e.target.checked
    setState(tState)
    resetForm()
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={ state.values[id] || ( state.values[id] === undefined && checked ) ? state.values[id] : false }
          onChange={ onChange ? ( e ) => { onChange( e, handleChange) } : handleChange }
          id={id}
          name={id}
          color={ color ? color : 'default' }
          disabled={ isSubmitting? true : state.submitSuccess ? true : false }
          required={ required ? true : false }
        />
      }
      label={ <>{ name }{ required ? ' *' : '' }</> }
      />
  )
}

function ALFormTextField( { type, margin, id, sub, name, value, placeholder, state, setState, resetForm, onChange, autoFocus, minRows, maxRows, check, select, SelectProps, options, isSubmitting, disabled }) {
  const { t } = useTranslation()
  const required = isRequired( check )

  const dispValue = sub
    ? state.values[ id ]?.[ state.values?.[ sub ] ] ?? ( select ? [] : '' )
    : state.values[ id ] ?? ''

  const handleChange = e => {
    let tState = cloneDeep( state )

    if( ! sub ) {
      if( e.target.value ) tState.values[ id ] = e.target.value
      else delete tState.values[ id ]
    }
    else {
      tState.values[ id ] = { ...tState.values[ id ] }
      if( ! e.target.value ) {
        delete tState.values[ id ][ tState.values[ sub ] ]
        if( ! Object.keys( tState.values[ id ] ).length ) delete tState.values[ id ]
      }
      else tState.values[ id ][ tState.values[ sub ] ] = e.target.value
    }

    delete tState.errors[ id ]

    if( check ) {
      for( let idx=0; idx < check.length; idx++ ) {
        if( ! check[idx](tState, id, true, Boolean( sub ) ) ) break
      }
    }

    setState( tState )
    resetForm()
  }

  return (
    <TextField
      type={ type || 'text' }
      select={ select ? true : false }
      SelectProps={ SelectProps || null }
      fullWidth
      margin={ margin ? margin : 'dense' }
      size='small'
      variant='filled'
      id={id}
      name={id}
      label={ name ? name : null }
      value={ dispValue }
      placeholder={ placeholder }
      onChange={ onChange ? ( e ) => { onChange( e, handleChange) } : handleChange }
      error={ state.errors[id] ? true : false }
      helperText={ state.errors[id] ? t( ...state.errors[id] ) : ' ' }
      disabled={ disabled || ( isSubmitting? true : ( state.submitSuccess ? true : false ) ) }
      multiline={ minRows > 1 || maxRows > 1 ? true : false }
      minRows={ minRows }
      maxRows={ maxRows }
      required={ required ? true : false }
      autoFocus={ autoFocus ? true : false }
    >
      { Array.isArray( options ) && options.map( ( elem, index ) => ( <MenuItem value={ elem.value } disabled={ elem.disabled } key={ index }>{ elem.label }</MenuItem>) ) }
    </TextField>
  )
}

function ALFormEditor( { id, sub, state, setState, resetForm, value, type, check, isSubmitting } ) {
  const { t } = useTranslation()
  const handleChange = e => {
    let tState = cloneDeep( state )

    if( ! sub ) {
      if( e ) tState.values[ id ] = e
      else delete tState.values[ id ]
    }
    else {
      tState.values[ id ] = { ...tState.values[ id ] }
      if( e ) tState.values[ id ][ tState.values[ sub ] ] = e
      else {
        delete tState.values[ id ][ tState.values[ sub ] ]
        if( ! Object.keys( tState.values[ id ] ).length ) delete tState.values[ id ]
      }
    }

    delete tState.errors[ id ]

    if( check ) {
      for( let idx=0; idx < check.length; idx++ ) {
        if( ! check[idx](tState, id, true, Boolean( sub ) ) ) break
      }
    }

    setState( tState )
    resetForm()
  }

  return (
    <ALEditorField
      id={ id }
      value={ sub ? value[ state.values[ sub ] ] : value }
      sid={ sub ? state.values[ sub ] : null }
      setState={ handleChange }
      error={ state.errors[id] ? true : false }
      helperText={ state.errors[id] ? t( ...state.errors[id] ) : ' ' }
      disabled={ isSubmitting }
    />
  )
}

function ALFormHidden( { id, state, setState, value } ) {
  // NOTE - ALForm transfers value into state.
  return ( <></> )
}

function ALFormText( { name } ) {
  return <Box sx={{width: '100%'}}>{ name }</Box>
}

// ----- DropZoneArea

const DropZoneArea = styled( Box, {
  shouldForwardProp: prop => prop !== 'isFocused' && prop !== 'isDragAccept' && prop !== 'isDragReject' && prop !== 'sx',
  name: 'DropZoneArea',
  slot: 'Root',
} ) ( ( { theme, isFocused, isDragAccept, isDragReject, isError, isSubmitting } ) => ( {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '6rem',
  borderWidth: 2,
  borderRadius: '0.25rem',
  borderStyle: 'solid',
  borderColor : isFocused ? theme.palette.primary.light : isDragAccept ? theme.palette.success.light: isError ? theme.palette.error.light : theme.palette.border.input_filled,
  color : isFocused ? theme.palette.primary.light : isDragAccept ? theme.palette.success.light: isError ? theme.palette.error.light : theme.palette.text.secondary,
  outline: 'none',
  transition: 'border .24s ease-in-out',
}))

// ----- ImageLoader

function ImageLoader( { file, role, height, width, fileLimits, onNewImageReady, status, setStatus, formStatus } ) {
  const { mutateAsync : doCreate } = useFileCreate()
  const { mutateAsync : doKeepAlive } = useFileUIPKeepAlive()
  const { mutateAsync : doDelete } = useFileUIPClear()

  const [ editFile, setEditFile ]= useState( {} )

  const onUpdate = useCallback( ( id, status ) => { setStatus( status ) }, [ setStatus ] )

  const onUploaded = useCallback( async ( id, newFileID ) => {
    onNewImageReady( newFileID )
  }, [ onNewImageReady ] )

  // Manage Preview
  useEffect( () => {
    if( file && ! file.preview ) {
      Object.assign( file, { preview: URL.createObjectURL( file ) } )

      setEditFile( file )

      FilesUploader.enqueue( {
        role,
        file,
        fileLimits : fileLimitsList( fileLimits ),
        id: file.preview,
        doCreate,
        doDelete,
        doKeepAlive,
        onUpdate,
        onError : onUpdate,
        onUploaded,
      } )
    }
  }, [ file, role, fileLimits, doCreate, doDelete, doKeepAlive, onUploaded, onUpdate ] )

  // Finalize file if successfully submitted
  useEffect( () => {
    if( formStatus === 'success' ) {
      FilesUploader.finalize( { id: file.preview } )
      file.finalized = true
    }
  }, [ formStatus, file ] )

  // Cleanup - File
  useEffect( () => {
    return () => {
      if( file?.preview ) {
        URL.revokeObjectURL( file.preview )
        delete file.preview
      }
      if( ! file.finalized ) FilesUploader.cancel( { id: file.preview } )
    }
  }, [ file ] )

  return (
    <Badge
      overlap='circular'
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={ uploadStatusIcon( status ) }
      color={ uploadStatusColor( status ) }
    >
      <Avatar src={ editFile?.preview } variant='rounded' sx={{ height, width }} />
    </Badge>
  )
}

function ALFormImage( { id, value, state, setState, resetForm, isSubmitting, formStatus, role, ...props } ) {
  const { t } = useTranslation()
  const avatarUrl = imageUrl( useImage( value ) )
  const fileLimits = useFileLimits( { role } )
  const imageLimits = getImageLimits( fileLimits )
  const defaultStatus = { code : 'ready', message : 'ready', data : {} }
  const [ status, setStatus ] = useState( defaultStatus )
  const [ file, setFile ] = useState()
  const h = 6
  const height = h + 'rem'
  const width = (h * imageLimitsRatio( imageLimits ) ) + 'rem'

  useEffect( () => {
    if( formStatus === 'success' ) setFile( null )
  }, [ formStatus ] )

  const onNewImageReady = useCallback( ( file_id ) => {
    const tvalues = { ...state.values }
    tvalues[ id ] = file_id

    const twip = { ...state.wip }
    twip[ id ] = 0

    setState( { ...state, values : tvalues, wip: twip } )
  }, [ id, state, setState ] )

  // onDrop
  function onDrop( files ) {
    setStatus( defaultStatus )
    resetForm()
  }

  // onDropAccepted
  function onDropAccepted( acceptedFiles ) {
    Object.assign( acceptedFiles[ 0 ], { role } )
    setFile( acceptedFiles[ 0 ] )

    const twip = { ...state.wip }
    twip[ id ] = 1

    setState( { ...state, wip: twip } )
  }

  //const accept = imageLimitsTypes( imageLimits )    // XXX figure out how I want to deal with this and then back port into previous commit
  const dropzoneLimits = { /*accept,*/ minSize: 0, maxSize: Infinity }

  const {
    getRootProps,
    isFocused,
    isDragAccept,
    isDragReject,
    getInputProps,
    fileRejections,
  } = useDropzone( {
    disabled : isSubmitting,
    //accept,
    maxFiles : 1,
    minFiles : 1,
    onDrop,
    onDropAccepted,
  } )

  if( rsIsLoading( fileLimits ) ) return <ALLoading/>

  const isError = ! isDragAccept && ( isDragReject || status.code === 'error' )
  const statusMessage = fileRejections?.[0]?.errors?.[0]?.code
    ? { message : 'status.error.dropzone.' + fileRejections?.[0]?.errors?.[0]?.code, data: dropzoneLimits }
    : { message : `status.${ status.code }.${ status.message }`, data : status.data }

  return (
    <Box>
      <Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' gap='0.5rem' >
        { ! file && <Avatar src={ avatarUrl } variant='rounded' sx={{ height, width }} /> }
        {   file && <ImageLoader file={ file } role={ role } fileLimits={ fileLimits } status={ status } setStatus={ setStatus } oldURL={ avatarUrl } height={ height } width={ width } state={ state } onNewImageReady={ onNewImageReady } formStatus={ formStatus } /> }

        <DropZoneArea { ...getRootProps( { isFocused, isDragAccept, isError, isSubmitting } )} minWidth='6rem' sx={{ boxShadow: 0 }} >
          <input {...getInputProps() } />
          <CloudUploadOutlinedIcon fontSize='large' />
          <Box textAlign='center' >{ t( 'act.dropzone.generic' ) }</Box>
        </DropZoneArea>
      </Box>
      <FormHelperText margin='dense' error={ isError } sx={{ mx: 0, textAlign : 'left' }}>{ file ? t( statusMessage.message, statusMessage.data ) : ' ' }</FormHelperText>
    </Box>
  )
}

// TileStatus


function TileStatus( { bgImage, blur, onClick, children } ) {
  const theme = useTheme()

  return (
    <Box
      onclick={ onClick }
      sx={{
        width: '100%',
        aspectRatio: '1',
        //objectFit : 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        ...( bgImage
          ? {
            backgroundPosition : 'center',
            backgroundSize : 'cover',
          }
          : {
            backgroundColor : theme.palette.background.disabledBackground,
          }
        ),
        ...( blur
          ? {
            ':after' : {
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '100%',
              //backgroundColor: 'rgba(0,0,0,0.6)',
              backgroundColor: theme.palette.background.disabledBackground,
              //WebkitBackdropFilter: 'blur(5px)',
              backdropFilter: 'blur(5px)',
              zIndex: 0,
            }
          }
          : {}
        ),
      }}
      style={{ backgroundImage: bgImage ? `url(${ bgImage })` : undefined }}
    >
      { children }
    </Box>
  )
}

// ----- FormTileImage

function FormTileImage( { elem : wi, idx, onClick } ) {
  const theme = useTheme()
  const mobile = useMobile()
  const status = wi.status
  const statusIcon = uploadStatusIcon2( status )
  const statusMessage = useUploadStatusMessage2( status )
  const image = useImage( wi.id )
  const url = wi.url ?? imageUrl( image )
  return (
    <>
        <TileStatus bgImage={ url } blur={ Boolean( statusIcon ) }>
          <Box onClick={ onClick ? () => onClick( idx ) : undefined } sx={{ zIndex: 1, width: 1, height: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Box sx={{ height: '2rem', width: 1, p: '0.25rem', backgroundColor: theme.palette.background.bar, color: theme.palette.error.contrastText, display: 'flex', flexWrap: 'nowrap', alignItems: 'center', justifyContent: mobile ? 'center' : 'flex-start' }}>
              { statusIcon }
              { ! mobile && Boolean( statusIcon ) &&
                <Typography variant='caption' sx={{ ml: '0.25rem' }}>
                  { statusMessage }
                </Typography>
              }
              { Boolean( wi.actions ) && <Box ml='auto' sx={{ zIndex:4 }}>{ wi.actions }</Box> }
            </Box>
          </Box>
        </TileStatus>
    </>
  )
}

// ----- FormCarouselTextField

function FormCarouselTextField( { wi, field, maxWidth, lang } ) {
  const value = wi[ field ].value[ lang ] || ''
  const label = wi[ field ].label
  const place = wi[ field ].placeholder

  const max = wi[ field ].max
  const length = value?.length || 0

  function onChange( e ) {
    const newvalue = { ...wi[ field ].value, [ lang ] : e.target.value.substring( 0, max ) }
    wi[ field ].value = newvalue
    wi.updateWIMeta( wi.url ?? wi.id, { ...wi } )
  }

  return (
    <TextField
      type={ 'text' }
      fullWidth
      sx={{ maxWidth : maxWidth }}
      margin={ 'dense' }
      size='small'
      variant='filled'
      id={ field }
      name={ field }
      label={ label }
      value={ value }
      placeholder={ place }
      onChange={ onChange }
      error={ length > max }
      helperText={ ( length + 10 ) > max ? `${ length } / ${ max }` : ' ' }
      multiline={ true }
      rows={ 3 }
      onKeyDown={ e => e.stopPropagation() }
    />
  )
}

// ----- FormCarouselLanguages

function FormCarouselLanguages( { maxWidth, langs, lang, setLang } ) {
  const { t } = useTranslation()
  return (
    <TextField
      select={ true }
      fullWidth
      sx={{ maxWidth : maxWidth }}
      margin={ 'dense' }
      size='small'
      variant='filled'
      id={ 'languages' }
      label={ t( 'rec.form.lang' ) }
      value={ lang }
      onChange={ e => setLang( e.target.value ) }
    >
      { Array.isArray( langs ) && langs.map( (elem, index) => ( <MenuItem value={elem.value} key={index}>{elem.label}</MenuItem>) ) }
    </TextField>
  )
}

// ----- FormCarouselImage

function FormCarouselImage( { elem : wi, viewHeight, langs, lang } ) {
  const { t } = useTranslation()
  const theme = useTheme()
  const status = wi.status
  const url = wi.url
  const alt = fieldTextLangs( wi?.alt, [ lang ], true )
  const [ show, setShow ] = useState( true )
  const [ prevStatus, setPrevStatus ] = useState( status )

  const [ lang_cur, setLangCur ] = useState( lang )

  useEffect( () => {
    if( status !== prevStatus ) {
      setShow( true )
      setPrevStatus( status )
    }
  }, [ status, prevStatus ] )

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'column nowrap', width:'100%' }} >
      { show && <Alert severity={ status.message === 'uploaded' ? 'success' : status.code === 'error' ? 'error' : 'info' } onClose={ () => { setShow( false ) } } sx={{ mt: '0.5rem', width: 'calc( 100vw - 2rem )', maxWidth: 'calc( 40rem - 2rem )' }}>{ t( `status.${ status.code }.${ status.message }`, status.data ) }</Alert> }
      <FormCarouselLanguages maxWidth='40rem' langs={ langs } lang={ lang_cur } setLang={ setLangCur } />
      <FormCarouselTextField wi={ wi } field='cap' maxWidth='40rem' lang={ lang_cur } />
      <FormCarouselTextField wi={ wi } field='alt' maxWidth='40rem' lang={ lang_cur } />
      { Boolean( wi.removeItem ) && <Box sx={{ backgroundColor: theme.palette.background.input_filled, width: 1, maxWidth: '40rem', mb: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}><IconButton size='small' color='info' onClick={ ( event ) => { event.stopPropagation(); wi.removeItem() } } ><DeleteIcon/></IconButton></Box> }
      <img src={ url } alt={ alt } style={{ maxWidth: '100vw', maxHeight: viewHeight, objectFit: 'contain' }} />
    </Box>
  )
}

// ----- ALFormMedia

function ALFormMedia( { ...props } ) {
  const { t } = useTranslation()
  const { mutateAsync : doCreate } = useFileCreate()
  const { mutateAsync : doKeepAlive } = useFileUIPKeepAlive()
  const { mutateAsync : doDelete } = useFileUIPClear()

  return <ALFormMediaInt
    { ...props }
    doCreate={ doCreate }
    doKeepAlive={ doKeepAlive }
    doDelete={ doDelete }
    alt={ t('rec.form.alt' ) }
    alt_place={ t('rec.form.alt-place' ) }
    cap={ t('rec.form.cap' ) }
    cap_place={ t('rec.form.cap-place' ) }
    t={ t }
  />
}

class ALFormMediaInt extends React.Component {
  constructor( props ) {
    super( props )

    this.maxFilesDefault = 10

    this.setStatus = this.setStatus.bind( this )

    this.updateWIMeta = this.updateWIMeta.bind( this )

    this.validator = this.validator.bind( this )
    this.onDragEnter = this.onDragEnter.bind( this )
    this.onDrop = this.onDrop.bind( this )
    this.onDropAccepted = this.onDropAccepted.bind( this )
    this.onDropRejected = this.onDropRejected.bind( this )

    this.onUpdate = this.onUpdate.bind( this )
    this.onUploaded = this.onUploaded.bind( this )

    this.removeItem = this.removeItem.bind( this )

    const wip = ( props.value ?? [] ).map(
      elem => { return {
        ...elem,
        alt: { value : elem.alt ?? {}, label : this.props.t( 'rec.form.alt' ), placeholder : this.props.t( 'rec.form.alt-place' ), max : 200 },
        cap : { value : elem.caption ?? {}, label : this.props.t( 'rec.form.cap' ), placeholder : this.props.t( 'rec.form.cap-place' ), max : 200 },
        status : { code : 'live', message : 'uploaded' },
        updateWIMeta : this.updateWIMeta,
        actions : <Box>
          <IconButton size='small' sx={{ padding: '0.125rem', ':hover' : { backgroundColor: 'rgba( 255,255,255,0.33 )' } }} color='info' onClick={ ( event ) => { event.stopPropagation(); this.removeItem( elem.id ) } }><RemoveCircleIcon/></IconButton>
        </Box>,
      } }
    )

    this.state = {
      wip : wip,
      status : {},
    }
  }

  // mounted
  componentDidMount() {
    this.defaultStatus = { code : 'ready', message : 'ready', data : {} }
  }

  // umount
  componentWillUnmount() {
    const wip = this.state.wip
    wip.forEach( wi => {
      URL.revokeObjectURL( wi.url )
      FilesUploader.cancel( { id: wi.url } )
    })
  }

  // setStatus

  setStatus( status ) {
    this.setState( { status: status } )
  }

  // validator
  validator( file ) {
    if( ( this.props.maxFiles || this.maxFilesDefault ) - this.state.wip.length <= 0 ) return { code : 'too-many-files', message: '' }
    return null
  }

  // onDragEnter

  onDragEnter() {
    this.setStatus( {} )
  }

  // onDrop

  onDrop( files ) {
  }

  // onDropAccepted

  onDropAccepted( acceptedFiles ) {
    const wipNew = acceptedFiles.map( file => {
      const url = URL.createObjectURL( file )
      const wi = {
        file,
        status : { ...this.defaultStatus },
        url : url,
        updateWIMeta: this.updateWIMeta,
        alt : { value : {}, label : this.props.t( 'rec.form.alt' ), placeholder : this.props.t( 'rec.form.alt-place' ), max : 200 },
        cap : { value : {}, label : this.props.t( 'rec.form.cap' ), placeholder : this.props.t( 'rec.form.cap-place' ), max : 200 },
        actions : <Box>
          <IconButton size='small' sx={{ padding: '0.125rem', ':hover' : { backgroundColor: 'rgba( 255,255,255,0.33 )' } }} color='info' ><EditIcon/></IconButton>
          <IconButton size='small' sx={{ padding: '0.125rem', ':hover' : { backgroundColor: 'rgba( 255,255,255,0.33 )' } }} color='info' onClick={ ( event ) => { event.stopPropagation(); this.removeItem( url ) } }><DeleteIcon/></IconButton>
        </Box>,
        removeItem: () => this.removeItem( url ),
      }
      return wi
    } )

    this.setState( ( state, props ) => {
      const wipold = [ ...state.wip ]
      const wip = wipold.concat( wipNew )

      this.updateFormState( wip )

      return { wip: wip }
    } )

    wipNew.forEach( wi => {
      FilesUploader.enqueue( {
        role: this.props.role,
        file : wi.file,
        fileLimits : this.props.fileLimits,
        id: wi.url,
        doCreate: this.props.doCreate,
        doDelete: this.props.doDelete,
        doKeepAlive: this.props.doKeepAlive,
        onUpdate : this.onUpdate,
        onError : this.onUpdate,
        onUploaded : this.onUploaded,
      } )
    } )
  }

  // onDropRejected

  onDropRejected( rejectedFiles ) {
    const errors = {}

    rejectedFiles.forEach( elem => {
      const code = elem.errors[ 0 ].code
      errors[ code ] = ( errors[ code ] ? errors[ code ] : 0 ) + 1
    } )

    const keys = Object.keys( errors )
    const maxError = keys.reduce( ( prev, cur ) => ( errors[ cur ] > errors[ prev ] ) ? cur : prev, keys[ 0 ] )

    this.setStatus( { code: 'error', message: maxError } )
  }

  // onUpdate

  onUpdate( id, status ) {
    this.setState( ( state, props ) => {
      const wi = state.wip.find( wi => wi.url === id )
      if( ! wi ) {
        return {}
      }
      wi.status = status
      Object.assign( wi, { status } )
      const wip = [ ...state.wip ]

      this.updateFormState( wip )

      return  { wip: wip }
    })
  }

  // onUploaded

  async onUploaded ( id, file_id ) {
    this.setState( ( state, props ) => {
      const wi = state.wip.find( wi => wi.url === id )
      if( ! wi ) {
        return {}
      }

      Object.assign( wi, { file_id } )
      const wip = [ ...state.wip ]

      this.updateFormState( wip )

      return  { wip: wip }
    })
  }

  // removeItem

  removeItem( id ) {
    this.setState( ( state, props ) => {
      const wip = [ ...state.wip ]
      const wipRm = remove( wip, wi => ( wi.url === id || wi.id === id ) )

      if( wipRm.length > 1 ) console.log(`ALFormMedia - removeItem - wipRm.length: ${ wipRm.length } > 1`)

      wipRm.forEach( wi => {
        URL.revokeObjectURL( wi.url )
        FilesUploader.cancel( { id: wi.url } )
      } )

      this.updateFormState( wip )

      return { wip : wip }
    } )
  }

  // updatedWIMeta

  updateWIMeta( id, newwi ) {
    this.setState( ( state, props ) => {
      const wi = state.wip.find( wi => ( wi.url ?? wi.id ) === id )
      if( ! wi ) {
        return {}
      }
      Object.assign( wi, newwi )

      const wip = [ ...state.wip ]

      this.updateFormState( wip )

      return { wip : wip }
    } )
  }

  // update updateFormState

  updateFormState( wip ) {
    const newValues = wip.reduce( ( acc, wi ) => { if( wi.status.message === 'uploaded' ) acc.push( { id: wi.file_id ?? wi.id, alt: wi.alt?.value, caption: wi.cap?.value } ); return acc }, [] )
    const newWip = wip.reduce( ( acc, wi ) => { return acc + ( wi.status.message === 'uploaded' ? 0 : 1 ) }, 0 )

    this.props.setFormState( this.props.id, { values: newValues, wip: newWip } )
  }

  // render

  render() {
    const maxFiles = ( this.props.maxFiles || this.maxFilesDefault ) - this.state.wip.length
    const accept = ( this.props.fileLimits.reduce( ( acc, elem ) => { return [ ...acc, elem.types ] }, [] ) ).join()
    const maxSize = this.props.fileLimits.reduce( ( max, elem ) => { return Math.max( max, elem.size_max ) }, 0 )
    const isError = this.state.status.code === 'error'

    return (
      <Box>
        { Boolean( this.state.wip.length ) && <ALTile objs={ this.state.wip } Tile={ FormTileImage } CarouselTile={ FormCarouselImage } carouselTileProps={{ langs : this.props.langs, lang : this.props.lang }} /> }
        <Box>
          <DropZone
            validator={ this.validator }
            onDragEnter={ this.onDragEnter }
            onDrop={ this.onDrop }
            onDropAccepted={ this.onDropAccepted }
            onDropRejected={ this.onDropRejected }
            maxFiles={ maxFiles }
            maxSize={ maxSize }
            disabled={ this.props.isSubmitting }
            accept={ accept }
            >
            { ( { getRootProps, isFocused, isDragAccept, isDragReject, getInputProps, fileRejections } ) => (<>
              <DropZoneArea { ...getRootProps( { isFocused, isDragAccept, isError : isDragReject, isWarning: maxFiles <= 0, isSubmitting: this.props.isSubmitting } )} minWidth='6rem' sx={{ boxShadow: 0 }} >
                <input {...getInputProps() } />
                <CloudUploadOutlinedIcon fontSize='large' />
                <Box textAlign='center' >{ this.props.t( maxFiles > 0 ? 'act.dropzone.generic' : 'status.error.dropzone.max-files' ) }</Box>
              </DropZoneArea>
            </>
            ) }
          </DropZone>
          <FormHelperText margin='dense' error={ isError } sx={{ mx: 0, textAlign : 'left' }}>{ isError ? this.props.t( 'status.error.dropzone.' + this.state.status.message, { accept, maxFiles, maxSize : maxSize/1024 } ) : ' ' }</FormHelperText>
        </Box>
      </Box>
    )
  }
}

// ----- -----

const FormActions = styled( 'div' ) ( {
  padding: '0.5rem 1rem 0.5rem 1rem',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
})

//

function ALFormInputs( { margin, children } ) {
  return (
    <Box sx={{ p : margin !== 'none' ? '0.5rem 1rem 0.5rem 1rem' : '0' }}>
      { children }
    </Box>
  )
}

function ALFormActions( { children } ) {
  return (
    <FormActions>
      { children }
    </FormActions>
  )
}
function ALFormAlert( { children } ) {
  return (
    <Box >
      { children }
    </Box>
  )
}

//

function ALFormGroup ( { id, margin, label, elements, setFormState, state, setState, resetForm, columns, isSubmitting, formStatus } ) {
  return (
    <FormControl
      fullWidth
      margin={ margin ? margin : 'dense'}
      component='fieldset'
    >
      { label && <FormLabel component='legend'>{ label }</FormLabel> }
      <Box sx={{ display: 'grid', columnGap: '1rem', gridTemplateColumns: `repeat( ${ columns ? columns : 1 }, 1fr)` }} >
      { elements.map( (elem, index) => {
        const { Comp, ...props } = elem
        return (
          <Box key={index} sx={{ width: '100%' }}>
            { Comp === Divider && <Comp { ...props } /> }
            { Comp !== Divider && <Comp { ...props } setFormState={ setFormState } state={ state } setState={ setState } resetForm={ resetForm } isSubmitting={ isSubmitting } formStatus={ formStatus } /> }
          </Box>
        ) }
      ) }
      </Box>
    </FormControl>
  )
}

//

function useFormMutation( service, method, oid, op, onSuccess ) {
  const alService = useALService()
  const facet_id = facetID( useFacet() )

  return useMutation( async ( values ) => {
    switch (method) {
      case 'create':
        return await alService( service, method, values, paramsForServer( { facet_id } ) )

      case 'patch':
        return await alService( service, method, oid, { op: op, ...values }, paramsForServer( { facet_id } ) )

      case 'authenticate':
        return await feathersClient.authenticate( { strategy: 'local', ...values } )

      default:
        throw new Error( `Unknown submit method (${ method })` )
    }
  },
  {
    onSuccess: ( data, variables ) => { if( onSuccess ) onSuccess( data, variables ) },
  }
  )
}

export default function ALForm( { elements, margin, values, service, method, oid, op, onSubmit, onSuccess, submitName, submitProps, buttonSecondary, buttonSuccess, allowRepeat } ) {
  const { t } = useTranslation()
  const [ formStatus, setFormStatus ] = useState( 'ready' )
  const isSubmitting = formStatus === 'submitting'
  const tvalues = elements.reduce( ( acc, elem ) => { if( elem?.value) { acc[ elem.id ] = elem.value } ; return acc }, values || {} )
  const [ state, setState ] = useState({ values : tvalues, ovalues : { ...tvalues }, files : {}, wip : {}, errors : {} })
  const [ changed, setChanged ] = useState( new Date() )
  const wip = Object.keys( state.wip ).reduce( ( acc, key ) => { return ( acc || Boolean( state.wip[ key ] ) ) }, false )

  const resetForm = () => setChanged( new Date() )

  // Reset formStatus to 'ready' after notifying everyone of success
  useEffect( () => {
    if( formStatus === 'success' ) setFormStatus( 'ready' )
  }, [ formStatus ] )

  useEffect( () => {
    if( formStatus === 'ready'         && ! isEqual( state.values, state.ovalues ) ) setFormStatus( 'modified' )
    else if( formStatus === 'modified' &&   isEqual( state.values, state.ovalues ) ) setFormStatus( 'ready' )
  }, [ state, formStatus, setFormStatus] )

  const onFormSuccess = useCallback( (...args ) => {
    setState( { ...state, ovalues : cloneDeep( state.values ) } )
    setFormStatus( 'success' )
    if( onSuccess ) onSuccess( ...args )
  }, [ state, onSuccess ] )

  const { mutateAsync: submitForm , isSuccess, isError, error, reset } = useFormMutation( service, method, oid, op, onFormSuccess )
  const [ errorUpload, setErrorUpload ] = useState( undefined )

  function setFormState( id, update ) {
    setState( ( state, props ) => {
      const newState = cloneDeep( state )

      Object.keys( update ).forEach( key => {
        newState[ key ][ id ] = update[ key ]
      } )

      return newState
    } )
  }

  useEffect( () => {
    reset()
  }, [ changed, reset ] )

  const handleSubmit = async(e) => {
    const oldFormStatus = formStatus

    try {
      reset()

      setFormStatus( 'submitting' )

      e.preventDefault()

      // strip local values
      const values = onSubmit ? { ...onSubmit( state.values ) } : { ...state.values }

      const rm = elements.reduce( ( acc, elem ) => { if( elem.local ) { acc = [ elem.id, ...acc ] } return acc }, [] )
      rm.forEach( key => { delete values[ key ] } )

      // update state with hidden fields
      elements.forEach( elem => { if( elem.Comp === ALFormHidden && ! elem.local ) values[ elem.id ] = elem.value } )

      await submitForm( values )
    }
    catch ( e ) {
      setFormStatus( oldFormStatus )
    }
  }

  const ok = formOk(elements, state)

  return (
    <form method='POST' onSubmit={ handleSubmit } noValidate autoComplete='off' style={{ width: '100%' }}>
      <ALFormInputs margin={margin} >
        <ALFormGroup margin='none' elements={ elements } setFormState={ setFormState } state={ state } setState={ setState } resetForm={ resetForm } isSubmitting={ isSubmitting } formStatus={ formStatus } />
      </ALFormInputs>

      <ALFormActions>
        { ( ! allowRepeat &&   isSuccess ) && Boolean( buttonSuccess ) && React.cloneElement( buttonSuccess, { disabled : isSubmitting ? true : false } ) }

        { (   allowRepeat || ! isSuccess ) && Boolean( buttonSecondary ) && React.cloneElement( buttonSecondary, { disabled : isSubmitting ? true : false } ) }

        { (   allowRepeat || ! isSuccess ) &&
          <LoadingButton
            loading={ isSubmitting }
            type='submit'
            variant='contained'
            color='primary'
            disabled={ wip || state.submitSuccess || ! ok }
            {...submitProps}
          >
            { submitName || t('act.submit') }
          </LoadingButton>
        }
      </ALFormActions>

      <ALFormAlert>
        { isSuccess && (
          <>
            <Divider light />
            <Alert severity='success' onClose={ allowRepeat ? () => reset() : undefined } >{ t('msg.success_bang') }</Alert>
          </>
          )
        }
        { isError && (
          <>
            <Divider light />
            <Alert severity='error' onClose={ allowRepeat ? () => reset() : undefined } ><AlertTitle>{ t('err.error') }</AlertTitle>{ error.message }</Alert>
          </>
          )
        }
        { Boolean( errorUpload ) && (
          <>
            <Divider light />
            <Alert severity='error' onClose={ () => setErrorUpload( undefined ) } ><AlertTitle>{ t('err.error') }</AlertTitle>{ errorUpload }</Alert>
          </>
          )
        }
      </ALFormAlert>
    </form>
  )
}

export {
  fieldRequired,
  fieldChanged,
  fieldChangedOne,
  useFieldRequiredOneOf,
  fieldRequiredArray,
  useFieldStringMax,
  fieldEmail,
  fieldMatch,

  ALFormSwitch,
  ALFormCheckbox,
  ALFormTextField,
  ALFormHidden,
  ALFormText,
  ALFormImage,
  ALFormMedia,

  ALFormGroup,

  ALFormEditor,
}
