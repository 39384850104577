//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React from "react"

import { useTranslation } from "react-i18next"

import ReactMarkdown from 'react-markdown'

import { styled } from '@mui/system'

import Box from '@mui/material/Box'

import Card from '@mui/material/Card'

import Typography from '@mui/material/Typography'

import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'

import {
  useMobile,
} from '../components/ALScreenSizing'

import ALPage from '../components/ALPage'

// -----

const ALTimelineDot = styled( Box )( {
  padding: '0.25rem',
  margin: '1.25rem 0',
} )

const ALCardContent = styled( Card )( {
  textAlign: 'left',
  padding: '0 1rem',
} )

// -----

export default function ALFoundersStory() {
  const mobile = useMobile()
  const { t } = useTranslation()

  return (
    <>
    <ALPage>
      <Box sx={{ display: 'flex', flexDirection : 'column', alignItems: 'center' }} >
        <Typography component='h1' variant='h3' mt='1rem' >{ t( 'pages.story.block-1-title' ) }</Typography>
        <ALCardContent sx={{ mt : '1rem', width : '100%', maxWidth: '1056px', textAlign: 'center' }} ><ReactMarkdown>{ t( 'pages.story.block-1' ) }</ReactMarkdown></ALCardContent>

        <Typography component='h1' variant='h3' mt='2rem' >{ t( 'pages.story.block-2-title' ) }</Typography>
        <ALCardContent sx={{ mt : '1rem', width : '100%', maxWidth: '1056px' }} ><ReactMarkdown>{ t( 'pages.story.block-2' ) }</ReactMarkdown></ALCardContent>

        <Typography component='h1' variant='h3' mt='2rem' >{ t( 'pages.story.title' ) }</Typography>
        <Timeline position={ mobile ? 'right' : 'alternate' } sx={ mobile ? {  padding: '0' , "& .MuiTimelineItem-root" : { "&:before": { display: 'none' } }, "& .MuiTimelineContent-root" : { padding: '0.5rem 0 0.5rem 0.5rem' } } : {} } >

          <TimelineItem>
            <TimelineSeparator >
              <ALTimelineDot>1992</ALTimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><ALCardContent><ReactMarkdown>{ t( 'pages.story.data-1992' ) }</ReactMarkdown></ALCardContent></TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator >
              <ALTimelineDot>1996</ALTimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><ALCardContent><ReactMarkdown>{ t( 'pages.story.data-1996' ) }</ReactMarkdown></ALCardContent></TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator >
              <ALTimelineDot>2000</ALTimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><ALCardContent><ReactMarkdown>{ t( 'pages.story.data-2000' ) }</ReactMarkdown></ALCardContent></TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator >
              <ALTimelineDot>2004</ALTimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><ALCardContent><ReactMarkdown>{ t( 'pages.story.data-2004' ) }</ReactMarkdown></ALCardContent></TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator >
              <ALTimelineDot>2009</ALTimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><ALCardContent><ReactMarkdown>{ t( 'pages.story.data-2009' ) }</ReactMarkdown></ALCardContent></TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator >
              <ALTimelineDot>2018</ALTimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><ALCardContent><ReactMarkdown>{ t( 'pages.story.data-2018' ) }</ReactMarkdown></ALCardContent></TimelineContent>
          </TimelineItem>

          <TimelineItem >
            <TimelineSeparator >
              <ALTimelineDot>2021</ALTimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><ALCardContent><ReactMarkdown>{ t( 'pages.story.data-2021' ) }</ReactMarkdown></ALCardContent></TimelineContent>
          </TimelineItem>

        </Timeline>
      </Box>
    </ALPage>
    </>  )
}
