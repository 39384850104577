//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useParams,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'

import Card from '@mui/material/Card'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import {
  rsIsLoading,
  rsIsError,
} from '../../remote-state/core'

import {
  useCommunity,
  communityName,

  communityGroups,
  communityGroupID,
  communityGroupName,

  communityArchived,
} from '../../remote-state/communities'

import {
  ALMain,
} from '../../components/ALMain'

import ALLoading from '../../components/ALLoading'

//

export default function ALMainCIDArticles() {
  const { cid } = useParams()
  const gid = undefined // TODO - pull this from useParams
  const { i18n, t } = useTranslation()

  const cmty = useCommunity( cid )
  const groups = communityGroups( cmty )

  if( rsIsLoading( cmty ) ) return ( <ALMain><ALLoading /></ALMain>)
  if( rsIsError( cmty ) ) return ( <ALMain><p>t('err.error')</p></ALMain>)

  return (
    <ALMain title={ communityName( cmty, [ i18n.language ] ) } nocard alerts={ communityArchived( cmty ) ? [ { severity : 'warning', message: t( `mod.community.archived_${ communityArchived( cmty ) }` ) } ] : null } >
      { groups.length > 0 && (
        <>
          <Card sx={{ pb: '2px' }} >
            <Tabs
              value={ gid ?? cid }
              variant="scrollable"
              scrollButtons="auto"
              aria-label="community groups"
              >
              <Tab label={ t( 'rec.community.group_all' ) } value={ cid }  />
              { groups.map( group => <Tab value={ communityGroupID( group ) } label={ communityGroupName( group, [ i18n.language ] ) } key={ communityGroupID( group ) } /> ) }
            </Tabs>
          </Card>
          <Box sx={{ pb: '0.5rem' }} />
        </>
      ) }

      <Card sx={{ p: '0.5rem' }} >
        Articles not implemented yet
      </Card>


    </ALMain>
  )
}