import feathers from "@feathersjs/client"
import rest from "@feathersjs/rest-client"
import axios from "axios"

const storageKey = 'feathers-react-jwt'
const { localStorage, sessionStorage } = global

function storageWrapper() {
  let shouldPersist = Boolean( localStorage.getItem( storageKey ) )

  return {
    setShouldPersist : ( persist ) => {
      shouldPersist = Boolean( persist )
      if( ! shouldPersist ) {
        localStorage.removeItem( storageKey )
      }
    },

    getItem : ( name ) => { return ( shouldPersist ? localStorage : sessionStorage ).getItem( name ) },

    setItem : ( name, value ) => { return ( shouldPersist ? localStorage : sessionStorage ).setItem( name, value ) },

    removeItem : ( name ) => { return ( shouldPersist ? localStorage : sessionStorage ).removeItem( name ) },
  }
}

const storage = storageWrapper()
//storage.setShouldPersist( false )
//storage.setShouldPersist( true )

const feathersClient = feathers()

const restClient = rest( process.env.REACT_APP_API_URL )

feathersClient.configure( restClient.axios( axios ) )
feathersClient.configure( feathers.authentication( {
    storage,
    storageKey,
  })
)

export default feathersClient

export {
  storage,
}
