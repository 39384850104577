//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useState,
  useEffect,
} from 'react'

import {
  useParams,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { styled } from '@mui/material/styles'

import {
  Avatar,
  Divider,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Tab,

  Dialog,
  DialogTitle,
  Typography,
} from '@mui/material'

import {
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  LockOutlined as LockedIcon,
  VisibilityOffOutlined as HiddenIcon,
} from '@mui/icons-material'

import {
  rsIsLoading,
  rsIsError,
} from '../../remote-state/core'

import {
  roles,
  checkRoles,
  capabilities,
  checkCapabilities,
} from '../../remote-state/authorization'

import {
  useCommunity,
  communityID,
  communityName,
  communityArchived,

  useCommunityMembers,
  communityMembers,
  communityMembersHasMore,
  communityMembersInsufficient,

  useCommunityRoles,
  useCommunityRolesModify,
  communityRolesRole,
  communityUserRoles,

  useCommunityJoin,
  useCommunityLeave,

  communityGroup,
  communityGroups,
  communityGroupID,
  communityGroupAuthz,
  communityGroupParentID,
  communityGroupName,
  communityGroupRoleCnt,
  communityGroupVisibility,

  useCommunityMemberRoles,
  communityMemberRoles,

  ROLE_MEM,
  ROLE_GRP,
  ROLE_CON,
  ROLE_LEA,
  ROLE_ADM,
  ROLE_OWN,
} from '../../remote-state/communities'

import {
  useFacet,

  facetID,
  facetAvatar,
  facetInitials_2,
  facetName,
} from '../../remote-state/facets'

import {
  useImage,
  imageUrl,
} from '../../remote-state/images'

import { useRenderLanguages } from '../../components/ALI18N'

import {
  ALMain,
} from '../../components/ALMain'

import {
  useMobile
} from '../../components/ALScreenSizing'

import ALLoading from '../../components/ALLoading'

//

const ChipText = styled( 'span' ) ( ( {  color, variant = 'filled', theme } ) => ( {
  marginLeft : '0.25rem',
  borderRadius : '0.25rem',
  display : 'inline',
  padding : '0 0.25rem 0 0.25rem',
  color : theme.palette[ color ].contrastText,
  backgroundColor : theme.palette[ color ].light,
  whiteSpace : 'nowrap',
  fontWeight : 'normal',
} ) )

// ChooseGroup

function ChooseGroup( { cmty, group_id, setGroupID, role, setRole } ) {
  const { t, i18n } = useTranslation()
  const groups = communityGroups( cmty )

  function onChange( event, newValue ) {
    setGroupID( newValue )
  }

  if( rsIsLoading( cmty ) || rsIsError( cmty ) ) return <></>

  return (
    <Card sx={{ pb: '2px', mb: '0.5rem' }} >
      <Tabs
        value={ group_id }
        onChange={ onChange }
        variant="scrollable"
        scrollButtons="auto"
        aria-label="community groups"
        sx={{ '& .MuiButtonBase-root' : {  minHeight : '1rem', } }}
        >
        <Tab label={ t( 'rec.community.group_all' ) } value={ communityID( cmty ) } />
        { groups.map( group => {
          const group_id = communityGroupID( group )
          const cmty_group = communityGroup( cmty, group_id )
          const vis = communityGroupVisibility( cmty_group )
          const icon = vis === 'hidden'
            ? <HiddenIcon sx={{ fontSize : '1rem' }} />
            : vis === 'private'
              ? <LockedIcon sx={{ fontSize : '1rem' }} />
              : <></>

          return <Tab value={ group_id } label={ communityGroupName( group, [ i18n.language ] ) } icon={ icon } iconPosition='end' key={ group_id } />
        } ) }
      </Tabs>
    </Card>
  )
}

// ChooseRole

function ChooseRole( { cmty, role, setRole } ) {
  const { t } = useTranslation()
  const roles = [ ROLE_MEM, ROLE_LEA, ROLE_ADM, ROLE_OWN ]
  function onChange( event, newValue ) {
    setRole( newValue )
  }

  if( rsIsLoading( cmty ) || rsIsError( cmty ) ) return <></>

  return (
    <Card sx={{ pb: '2px', mb: '0.5rem' }} >
      <Tabs
        value={ role }
        onChange={ onChange }
        variant="scrollable"
        scrollButtons="auto"
        aria-label="group roles"
        >
        { roles.map( role => <Tab value={ role } label={ t( `rec.authz.${ role }`, { count : 2 } ) } key={ role } /> ) }
      </Tabs>
    </Card>
  )
}

// MembersGroupDialogItem

function MembersGroupDialogItem( { cmty, subject_id, subject_member_roles, group_id, isSelf, onClose } ) {
  const { t } = useTranslation()

  const group = communityGroup( cmty, group_id )

  const group_roles = { roles : subject_member_roles.find( elem => elem.group_id === group_id )?.roles ?? 0b0 }
  const { i18n } = useTranslation()

  const isMem = checkRoles( group_roles, roles.member | roles.group )
  const isGroup = communityID( cmty ) !== group_id

  const count_lea = communityGroupRoleCnt( group, ROLE_LEA )
  const count_adm = communityGroupRoleCnt( group, ROLE_ADM )

  const group_authz = communityGroupAuthz( group )
  const mayLeave = checkCapabilities( group_authz, capabilities.leave )
  const mayJoin = checkCapabilities( group_authz, capabilities.join )    // TODO handle join_app
  const mayMngMem = checkCapabilities( group_authz, capabilities.manage_m )
  const mayMngGrp = checkCapabilities( group_authz, capabilities.manage_g )
  const mayMngLea = checkCapabilities( group_authz, capabilities.manage_l )
  const mayMngAdm = checkCapabilities( group_authz, capabilities.manage_a )

  const mayRemMem = ! Boolean( communityGroupParentID( group ) )
                      && mayMngMem
                      && ! checkRoles( group_roles, roles.LEADERSHIP )
                      && subject_member_roles.reduce( ( acc, group ) => {   // false if subject is member of any groups
                            if( checkRoles( { roles : group.roles }, roles.group) ) return false
                            return acc
                        }, true )

  const mayRemGrp =   Boolean( communityGroupParentID( group ) )
                      && mayMngGrp
                      && ! checkRoles( group_roles, roles.LEADERSHIP )

  const isSubAdm = checkRoles( group_roles, roles.admin )
  const isSubLea = checkRoles( group_roles, roles.leader )
  const isSubOwn = checkRoles( group_roles, roles.owner )

  const doLeaveCmty = useCommunityLeave( cmty, onClose )
  const doLeaveGroup = useCommunityLeave( cmty )

  const doJoin = useCommunityJoin( cmty )

  const doRoleModify = useCommunityRolesModify( cmty )

  const [ anchorEl, setAnchorEl ] = useState( false )
  const open = Boolean( anchorEl )
  function handleClick( e ) {
    setAnchorEl( e.currentTarget )
  }
  function handleClose() {
    setAnchorEl( null )
  }

  const menuItems = [
    // Community
    ...(   isSelf &&   mayJoin
      ? [ {
        label : isGroup ? t('act.join_group') : t('act.join_community'),
        onClick : () => { doJoin.mutate( { group_id } ); handleClose(); },
        } ]
      : []
    ),
    ...(   isSelf &&   mayLeave
      ? [ {
        label : isGroup ? t('act.leave_group') : t('act.leave_community'),
        onClick : () => { ( isGroup ? doLeaveGroup : doLeaveCmty ).mutate( { group_id } ); handleClose(); },
      } ]
      : []
    ),
    ...( ! isSelf &&   isMem && mayRemMem && ! isGroup
      ? [ {
        label : t('act.remove_from_community'),
        onClick : () => { doLeaveCmty.mutate( { group_id, subject_id } ); handleClose(); },
        } ]
      : []
    ),

    // Group
    ...(   isSelf && ! isMem && mayMngGrp &&   isGroup && ! mayJoin
      ? [ {
        label : t('act.add_to_group'),
        onClick : () => { doJoin.mutate( { group_id, subject_id } ); handleClose(); },
        } ]
      : []
    ),
    ...( ! isSelf && ! isMem && mayMngGrp &&   isGroup
      ? [ {
        label : t('act.add_to_group'),
        onClick : () => { doJoin.mutate( { group_id, subject_id } ); handleClose(); },
        } ]
      : []
    ),
    ...( ! isSelf &&   isMem && mayRemGrp &&   isGroup
      ? [ {
        label : t('act.remove_from_group'),
        onClick : () => { doLeaveGroup.mutate( { group_id, subject_id } ); handleClose(); },
        } ]
      : []
    ),

    // LEA
    ...(             ! isSubLea && mayMngLea &&   isMem
      ? [ {
        label : t('act.make_leader'),
        onClick : () => { doRoleModify.mutate( { op: 'add', role : ROLE_LEA, subject_id, group_id } ); handleClose(); },
      } ]
      : []
    ),
    ...( ! isSelf &&   isSubLea && mayMngLea
      ? [ {
        label : count_lea <= 1 && ! isGroup ? t('act.remove_leader_last') : t('act.remove_leader'),
        onClick : () => { doRoleModify.mutate( { op: 'remove', role : ROLE_LEA, subject_id, group_id } ); handleClose(); },
      } ]
      : []
    ),
    ...(   isSelf &&   isSubLea
      ? [ {
        label : count_lea <= 1 && ! isGroup ? t('act.resign_leader_last') : t('act.resign_leader'),
        onClick : () => { doRoleModify.mutate( { op: 'remove', role : ROLE_LEA, subject_id, group_id } ); handleClose(); },
      } ]
      : []
    ),

    // ADM
    ...(             ! isSubAdm && mayMngAdm &&   isMem
      ? [ {
        label : t('act.make_admin'),
        onClick : () => { doRoleModify.mutate( { op: 'add', role : ROLE_ADM, subject_id, group_id } ); handleClose(); },
      } ]
      : []
    ),
    ...( ! isSelf &&   isSubAdm && mayMngAdm
      ? [ {
        label : count_adm <= 1 && ! isGroup ? t('act.remove_admin_last') : t('act.remove_admin'),
        onClick : () => { doRoleModify.mutate( { op: 'remove', role : ROLE_ADM, subject_id, group_id } ); handleClose(); },
      } ]
      : []
    ),
    ...(   isSelf &&   isSubAdm
      ? [ {
        label : count_adm <= 1 && ! isGroup ? t('act.resign_admin_last') : t('act.resign_admin'),
        onClick : () => { doRoleModify.mutate( { op: 'remove', role : ROLE_ADM, subject_id, group_id } ); handleClose(); },
      } ]
      : []
    ),

    // OWN
    ...(   isSelf &&   isSubOwn
      ? [ {
        label : t('act.resign_owner_last'),
        onClick : () => { doRoleModify.mutate( { op: 'remove', role : ROLE_OWN, subject_id, group_id } ); handleClose(); },
      } ]
      : []
    ),
  ]

  return (
    <ListItem>
      <ListItemText
        primary={ communityGroupName( group, [ i18n.language ] ) }
        secondary={
          <span>
            {   isGroup && checkRoles( group_roles, roles.group       ) ? <ChipText color='secondary'     >{ t( `role.${ ROLE_GRP }`, { count : 1 } ) }</ChipText> : <></> }
            {   isGroup && checkRoles( group_roles, roles.contributor ) ? <ChipText color='secondary'     >{ t( `role.g_${ ROLE_CON }`, { count : 1 } ) }</ChipText> : <></> }
            {   isGroup && checkRoles( group_roles, roles.leader      ) ? <ChipText color='secondary'     >{ t( `role.g_${ ROLE_LEA }`, { count : 1 } ) }</ChipText> : <></> }
            {   isGroup && checkRoles( group_roles, roles.admin       ) ? <ChipText color='secondary'     >{ t( `role.g_${ ROLE_ADM }`, { count : 1 } ) }</ChipText> : <></> }
            { ! isGroup && checkRoles( group_roles, roles.contributor ) ? <ChipText color='info'          >{ t( `role.${ ROLE_CON }`, { count : 1 } ) }</ChipText> : <></> }
            { ! isGroup && checkRoles( group_roles, roles.leader      ) ? <ChipText color='info'          >{ t( `role.${ ROLE_LEA }`, { count : 1 } ) }</ChipText> : <></> }
            { ! isGroup && checkRoles( group_roles, roles.admin       ) ? <ChipText color='info'          >{ t( `role.${ ROLE_ADM }`, { count : 1 } ) }</ChipText> : <></> }
            { ! isGroup && checkRoles( group_roles, roles.owner       ) ? <ChipText color='warning'       >{ t( `role.${ ROLE_OWN }`, { count : 1 } ) }</ChipText> : <></> }
            &nbsp;
          </span>
        }
        secondaryTypographyProps={{ display : 'inline-block', height: '1.25rem' }}
      >
      </ListItemText>
      {
        Boolean( menuItems.length ) &&
        <>
          <IconButton
            id="mb-menu-button"
            aria-controls="alter user menu"
            aria-haspopup="true"
            aria-expanded={ open ? 'true' : undefined }
            onClick={ handleClick }
            sx={{ p: '0' }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="mb-menu"
            anchorEl={ anchorEl }
            open={ open }
            onClose={ handleClose }
            MenuListProps={{
              'aria-labelledby':'delete-button',
            }}
          >
            { menuItems.map( ( item, idx ) => (
              <MenuItem onClick={ item.onClick } key={ idx } >
                <ListItemText>{ item.label }</ListItemText>
              </MenuItem>
            ) ) }
          </Menu>
        </>
      }
    </ListItem>
  )
}

// MembersGroupsDialog

function MembersGroupsDialog( { cmty, subject, open, onClose } ) {
  const mobile = useMobile()
  const cmty_id = communityID( cmty )
  const subject_id = facetID( subject )
  const groups = communityGroups( cmty )
  const use_subject_member_roles = useCommunityMemberRoles( cmty, open ? subject_id : undefined )
  const subject_member_roles = communityMemberRoles( use_subject_member_roles )

  const actor = useFacet()
  const isSelf = facetID( actor ) === subject_id

  if( ! open ) return <></>
  if( rsIsLoading( use_subject_member_roles ) ) return <></>

  return (
    <Dialog open={ open } onClose={ onClose } fullScreen={ mobile } fullWidth maxWidth='sm' >
      <DialogTitle sx={{ p : '1rem', display : 'flex', justifyContent : 'space-between' }} >
        { facetName( subject ) }
        <IconButton
          aria-label="close"
          onClick={ onClose }
          sx={{ color: (theme) => theme.palette.grey[500], }}
          >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <List dense sx={{ maxHeight: '100%', overflow: 'auto' }}>
        <MembersGroupDialogItem
          cmty={ cmty }
          subject_id={ subject_id }
          subject_member_roles={ subject_member_roles }
          group_id={ cmty_id }
          isSelf={ isSelf }
          onClose={ onClose }
          key={ cmty_id }
        />
        <Divider sx={{ mx:'1rem' }} />
        { groups.map( group => (
          <MembersGroupDialogItem
            cmty={ cmty }
            subject_id={ subject_id }
            subject_member_roles={ subject_member_roles }
            group_id={ communityGroupID( group ) }
            isSelf={ isSelf }
            onClose={ onClose }
            key={ communityGroupID( group ) }
          />
        ) ) }
      </List>

    </Dialog>
  )
}

// MembersListItem

function MembersListItem( { subject_id, cmty, group_id } ) {
  const { t } = useTranslation()
  const langs = useRenderLanguages()

  const actor = useFacet()
  const actor_id = facetID( actor )
  const subject = useFacet( subject_id )

  const isSelf = actor_id === subject_id

  const groups = communityGroups( cmty )
  const mayEdit = checkCapabilities( communityGroupAuthz( communityGroup( cmty ) ), capabilities.manage_m )
                    || groups.reduce( ( acc, group ) => checkCapabilities( communityGroupAuthz( group ), capabilities.manage_m | capabilities.manage_g ) || acc, false )
  const cmty_roles = useCommunityRoles( cmty )
  const cmty_roles_subject = communityUserRoles( cmty_roles, subject, communityID( cmty ) )
  const grp_roles_subject = communityUserRoles( cmty_roles, subject, group_id )
  const isGroup = group_id !== communityID( cmty )

  const avatar = useImage( facetAvatar( subject ) )
  const initials = facetInitials_2( subject, langs )

  const [ open, setOpen ] = useState( false )

  if( rsIsLoading( subject ) ) return <></>
  if( rsIsLoading( cmty_roles ) ) return <></>

  return (
    <ListItem
      secondaryAction={ isSelf || mayEdit
        ? (
          <IconButton onClick={ () => setOpen( true ) } edge="end" aria-label="edit user memberships">
            <EditIcon />
          </IconButton>
        )
        : <></>
      }
    >
      <ListItemAvatar>
          <Avatar src={ imageUrl( avatar ) } variant='rounded'>{ initials }</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={ facetName( subject, langs ) }
          secondary={
            <span>
              { isGroup && checkRoles( grp_roles_subject,  roles.group       ) ? <ChipText color='secondary'>{ t( `role.${ ROLE_GRP }`, { count : 1 } ) }</ChipText> : <></> }
              { isGroup && checkRoles( grp_roles_subject,  roles.contributor ) ? <ChipText color='secondary'>{ t( `role.g_${ ROLE_CON }`, { count : 1 } ) }</ChipText> : <></> }
              { isGroup && checkRoles( grp_roles_subject,  roles.leader      ) ? <ChipText color='secondary'>{ t( `role.g_${ ROLE_LEA }`, { count : 1 } ) }</ChipText> : <></> }
              { isGroup && checkRoles( grp_roles_subject,  roles.admin       ) ? <ChipText color='secondary'>{ t( `role.g_${ ROLE_ADM }`, { count : 1 } ) }</ChipText> : <></> }
              {            checkRoles( cmty_roles_subject, roles.contributor ) ? <ChipText color='info'     >{ t( `role.${ ROLE_CON }`, { count : 1 } ) }</ChipText> : <></> }
              {            checkRoles( cmty_roles_subject, roles.leader      ) ? <ChipText color='info'     >{ t( `role.${ ROLE_LEA }`, { count : 1 } ) }</ChipText> : <></> }
              {            checkRoles( cmty_roles_subject, roles.admin       ) ? <ChipText color='info'     >{ t( `role.${ ROLE_ADM }`, { count : 1 } ) }</ChipText> : <></> }
              {            checkRoles( cmty_roles_subject, roles.owner       ) ? <ChipText color='warning'  >{ t( `role.${ ROLE_OWN }`, { count : 1 } ) }</ChipText> : <></> }
              &nbsp;
            </span>
          }
          secondaryTypographyProps={{ display : 'inline-block', height: '1.25rem' }}
        />
        <MembersGroupsDialog cmty={ cmty } subject={ subject } open={ open } onClose={ () => setOpen( false ) } />
      </ListItem>
  )
}

// Members

function Members ( { cmty, group_id, mayRead } ) {
  const limit = 20
  const [ value, setValue ] = useState( '' )
  const [ query, setQuery ] = useState( '' )
  const { t } = useTranslation()
  const cmty_mbs = useCommunityMembers( cmty, { group_id, query, limit, enabled : mayRead } )
  const mbs = communityMembers( cmty_mbs )
  const has_more = communityMembersHasMore( cmty_mbs )
  const insufficient = communityMembersInsufficient( cmty_mbs )

  useEffect( () => {
    const timer = setTimeout( () => {
      if( query !== value ) {
        setQuery( value )
      }
    }, 250 )
    return () => clearTimeout( timer )
  }, [ value, query, setQuery ] )

  function onChange( e ) {
    setValue( e.target.value )
  }

  const error = rsIsError( cmty_mbs )
    ? t( 'status.error.query.error' )
    : insufficient
      ? t( 'query.status.insufficient' )
      : ''

  const loading = rsIsLoading( cmty) || rsIsLoading( cmty_mbs )

  if( ! mayRead ) {
    return <Typography p='1rem' >{ t('status.query.no_perms' ) }</Typography>
  }

  return (
    <List dense aria-label='members' >
      <ListItem>
        <TextField
          fullWidth
          margin='none'
          size='small'
          variant='filled'
          label={ t( 'act.filter_results' ) }
          value={ value }
          onChange={ onChange }
          error={ Boolean( error ) }
          helperText={ error }
          autoFocus={ true }
        />
      </ListItem>
      {   loading && <ALLoading /> }

      { ! loading && mbs.length === 0 && <ListItem><ListItemText>{ t('status.query.no_results' ) }</ListItemText></ListItem> }
      { mbs.map( ( id, idx ) => ( <MembersListItem subject_id={ id } cmty={ cmty } group_id={ group_id } key={ idx } /> ) ) }
      { has_more && <ListItem>{ t( 'status.query.has_more' ) }</ListItem>}
    </List>
  )
}

// MembersRole

function MembersRole ( { cmty, group_id, role, mayRead } ) {
  const { t } = useTranslation()
  const cmty_roles = useCommunityRoles( cmty )
  const mbs_cmty = communityRolesRole( cmty_roles, role, communityID( cmty ) )
  const mbs_grp = communityRolesRole( cmty_roles, role, group_id )
  const mbs = _.unionBy( mbs_grp, mbs_cmty, 'facet_id' )

  if( ! mayRead ) {
    return <Typography p='1rem' >{ t('status.query.no_perms' ) }</Typography>
  }

  return (
    <List component='nav' aria-label='members' >
      { mbs.map( ( elem, idx ) => ( <MembersListItem subject_id={ elem.facet_id } cmty={ cmty } group_id={ group_id } key={ idx } /> ) ) }
    </List>
  )
}

// ALMainCIDMemers

export default function ALMainCIDMembers() {
  const { t, i18n } = useTranslation()
  const { cid } = useParams()

  const [ group_id, setGroupID ] = useState( cid )
  const [ role, setRole ] = useState( ROLE_MEM )

  const role_member = ( role === ROLE_MEM || role === ROLE_GRP )

  const cmty = useCommunity( cid )
  const group = communityGroup( cmty, group_id )
  const mayRead = checkCapabilities( communityGroupAuthz( group ), capabilities.read )

  if( rsIsLoading( cmty ) || rsIsLoading( group ) ) return <></>

  return (
    <ALMain nocard title={ communityName( cmty, [ i18n.language ] ) } alerts={ communityArchived( cmty ) ? [ { severity : 'warning', message: t( `mod.community.archived_${ communityArchived( cmty ) }` ) } ] : null } >
      <ChooseGroup cmty={ cmty } group_id={ group_id } setGroupID={ setGroupID } role={ role } setRole={ setRole } />
      <ChooseRole cmty={ cmty } group_id_id={ group_id } role={ role } setRole={ setRole } />
      <Card>
        {   role_member && <Members cmty={ cmty } group_id={ group_id } mayRead={ mayRead } /> }
        { ! role_member && <MembersRole cmty={ cmty } group_id={ group_id } role={ role } mayRead={ mayRead } /> }
      </Card>
    </ALMain>
  )
}
