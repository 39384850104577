//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  Navigate,
  Outlet,
} from 'react-router-dom'

import {
  checkCapabilities,
} from '../remote-state/authorization'

import {
  useAuth,
 } from './ALAuth'

 function AuthorizedRoute( { redirectPath = '/', authz, capability, children } ) {
  const auth = useAuth()

  if( ! auth ) return <Navigate to={ redirectPath } />

  if( ! checkCapabilities( authz, capability ) ) return <Navigate to={ redirectPath } />

  return children ?? < Outlet / >
}

function AuthenticatedRoute( { redirectPath = '/', children } ) {
  const auth = useAuth()

  if( ! auth ) return <Navigate to={ redirectPath } />

  return children ?? < Outlet / >
}

//

function UnauthenticatedRoute( { redirectPath = '/', children } ) {
  const auth = useAuth()

  if( auth ) return <Navigate to={ redirectPath } />

  return children ?? < Outlet / >
}

//

export {
  AuthorizedRoute,
  AuthenticatedRoute,
  UnauthenticatedRoute,
}
