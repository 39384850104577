//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React, {
  useEffect,
  useState,
} from "react"

import {
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'

import {
  useTranslation,
} from 'react-i18next'

import {
  Box,
  Button,
} from '@mui/material'

import {
  useUser,
  userFacetIDs,
} from '../../remote-state/users'

import {
  useFacet,
  facetID,
  facetDescription,
  facetAvatar,
  facetInitials_2,
  useFacetCreate,
} from '../../remote-state/facets'

import {
  useLogout,
} from '../../components/ALAuth'

import {
  useDesktop,
} from '../../components/ALScreenSizing'

import {
  ALSide,
  ALSideList,
  ALSideListItem,
} from '../../components/ALSide'

//

function ListItemFacet( { facet_id, to, selected } ) {
  const facet = useFacet( facet_id )
  const label = facetDescription( facet )
  const avatar = facetAvatar( facet )
  const initials = facetInitials_2( facet )

  return <ALSideListItem
    item={ {
      type : 'item',
      label,
      avatar : { variant : 'rounded', image : avatar, initials : initials },
      to,
      selected,
    } }
  />
}

export default function ALSideRoot() {
  const { t } = useTranslation()
  const path='/account'

  const [ sel, setSel ] = useState('')

  const desktop = useDesktop()
  const navigate = useNavigate()
  const location = useLocation()

  const logout = useLogout()

  const user = useUser()
  const fids = userFacetIDs( user )
  function postFacetCreate( fid ) { navigate( `/account/facets/${ fid }` ) }
  const facetCreate = useFacetCreate( postFacetCreate )

  const { f_id } = useParams()
  const facet = useFacet()
  const facet_id = f_id || facetID( facet )

  useEffect( () => {
    const pathComp = location.pathname.split('/')
    if ( ! pathComp[2] && desktop ) { setSel('facets') }
    else { setSel(pathComp[2]) }
  }, [ location, desktop ] )

  const facetList = fids.map( fid => { return { type: 'function', function: <ListItemFacet facet_id={ fid } to={ `${path}/facets/${ fid }` } selected={ ( sel === 'facets' && ( ( fid === facet_id ) || (! facet_id && fid === fids[ 0 ] ) ) ) } /> } } )
  facetList.push( { type : 'button', label: t( 'act.facet_create' ), onClick: () => facetCreate.mutate(), variant: 'outlined' } )

  const itemsFacets = [
    { type : 'label', label: t( 'rec.facet.facet', { count : 2 } ) },
    ...( facetList ),
  ]
  const itemsIdentity = [
    { label: t( 'mod.account.identity' ), to: `${path}/identity`, selected: sel === 'identity' },
  ]

  return (
    <Box>
      <ALSide>
        <ALSideList items={ itemsFacets } />
      </ALSide>
      <ALSide>
        <ALSideList items={ itemsIdentity } />
      </ALSide>
      <Box mx='1rem'>
        <Button variant='contained' size='small' fullWidth onClick={ logout  }>{ t( 'act.signout' ) }</Button>
      </Box>
    </Box>
  )
}
