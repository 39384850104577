//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React, {
  useState,
  useEffect,
  useCallback,
  Children,
} from 'react'

import {
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material'

import {
  Close as CloseIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material'

import SwipeableViews from 'react-swipeable-views'              // TODO - throws UNSAFE_componentWillReceiveProps.
//import { bindKeyboard } from 'react-swipeable-views-utils'    // Don't use - throws UNSAFE_componentWillMount., manually impemented in ALCarousel

import {
  useImage,
  imageUrl,
  imageAlt,
  imageCaption,
} from '../remote-state/images'

// -----

function ALStepper( { step, maxSteps, nextButton, prevButton } ) {
  return (
    <Box display='flex' flexDirection='row' flexWrap='nowrap' justifyContent='space-between' alignItems='center' maxWidth='32rem' height='2rem' marginX='auto'>
      <Box>{ prevButton }</Box>
      <Typography variant='button' >{ step + 1 } / { maxSteps }</Typography>
      <Box>{ nextButton }</Box>
    </Box>
  )
}

const alCarouselControlsHeight = '4rem'

// ----- ALCarousel

function ALCarousel( { index, setIndex, children } ) {
  const maxSteps = Children.toArray( children ).length
  const viewHeight = maxSteps > 1 ? 'calc( 100vh - 4rem )' : 'calc( 100vh - 2rem )'

  const onNext = useCallback( () => {
    setIndex( prev => ( prev + 1 ) % maxSteps )
  }, [ setIndex, maxSteps ] )

  const onPrev = useCallback( () => {
    setIndex( prev => ( prev + maxSteps - 1 ) % maxSteps )
  }, [ setIndex, maxSteps ] )

  function onSet( step ) {
    setIndex( step )
  }

  const onKeyDown = useCallback( e => {
    switch( e.code ) {
      case 'ArrowRight':
        onNext()
        break

      case 'ArrowLeft':
        onPrev()
        break

      default: break
    }
  }, [ onNext, onPrev ] )

  useEffect( () => {
    window.addEventListener( 'keydown', onKeyDown )
    return () => window.removeEventListener( 'keydown', onKeyDown )
  }, [ onKeyDown ] )

  if( index >= maxSteps ) setIndex( maxSteps - 1 )
  if( index < 0 ) setIndex( 0 )

  return (
    <Box sx={{ maxWidth: '100vw', my: 'auto' }} >
      <SwipeableViews
        index={ index }
        onChangeIndex={ onSet }
        enableMouseEvents
      >
      { React.Children.map( children, child => { return (
        <Box sx={{ maxHeight: viewHeight }}>
          {
            React.isValidElement( child )
              ? React.cloneElement( child, { viewHeight } )
              : child
          }
        </Box>
      ) } ) }
      </SwipeableViews>
      <Box sx={{ postion: 'absolute', zIndex: 100 }}>
        { maxSteps > 1 && <ALStepper
          step={ index }
          maxSteps={ maxSteps }
          nextButton={ <Button size='small' onClick={ onNext }>Next <KeyboardArrowRightIcon /></Button> }
          prevButton={ <Button size='small' onClick={ onPrev }><KeyboardArrowLeftIcon /> Prev</Button> }
        /> }
        </Box>
    </Box>
  )
}

// ----- ALCarouselImage

function ALCarouselImage( { elem } ) {
  const image = useImage( elem.id, elem )
  const url = imageUrl( image )
  const alt = imageAlt( image )
  const cap = imageCaption( image )
  const boxHeight = `calc( 100vh - ${ alCarouselControlsHeight } )`
  return (
    <Box sx={{maxHeight: boxHeight }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flexWrap: 'nowrap' }}>
        <Typography variant='caption' sx={{  px: '0.5rem', pb: '0.5rem', maxWidth: '40rem' }}>{ cap }</Typography>
        <img src={ url } alt={ alt } style={{ maxWidth: '100vw',objectFit: 'contain' }} />
      </Box>
    </Box>
  )
}

// ----- ALDialogCarousel

function ALDialogCarousel( { Tile = ALCarouselImage, tileProps = {}, open, objs, index, setIndex, onClose } ) {
  return (
    <Dialog fullScreen open={ open } onClose={ onClose } >
      <Box display='flex' justifyContent='flex-end' alignItems='center' >
        <IconButton aria-label='close' onClick={ onClose } sx={{ p: '0.25rem' }} ><CloseIcon /></IconButton>
      </Box>
      <ALCarousel index={ index } setIndex={ setIndex } >
        { ( objs || [] ).map( ( elem, idx ) => (
          <Tile elem={ elem } { ...tileProps } key={ idx } />
          ) ) }
      </ALCarousel>
    </Dialog>
  )
}

// ----- ALTileImage

function ALTileImage( { elem, idx, onClick, maxHeight, single, ...props} ) {
  const image = useImage( elem?.id, elem )
  const url = imageUrl( image )
  const alt = imageAlt( image )

  return (
    <img
      src={ url }
      alt={ alt }
      loading='lazy'
      className="MuiImageListItem-img"
      onClick={ onClick ? () => onClick( idx ) : undefined }
      style={{
        maxHeight : single ? 'calc( 100vh - 8rem )' : undefined,
        aspectRatio : single ? undefined : '1',
        objectFit: single ? 'contain' : 'cover',
      }}
    />
  )
}

// ----- ALTile

function ALTile( { objs, Tile = ALTileImage, tileProps = {}, CarouselTile, carouselTileProps, ...props } ) {
  const num = ( objs || [] ).length
  const disp = Math.min( num, 5 )

  const [ open, setOpen ] = useState( false )
  const [ index, setIndex ] = useState( 0 )

  function onClick( id ) {
    setIndex( id )
    setOpen( true )
  }

  function onClose() {
    setOpen( false )
  }

  const cols = {
    1 : [ 6 ],
    2 : [ 3, 3 ],
    3 : [ 6, 3, 3],
    4 : [ 3, 3, 3, 3 ],
    5 : [ 3, 3, 2, 2, 2 ],
  }

  if( num === 0 ) return <></>

  return (
    <Box>
      <ImageList cols={ 6 } >
        { ( objs.slice(0,5) || [] ).map( ( elem, idx ) => (
          <ImageListItem key={ idx } cols={ cols[ disp ][ idx ] } >
            <Tile elem={ elem } idx={ idx } onClick={ onClick } single={ num === 1 } { ...tileProps } />
            { ( idx + 1 ) === disp && num > disp && <ImageListItemBar onClick={ () => onClick( idx +1 ) } title={ <Typography variant='button' >{ `+ ${ num - disp }` }</Typography> } sx={{ textAlign: 'center', '& .MuiImageListItemBar-titleWrap' : { p: '0.25rem'}, zIndex: 900 }}  /> }
          </ImageListItem>
        ) ) }
      </ImageList>
      <ALDialogCarousel Tile={ CarouselTile } tileProps={ carouselTileProps } open={ open } objs={ objs } index={ index } setIndex={ setIndex } onClose={ onClose } />
    </Box>
  )
}

// -----

export {
    ALTile,
}