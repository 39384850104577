//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import { useTranslation } from 'react-i18next'

import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography'

import {
  useCommunitiesDiscover,
  communitiesDiscover,
} from '../../remote-state/communities'

import ALCardDiscover from './ALComponents'

import {
  ALMain,
} from '../../components/ALMain'

import ALLoading from '../../components/ALLoading'

//

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  card: {
    margin: '0.5rem',
  }
});

function ALCommuntiesFeatured() {
  const { t } = useTranslation()
  const classes = useStyles()

  const discover = useCommunitiesDiscover()
  const featured = communitiesDiscover( discover )

  return (
    <>
    <Typography align='center' variant='h3'>{ t('mod.communities.pages.discover.title') }</Typography>
      { ! Array.isArray(featured) && <ALLoading /> }
      { Array.isArray(featured) && featured.length <= 0 && t('mod.communities.pages.discover.empty') }
      { Array.isArray(featured) && (
        <div className={classes.flex}>
          {
            featured.map( ( cid, index ) =>
              (
                <ALCardDiscover cid={ cid } className={ classes.card } key={ index } />
              )
            )
          }
        </div>
      ) }
    </>
  )
}

// TODO - translate title
export default function ALMainDiscover( ) {
  return (
    <ALMain title='Discover Communities' >
      <ALCommuntiesFeatured />
    </ALMain>
  )
}