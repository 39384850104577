//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import { Outlet } from 'react-router'

import ALPage from './ALPage'

export default function ALLayout2( { desktop, side } ) {
  return(
    <ALPage alignItems='flex-start'>
        { desktop && side }

        <Outlet />
    </ALPage>
  )
}
