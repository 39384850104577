//
// Copyright 2022 Avvia Life, All Rights Reserved
//

const object = {
  wip               :                                0b1,
  f2                :                               0b10,
  locked_author     :                              0b100,
  locked_other      :                             0b1000,
  isLocked          :                             0b1100,

  hidden_author     :                            0b10000,
  hidden_other      :                           0b100000,
  isHidden          :                           0b110000,
  deleted           :                          0b1000000,
  purged            :                         0b10000000,
  isDeleted         :                         0b11000000,

  pinned            :                        0b100000000,

  bits_intermediate : 0b00000000000000001111111111111111,

  isInvisible       :   0b100000000000000000000000000000,
  isImmutable       :  0b1000000000000000000000000000000,
  f32               : 0b10000000000000000000000000000000,
}

const roles = {
  banned            :                                0b1,  // TODO placeholder. not sure if this is right.
  guest_public      :                               0b10,
  guest_site        :                              0b100,
  guest_user        :                             0b1000,

  member            :                            0b10000,
  group             :                           0b100000,
  contributor       :                          0b1000000,
  f8                :                         0b10000000,

  f9                :                        0b100000000,
  f10               :                       0b1000000000,
  f11               :                      0b10000000000,
  f12               :                     0b100000000000,

  leader            :                    0b1000000000000,
  f14               :                   0b10000000000000,
  f15               :                  0b100000000000000,
  f16               :                 0b1000000000000000,

  admin             :    0b10000000000000000000000000000,
  owner             :   0b100000000000000000000000000000,
  author            :  0b1000000000000000000000000000000,
  f32               : 0b10000000000000000000000000000000,
}

const capabilities = {
  create          :                               0b1,
  read            :                              0b10,
  edit            :                             0b100,
  delete          :                            0b1000,

  index           :                           0b10000,
  hide            :                          0b100000,
  lock            :                         0b1000000,
  pin             :                        0b10000000,

  // as
  // moderate
  // approve      // TODO is this the same as moderate?

  f9              :                        0b100000000,
  f10             :                       0b1000000000,
  f11             :                      0b10000000000,
  f12             :                     0b100000000000,

  f13             :                    0b1000000000000,
  f14             :                   0b10000000000000,
  f15             :                  0b100000000000000,
  f16             :                 0b1000000000000000,

  read_info       :                0b10000000000000000,
  f18             :               0b100000000000000000,
  f19             :              0b1000000000000000000,
  f20             :             0b10000000000000000000,

  leave           :            0b100000000000000000000,
  join            :           0b1000000000000000000000,
  f23             :          0b10000000000000000000000,
  f24             :         0b100000000000000000000000,
  // join_app     // TODO is this a capability or a community setting?
  // join_code    // TODO is this a capability or a community setting?

  f25             :        0b1000000000000000000000000,
  f26             :       0b10000000000000000000000000,
  manage_m        :      0b100000000000000000000000000,
  manage_g        :     0b1000000000000000000000000000,

  manage_c        :    0b10000000000000000000000000000,
  manage_l        :   0b100000000000000000000000000000,
  manage_a        :  0b1000000000000000000000000000000,
  f32             : 0b10000000000000000000000000000000,

  CREATE          :                                0b1,
  INDEX           :                            0b10000,
  READ            :                0b10000000000000010,
  JOIN            :           0b1000000000000000000000,
}

// -----

function checkCapabilities( authz, capability ) {
  return Boolean( authz?.capabilities & capability )
}

function checkRoles( authz, role ) {
  return Boolean( authz?.roles & role )
}

function checkObject( authz, status ) {
  return Boolean( authz?.object & status )
}

// -----

export {
  object,
  roles,
  capabilities,
  checkCapabilities,
  checkRoles,
  checkObject,
}