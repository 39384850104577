//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useParams,
  useLocation,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import {
  Box,
  Divider,
} from '@mui/material'

import {
  useLangs,
  useLangCurrent,
} from '../../components/ALI18N'

import {
  rsIsLoading,
  rsIsError,
} from '../../remote-state/core'

import {
  capabilities,
} from '../../remote-state/authorization'

import {
  useCommunity,
  useCommunityReload,
  communityID,
  communityName,
  communityArchived,

  communityGroup,
  communityGroupAuthzRoles,
  communityGroupAuthzRolesCountBits,
  communityGroupName,
  communityGroupNames,
  communityGroupLangs,
  communityGroupHead,
  communityGroupHeadlines,
  communityGroupDesc,
  communityGroupDescriptions,
} from '../../remote-state/communities'

import {
  fieldRequired,
  fieldRequiredArray,
  useFieldStringMax,
  fieldChanged,
  ALFormTextField,
} from '../../components/form/ALForm'

import {
  ALSettings,
} from '../../components/ALSettings'

import {
  ALEditor,
} from '../../components/ALDraftJS'

import {
  ALMain,
} from '../../components/ALMain'

import ALLoading from '../../components/ALLoading'


//

export default function ALMainAdmGroup() {
  const { t, i18n } = useTranslation()
  const { cid, gid } = useParams()

  const location = useLocation()
  const backTo = location.pathname.replace(/\/group\/.*$/i,'' )

  const fieldMax80 = useFieldStringMax( 80 )
  const fieldMax2048 = useFieldStringMax( 2048 )

  const cmty = useCommunity( cid )
  const group = communityGroup( cmty, gid )
  const group_member_cap = communityGroupAuthzRoles( group, 'member' )
  const group_group_cap = communityGroupAuthzRoles( group, 'group' )

  const { code } = useLangCurrent()
  const langs = useLangs()
  const ls = langs.map( elem => ( { value: elem.code, label : elem.nativeName } ) )

  const values = {
    head : communityGroupHead( group, [ code ], true ),
    headlines : communityGroupHeadlines( group ),
    desc : communityGroupDesc( group, [ code ], true ),
    descriptions : communityGroupDescriptions( group ),
  }

  const pOptions = t( 'rec.community.permission-levels-group', { returnObjects : true } )
  const jOptions = t( 'rec.community.join-options', { returnObjects: true } )
  const cOptions = t( 'rec.community.create-options', { returnObjects: true } )

  //

  const dValue = communityGroupAuthzRolesCountBits( group, capabilities.INDEX, [ 'public', 'site', 'member', 'group' ] ).toString()
  const d = pOptions.find( elem => elem.value === dValue ) ?? {}

  const iValue = communityGroupAuthzRolesCountBits( group, capabilities.read_info, [ 'public', 'site', 'member', 'group' ] ).toString()
  const i = pOptions.find( elem => elem.value === iValue ) ?? {}

  const rValue = communityGroupAuthzRolesCountBits( group, capabilities.read, [ 'public', 'site', 'member', 'group' ] ).toString()
  const r = pOptions.find( elem => elem.value === rValue ) ?? {}

  const jValue = ( group_member_cap & capabilities.join ) ? '3' : '0'
  const j = jOptions.find( elem => elem.value === jValue ) ?? {}

  const mcValue = ( group_member_cap & capabilities.create ) ? '2' : '0'
  const mc = cOptions.find( elem => elem.value === mcValue ) ?? {}

  const gcValue = ( group_group_cap & capabilities.create ) ? '2' : '0'
  const gc = cOptions.find( elem => elem.value === gcValue ) ?? {}

  const communityReload = useCommunityReload( cid )

  if( rsIsLoading( cmty ) ) return ( <ALMain><ALLoading /></ALMain>)
  if( rsIsError( cmty ) ) return ( <ALMain><p>t('err.error')</p></ALMain>)

  // TODO - change this to specific community.
  const onSuccess = () => { communityReload() }

  const gLangs = communityGroupLangs( group )
  const cls = gLangs.map( lng => ( { value: lng, label : t( 'nativename', { lng } ) } ) ) ?? []
  const l = {
    label : gLangs.map( lng => t( 'nativename', { lng } ) ).join(', '),
    value : gLangs,
  }
  const group_names = communityGroupNames( group )

  const settings = [
    {
      valueonly : true,
      label : t( 'rec.community.id' ),
      margin: 'none',
      value : gid,
      elems : [],
    },

    {
      valueonly : true,
      margin: 'none',
      value: ( <Divider/>),
      elems : [],
    },

    {
      label : t( 'rec.community.langs' ),
      margin: 'none',
      value : l.label,
      hidden: { group_id: gid },
      service : 'api/communities',
      oid : communityID( cmty ),
      op : 'languages',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'languages', name : t('rec.community.langs'), check: [ fieldRequiredArray, fieldChanged ], value: l.value, select: true, SelectProps : { multiple: true }, options : [ { value: '', label: t( 'act.sellang' ), disabled : true }, ...ls ] },
      ],
    },

    {
      label : t( 'rec.community.name' ),
      margin: 'none',
      value : communityGroupName( group, [ code ], true ),
      placeholder : communityGroupName( group, [ code ], false ),
      hidden: { group_id: gid },
      service : 'api/communities',
      oid : communityID( cmty ),
      op : 'name',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'language', local : true, name : t('rec.community.lang'), check: [ fieldRequired ], value: code, select: true, options : [ { value: '', label: t( 'act.sellang' ) }, ...cls ] },
        { Comp: ALFormTextField, id: 'name', sub : 'language', placeholder: communityGroupName( group, [ code ], false ), value: group_names, type: 'text', check: [ fieldRequired, fieldChanged ] },
      ],
    },

    {
      label : t( 'rec.community.head' ),
      margin: 'none',
      value : values.head,
      hidden: { group_id: gid },
      service : 'api/communities',
      oid : communityID( cmty ),
      op : 'headline',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'language', local : true, name : t('rec.community.lang'), check: [ fieldRequired ], value: code, select: true, options : [ { value: '', label: t( 'act.sellang' ), disabled : true }, ...cls ] },
        { Comp: ALFormTextField, id: 'headline', sub : 'language', value: values.headlines, type: 'text', minRows: 2, maxRows : 4, check: [ fieldChanged, fieldMax80 ] },
      ],
    },

    {
      label : t( 'rec.community.desc' ),
      margin: 'none',
      value : <ALEditor value={ values.desc } />,
      hidden: { group_id: gid },
      service : 'api/communities',
      oid : communityID( cmty ),
      op : 'description',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'language', local : true, name : t('rec.community.lang'), check: [ fieldRequired ], value: code, select: true, options : [ { value: '', label: t( 'act.sellang' ), disabled : true }, ...cls ] },
        { Comp: ALFormTextField, id: 'description', sub : 'language', value: values.descriptions, minRows: 6, maxRows : 10, check : [ fieldChanged, fieldMax2048 ] },
      ],
    },

    {
      valueonly : true,
      margin: 'none',
      value: ( <Divider/>),
      elems : [],
    },

    {
      valueonly : true,
      margin: 'none',
      value: t( 'rec.community.permissions' ),
      elems : [],
    },

    {
      label : t( 'rec.community.index' ),
      margin: 'none',
      value : d.label,
      hidden: { group_id: gid },
      service : 'api/communities',
      oid : communityID( cmty ),
      op : 'permissions_group_search',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'value', value: d.value,  select: true, check: [ fieldRequired, fieldChanged ], options : [ ...( pOptions.map( item => ({ value : item.value, label : item.label, disabled : item.disabled }) ) ) ] },
      ],
    },

    {
      label : <Box display='flex' alignItems='center' ><Box width='1rem' /><Box>{ t( 'rec.community.read' ) }</Box></Box>,
      margin: 'none',
      value : r.label,
      hidden: { group_id: gid },
      service : 'api/communities',
      oid : communityID( cmty ),
      op : 'permissions_group_read',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'value', value: r.value,  select: true, check: [ fieldRequired, fieldChanged ], options : [ ...( pOptions.map( item => ({ value : item.value, label : item.label, disabled : item.disabled }) ) ) ] },
      ],
    },

    {
      label : <Box display='flex' alignItems='center' ><Box width='2rem' /><Box>{ t( 'rec.community.read-info' ) }</Box></Box>,
      margin: 'none',
      value : i.label,
      hidden: { group_id: gid },
      service : 'api/communities',
      oid : communityID( cmty ),
      op : 'permissions_group_read_info',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'value', value: i.value,  select: true, check: [ fieldRequired, fieldChanged ], options : [ ...( pOptions.map( item => ({ value : item.value, label : item.label, disabled : item.disabled }) ) ) ] },
      ],
    },

    {
      label : t( 'rec.community.join' ),
      margin: 'none',
      value : j.label,
      hidden: { group_id: gid },
      service : 'api/communities',
      oid : communityID( cmty ),
      op : 'permissions_group_join',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'value', value: j.value,  select: true, check: [ fieldRequired, fieldChanged ], options : [ ...( jOptions.map( item => ({ value : item.value, label : item.label, disabled : item.disabled }) ) ) ] },
      ],
    },

    {
      label : t( 'rec.community.member-create' ),
      margin: 'none',
      value : mc.label,
      hidden: { group_id: gid },
      service : 'api/communities',
      oid : communityID( cmty ),
      op : 'permissions_group_create_member',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'value', value: mc.value,  select: true, check: [ fieldRequired, fieldChanged ], options : [ ...( cOptions.map( item => ({ value : item.value, label : item.label, disabled : item.disabled }) ) ) ] },
      ],
    },

    {
      label : <Box display='flex' alignItems='center' ><Box width='1rem' /><Box>{ t( 'rec.community.member-group-create' ) }</Box></Box>,
      margin: 'none',
      value : gc.label,
      hidden: { group_id: gid },
      service : 'api/communities',
      oid : communityID( cmty ),
      op : 'permissions_group_create_member_group',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'value', value: gc.value,  select: true, check: [ fieldRequired, fieldChanged ], options : [ ...( cOptions.map( item => ({ value : item.value, label : item.label, disabled : item.disabled }) ) ) ] },
      ],
    },
  ]

  return (
    <ALMain title={ `${ communityName( cmty, [ i18n.language ] ) } - Admin` } backTo={ backTo } alerts={ communityArchived( cmty ) ? [ { severity : 'warning', message: t( `mod.community.archived_${ communityArchived( cmty ) }` ) } ] : null } >
      <ALSettings items={ settings } />
    </ALMain>
  )
}
