//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useLocation,
} from "react-router-dom"

import makeStyles from '@mui/styles/makeStyles'

import {
  Home as HomeIcon,
  ConnectWithoutContact as ConnectionsIcon,
  Diversity2 as GroupsIcon,
  Login as LoginIcon,
  Language as LanguageIcon,
} from '@mui/icons-material'

import {
  useColorMode,
 } from '../ALColorMode'

import Avatar from '@mui/material/Avatar';

import { useTranslation } from 'react-i18next'

import { rsIsLoading } from '../../remote-state/core'

import {
  useImage,
  imageUrl,
} from '../../remote-state/images'

import {
  useFacet,
  facetDescription,
  facetName,
  facetInitials_1,
  facetAvatar,
} from '../../remote-state/facets'

import {
  useLangCurrent,
} from '../ALI18N'


//

const useStyles = makeStyles({
  avatar_sm: {
    width: '1.5rem',
    height: '1.5rem',
    fontSize: '1rem',
  },
})

//

export default function useNavItems() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { name: lang } = useLangCurrent()
  const facet = useFacet()
  const desc = facetDescription( facet )
  const name = facetName( facet )
  const initial = facetInitials_1( facet )
  const avatar = useImage( facetAvatar( facet ) )
  const colorMode = useColorMode()
  const location = useLocation()

  if( rsIsLoading( facet ) ) return []

  return (
    [
      { auth: -1, type : 'Link',  bottom: 0, primary: t('mod.home.name'), to: '/', iconComponent: <HomeIcon />},
      { disabled : true, auth:  0, type : 'Link',  bottom: 1, primary: t('mod.connections.name'), to: '/connections', iconComponent: <ConnectionsIcon/>},
      { auth:  0, type : 'Link',  bottom: 1, primary: t('mod.communities.name'), to: '/communities', iconComponent: <GroupsIcon/>},
      //{ auth:  0, type : 'Link',  bottom: 0, primary: 'Search', to: '/search', iconComponent: <SearchIcon/>},
      //{ auth:  0, type : 'Link',  bottom: 0, primary: 'Notices', to: '/account/notices', iconComponent: <NotificationsIcon/>},
      { auth:  0, type : 'Lang',  bottom: 0, primary: lang, iconComponent: <LanguageIcon/>},
      { auth:  0, type : 'ColorMode',  bottom: 0, primary: colorMode.name, iconComponent: colorMode.icon },
      { auth:  1, type : 'Account',  bottom: 0, primary: desc, iconComponent: <Avatar alt={ name } src={ imageUrl( avatar ) } variant='rounded' className={ classes.avatar_sm } >{ initial }</Avatar> },
      ...( ( '/' !== location.pathname && '/auth/signin' !== location.pathname ) ? [ { auth: -1, type : 'Link',  bottom: 0, primary: t('act.signin'), to: '/', iconComponent: <LoginIcon/>} ] : [] ),
    ]
  )
}