//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useCallback,
} from 'react'

import {
  useQuery,
  useQueryClient,
} from 'react-query'

import {
  rsInit,
  rsCheckType,
} from '../remote-state/core'

import {
  useALService,
} from '../components/client'

import {
  useAuth,
} from '../components/ALAuth'

// -----

function useUser( id ) {
  const auth = useAuth()
  const authID = auth ? auth.login.user_id : null
  const queryID = id || authID
  const service = useALService()

  const user =  useQuery( [ 'user', queryID ] , async () => {
      if( ! queryID ) return {}
      const result = await service( 'api/users', 'get', queryID )
      return result
    },
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  )

  return rsInit( user, 'user' )
}

//

function useUserReload( id ) {
  const queryClient = useQueryClient()
  const auth = useAuth()
  const authID = auth ? auth.login.user_id : null
  const queryID = id || authID

  return useCallback( () => {
     queryClient.invalidateQueries('user', queryID )
    },
    [ queryClient, queryID ]
  )
}

function ckUser( user ) {
  rsCheckType( user, 'user' )
}

//

function userID( user ) {
  ckUser( user )
  return user.data?.id
}

//

function userLanguages( user ) {
  ckUser( user )
  return user.data?.languages || []
}

//

function userFacetIDs( user ) {
  ckUser( user )
  return user.data?.facet_ids || []
}

// ----- Identity

function userIdentityFirst( user ) {
  ckUser( user )
  return user.data?.identity?.first || ''
}

function userIdentityLast( user ) {
  ckUser( user )
  return user.data?.identity?.last || ''
}

// -----

export {
  useUser,
  useUserReload,

  userID,

  userLanguages,

  userFacetIDs,

  userIdentityFirst,
  userIdentityLast,
}
