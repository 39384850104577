//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Suspense } from 'react'
import { ALLoadingPlain } from './components/ALLoading'
import './components/ALI18N'

ReactDOM.render(
  <React.Fragment>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
    <Router>
        <React.StrictMode>
          <Suspense fallback={ <ALLoadingPlain/> } >
            <App />
          </Suspense>
        </React.StrictMode>
    </Router>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
