//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useEffect,
  useState,
} from 'react'

import {
  useParams,
  useNavigate,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import {
  useLangs,
  useLangCurrent,
} from '../../components/ALI18N'

import {
  rsIsLoading,
} from '../../remote-state/core'

import {
  useCommunity,
  useCommunityReload,
  communityLangs,
  communityArchived,
} from '../../remote-state/communities'

import {
  fieldRequired,
  fieldChanged,
  useFieldStringMax,
  ALFormTextField,
} from '../../components/form/ALForm'

import ALForm from '../../components/form/ALForm'

import {
  ALMain,
} from '../../components/ALMain'

import ALLoading from '../../components/ALLoading'

//
export default function ALMainAdmGroupNew() {
  const { t } = useTranslation()
  const { cid } = useParams()
  const cmty = useCommunity( cid )
  const langsCmty = communityLangs( cmty )
  const { codeCur } = useLangCurrent()
  const code = langsCmty.indexOf( codeCur ) !== -1 ? codeCur : langsCmty[ 0 ]
  const ls = useLangs( langsCmty ).map( lang => { return { value: lang.code, label: lang.nativeName } } )
  const [ gid, setGID ] = useState( null )
  const navigate = useNavigate()

  const vOptions = t( 'rec.community.visibility-options', { returnObjects: true } )

  const communityReload = useCommunityReload( cid )

  const fieldMax64 = useFieldStringMax( 64 )

  const onSuccess = ( { id } ) => {
    communityReload()
    setGID( id )
  }

  useEffect( () => {
    if( gid ) navigate( `/communities/${ cid }/admin/group/${ gid }` )
  }, [ cid, gid, navigate ] )

  if( rsIsLoading( cmty ) ) return <ALLoading />

  const formElements = [
    { Comp: ALFormTextField, id: 'language', local : true, name : t('rec.community.lang'), check: [ fieldRequired ], value: code, select: true, options : [ { value: '', label: t( 'act.sellang' ), disabled : true }, ...ls ] },
    { Comp: ALFormTextField, id: 'name', sub : 'language', name: t('rec.community.name'), autoFocus: true, check: [ fieldRequired, fieldMax64 ] },
    { Comp: ALFormTextField, id: 'visibility', name : t('rec.community.visibility'), check: [ fieldRequired, fieldChanged ], value: '-', select: true, options : vOptions },
  ]

  // TODO - translate title
  return (
    <ALMain title='Create Group' alerts={ communityArchived( cmty ) ? [ { severity : 'warning', message: t( `mod.community.archived_${ communityArchived( cmty ) }` ) } ] : null }>
      <ALForm elements={ formElements } service='api/communities' oid={ cid } method='patch' op='group_add' onSuccess={ onSuccess } submitName={ t( 'act.create' ) } />
    </ALMain>
  )
}
