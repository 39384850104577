//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React, {useState} from 'react'

import {
  useStoreValue,
  useSetStoreValue,
} from 'react-context-hook'

import {
  useSearchParams,
  Link,
} from "react-router-dom"

import { useTranslation } from 'react-i18next'

import ReactMarkdown from 'react-markdown'

import {
  Alert,
  AlertTitle,
  Divider,
  Button,
} from '@mui/material'

import {
  LinkedIn as LinkedInIcon,
} from '@mui/icons-material'

import ALForm, {
  fieldRequired,
  useFieldStringMax,
  fieldEmail,
  fieldMatch,
  ALFormTextField,
  ALFormCheckbox,
  ALFormHidden,
} from '../../components/form/ALForm'

import {
  useLangs,
  useLangCurrent,
} from '../../components/ALI18N'

import {
  useAuth,
} from '../../components/ALAuth'

import ALPage from '../../components/ALPage'

import {
  ALAuthCard,
  ALAuthCardHead,
  ALAuthCardContent,
  ALAuthCardActions,
} from '../../components/ALAuthCard/ALAuthCard'

//

function useInviteCodeInit() {
  const [ search ] = useSearchParams()
  const invite_code = search.get( 'invite_code' )
  const old = useStoreValue( [ 'auth', 'invite_code' ], undefined )
  const set = useSetStoreValue( [ 'auth', 'invite_code' ] )
  if( old == null ) set( invite_code )
}

function useInviteCode() {
  return useStoreValue( [ 'auth', 'invite_code' ], undefined )
}

//

export default function ALSignUp() {
  const { t } = useTranslation()
  const invite_code = useInviteCode()

  const langs = useLangs().map( lang => { return { value: lang.code, label: lang.nativeName } } )
  const lang_val = useLangCurrent().code
  const [ created, setCreated ] = useState(false)
  const now = new Date()

  const fieldMax48 = useFieldStringMax( 48 )
  const fieldMax128 = useFieldStringMax( 128 )

  const auth = useAuth()

  const successHandler = async () => {
    setCreated(true)
  }

  function onSubmit( values ) {
    return {
      ...values,
      over_18 : true,
      agreement_social_contract : true,
      agreement_user_agreement : true,
      agreement_privacy_policy : true,
      agreement_cookies_policy : true,
    }
  }

  const formElements = [
    { Comp: ALFormTextField, id: 'language', name : t( 'rec.posts.lang' ), check: [ fieldRequired ], value: lang_val, select: true, options : [ { value: '', label: t( 'rec.posts.lang_sel' ) }, ...langs ] },
    { Comp: ALFormTextField, id: 'first', name: t('mod.auth.first') , type:'text', autoFocus: true, check: [ fieldRequired, fieldMax48 ] },
    { Comp: ALFormTextField, id: 'last', name: t('mod.auth.last') , type:'text', check: [ fieldRequired, fieldMax48 ] },
    { Comp: ALFormTextField, id: 'email', name: t('mod.auth.email') , type:'email', check: [ fieldRequired, fieldMax128, fieldEmail ] },
    { Comp: ALFormTextField, id: 'password', name: t('mod.auth.password'), type: 'password', check: [ fieldRequired ] },
    { Comp: ALFormTextField, id: 'verify', name: t('mod.auth.repassword'), type: 'password', check: [ fieldRequired, fieldMatch( 'password', 'err.passwd_match' ) ], local : true },

    { Comp: ALFormTextField, id: 'invite_code', value : invite_code, name: t('mod.auth.invitecode'), check: [ fieldRequired ] },

    { Comp: ALFormHidden,    id: 'date', value: now, local: true },
    { Comp: ALFormCheckbox,  id: 'ag_age', name: <ReactMarkdown components={{ p : 'span' }}>{ t( 'mod.auth.acc-age' )}</ReactMarkdown>, check: [ fieldRequired ], local: true },
    { Comp: ALFormCheckbox,  id: 'ag_sc',  name: <ReactMarkdown components={{ p : 'span' }}>{ t( 'mod.auth.acc-ag-sc' )}</ReactMarkdown>,  check: [ fieldRequired ], local: true },
    { Comp: ALFormCheckbox,  id: 'ag_o', name: <ReactMarkdown components={{ p : 'span', a: ( { ...props} ) => <Link to={ props.href }>{ props.children }</Link>} } >{ t( 'mod.auth.acc-ag-o' )}</ReactMarkdown>, check: [ fieldRequired ], local: true },
  ]

  const buttonSecondary = (
    <Button
      component={Link}
      to="/"
      variant={ created ? 'contained' : 'outlined' }
    >
    { t( 'act.signin' ) }
    </Button>
  )

  if( created ) {
    return (
      <ALPage>
        <ALAuthCard >
          <ALAuthCardHead >{t( 'mod.auth.signup' ) }</ALAuthCardHead>
          <Divider light />
          <ALAuthCardContent sx={{ alignItems: 'center' }}>
            { t( 'msg.account_created' ) }
          </ALAuthCardContent>
          <ALAuthCardActions>
            { buttonSecondary }
          </ALAuthCardActions>
        </ALAuthCard>
      </ALPage>
    )
  }

  return (
    <ALPage>
      <ALAuthCard >
        <ALAuthCardHead >{t( 'mod.auth.signup' ) }</ALAuthCardHead>

        <Divider light />

        { ! auth &&
          <Alert severity="warning" sx={{ marginTop: '1rem' }} ><AlertTitle>Status: Prototype</AlertTitle>Follow us to track our progress on<Button component='a' href="https://www.linkedin.com/company/avvia-life/" target='#' endIcon={ <LinkedInIcon /> } >LinkedIn</Button></Alert>
        }

        <ALForm
          elements={formElements}
          service='/api/users'
          method='create'
          onSuccess={successHandler}
          submitName={ t( 'act.signup') }
          buttonSecondary={buttonSecondary}
          onSubmit={ onSubmit }
        />

      </ALAuthCard>
    </ALPage>
  )
}

export {
  useInviteCodeInit,
  useInviteCode,
}
