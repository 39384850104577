//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useCallback,
} from 'react'

import {
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  useMutation,
} from 'react-query'

import { paramsForServer } from 'feathers-hooks-common'

import {
  rsInit,
  rsCheckType,
  rsIsLoading,
  rsIsError,

  rsiInit,
  rsiCheckType,
} from '../remote-state/core'

import {
  useFacet,
  facetID,
} from './facets'

import {
  useALService,
} from '../components/client'

import {
  objGetLang,
} from '../components/ALI18N'

// -----

function usePost( id ) {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  const post = useQuery( [ facet_id, 'post', id ], async () => {
    if( ! id ) return {}
    return await service( 'api/posts', 'get', id, paramsForServer( { facet_id } ) )
  } )

  return rsInit( post, 'post' )
}

function ckPost( post ) {
  rsCheckType( post, 'post' )
}

function usePostReload( post ) {
  ckPost( post )
  const queryClient = useQueryClient()
  const facet_id = facetID( useFacet() )
  const id = postID( post )

  return useCallback( () => {
    return queryClient.invalidateQueries( [ facet_id, 'post', id ], { exact: false } )
  }, [ facet_id, queryClient, id ] )
}

//

function usePatch( post, onSettled, op ) {
  ckPost( post )
  const service = useALService()
  const facet_id = facetID( useFacet() )

  return useMutation( async ( data ) => {
    return await service( 'api/posts', 'patch', postID( post ), { ...( data || {} ), op : op }, paramsForServer( { facet_id } ) )
  },
  {
    mutationKey: [ 'post', postID( post ), 'patch' ],
    onSettled: onSettled,
  } )
}

function usePostReport( post, onSettled ) {
  return usePatch( post, onSettled, 'report' )
}

function usePostDelete( post, onSettled ) {
  return usePatch( post, onSettled, 'delete' )
}

function usePostUndelete( post, onSettled ) {
  return usePatch( post, onSettled, 'undelete' )
}

function usePostLock( post, onSettled ) {
  return usePatch( post, onSettled, 'lock' )
}

function usePostUnlock( post, onSettled ) {
  return usePatch( post, onSettled, 'unlock' )
}

function usePostHide( post, onSettled ) {
  return usePatch( post, onSettled, 'hide' )
}

function usePostUnhide( post, onSettled ) {
  return usePatch( post, onSettled, 'unhide' )
}

function usePostPin( post, onSettled ) {
  return usePatch( post, onSettled, 'pin' )
}

function usePostUnpin( post, onSettled ) {
  return usePatch( post, onSettled, 'unpin' )
}

//

function postID( post ) {
  ckPost( post )
  return post?.data?.id
}

function postSID( post ) {
  ckPost( post )
  return post?.data?.sid
}

function postCommunityID( post ) {
  ckPost( post )
  return post?.data?.community_id
}

//

function postBody( post ) {
  ckPost( post )
  return objGetLang( post?.data?.body )
}

function postBodies( post ) {
  ckPost( post )
  const bodies = post?.data?.body ?? {}

  return Object.keys( bodies ).reduce( ( acc, key ) => { acc[ key ] = bodies[ key ].text; return acc } , {} )
}

function postImages( post ) {
  ckPost( post )
  return post.data?.images || []
}

function postGroupID( post ) {
  ckPost( post )
  return post.data?.group_id
}

//

function postCreatedAt( post ) {
  ckPost( post )
  return post.data?.created?.at
}

function postCreatedBy( post ) {
  ckPost( post )
  return post.data?.created?.by
}

function postHistory( post ) {
  ckPost( post )
  return post.data?.history
}

//

function postAuthz( post ) {
  ckPost( post )
  return post.data?.authz ?? {}
}

//

function postStatsReplies( post ) {
  ckPost( post )
  return post?.data?.stats?.replies
}

// -----

function usePosts( { community_id, group_id, sort, pinned } ) {
  const service = useALService()
  const facet_id = facetID( useFacet() )
  const lim = undefined // TODO - consider allowing users to specify

  const results = useInfiniteQuery( [ facet_id, 'posts', group_id, 'ids', sort, ...( pinned ? [ 'pinned' ] : [] ) ] , async ( { pageParam = {} } ) => {
    const result = await service( 'api/posts', 'find', paramsForServer( {
      query: pageParam.state
        ? { state : pageParam.state }
        : { op : 'by_group_id', community_id, group_id, sort, lim, pinned },
      facet_id } ) )
    return result
  },
  {
    getNextPageParam: lastPage => lastPage.has_more
      ? ( { state: lastPage.state } )
      : undefined
  })

  return rsiInit( results, 'posts' )
}

// -----

function usePostsRefresh() {
  const queryClient = useQueryClient()
  const facet_id = facetID( useFacet() )

  return useCallback( () => {
    queryClient.removeQueries( [ facet_id, 'posts' ] )
  }, [ facet_id, queryClient ] )
}

function ckPosts( posts ) {
  rsCheckType( posts, 'posts' )
}

//

function postsIDs( posts ) {
  rsiCheckType( posts, 'posts' )

  if( rsIsLoading( posts ) || rsIsError( posts ) ) return []

  const ids = ( posts.data.pages || [] ).reduce( ( acc, page ) => { return [ ...acc, ...( page.ids || [] ) ] }, [] )

  return ids
}

function postsHasMore( posts ) {
  ckPosts( posts )

  if( rsIsLoading( posts ) || rsIsError( posts ) ) return false

  return posts.data.pages[ posts.data.pages.length -1 ].has_more
}

// -----


export {
  usePost,
  usePostReload,

  usePostReport,
  usePostDelete,
  usePostUndelete,
  usePostLock,
  usePostUnlock,
  usePostHide,
  usePostUnhide,
  usePostPin,
  usePostUnpin,

  postID,
  postSID,
  postCommunityID,

  postCreatedAt,
  postCreatedBy,

  postHistory,

  postAuthz,

  postBody,
  postBodies,
  postImages,
  postGroupID,

  postStatsReplies,

  usePosts,
  //usePostAdd,
  usePostsRefresh,
  postsIDs,
  postsHasMore,
}
