//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useCallback,
} from 'react'

import {
  useQuery,
  useQueryClient,
  useMutation,
} from 'react-query'

import { paramsForServer } from 'feathers-hooks-common'

import {
  rsInit,
  rsCheckType,
} from '../remote-state/core'

import {
  useALService,
} from '../components/client'

import {
  useUser,
  userFacetIDs,
  useUserReload,
} from './users'

import {
  useAuth,
} from '../components/ALAuth'

import {
  fieldTextLangs,
} from '../components/ALI18N'

import {
  useSessionStoreValue,
  useSessionStoreSet,
} from '../components/ALSessionStorage'

import { useColorModeID } from '../components/ALColorMode'

// -----

const key_facet_id = 'facet_id'
function useFacetCurrentID() {
  const setFacetID = useFacetCurrentIDSet()
  const user = useUser()
  const def = user?.data?.defaults?.facet_id || userFacetIDs( user )[ 0 ] || null
  // TODO need to store user_id and if user_id doesn't match, clear sessionStore
  const fid = useSessionStoreValue( key_facet_id, def )

  // Needed as default can be set before user is fully loaded
  if( ! fid && def ) setFacetID( def )

  return fid
}

function useFacetCurrentIDSet() {
  return useSessionStoreSet( key_facet_id )
}

// -----

function useFacet( id ) {
  const mode = useColorModeID()
  const user = useUser()
  const fid = useFacetCurrentID( user )
  const queryID = id || fid

  const service = useALService()

  const facet =  useQuery( [ 'facet', queryID ] , async () => {
      if( ! queryID ) return {}
      return await service( 'api/facets', 'get', queryID )
    },
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  )

  if( facet.data ) {
    facet.data.avatar = facet.data[ `avatar_${ mode }` ] || facet.data?.avatar_light || ''
  }

  return rsInit( facet, 'facet' )
}

function useFacetReload( id ) {
  const queryClient = useQueryClient()
  const auth = useAuth()
  const authID = auth ? auth.login.user_id : null
  const queryID = id || authID

  return useCallback( () => {
      queryClient.invalidateQueries('facet', queryID )
    },
    [ queryClient, queryID ]
  )
}

function ckFacet( facet ) {
  rsCheckType( facet, 'facet' )
}

function facetID( facet ) {
  ckFacet( facet )
  return facet.data?.id || '000000000000000000000000'
}

function facetDescription( facet ) {
  ckFacet( facet )
  return facet.data?.description || facet.id
}

function facetVisibilityLevel( facet ) {
  ckFacet( facet )
  return ( facet.data?.permissions?.visibility_level ?? '-' ).toString()
}

function facetName( facet, langs, nodefault ) {
  ckFacet( facet )
  return fieldTextLangs( facet.data?.name , langs, nodefault )
}

function facetNameR( facet ) {
  ckFacet( facet )
  return facet.data?.name
}

function facetInitials_1( facet, langs, nodefault ) {
  ckFacet( facet )
  return fieldTextLangs( facet.data?.initials_1 , langs, nodefault )
}

function facetInitials_1R( facet ) {
  ckFacet( facet )
  return facet.data?.initials_1
}

function facetInitials_2( facet, langs, nodefault ) {
  ckFacet( facet )
  const initials_2 = fieldTextLangs( facet.data?.initials_2 , langs, nodefault )
  if( initials_2 ) return initials_2

  return facetInitials_1( facet, langs, nodefault )
}

function facetInitials_2R( facet ) {
  ckFacet( facet )
  return facet.data?.initials_2
}

function facetPronouns( facet, langs, nodefault ) {
  ckFacet( facet )
  return fieldTextLangs( facet.data?.pronouns , langs, nodefault )
}

function facetPronounsR( facet, langs, nodefault ) {
  ckFacet( facet )
  return facet.data?.pronouns
}

function facetAvatar( facet, mode ) {
  ckFacet( facet )
  switch( mode ) {
    default: return facet.data?.avatar || ''
    case 'light': return facet.data?.avatar_light || ''
    case 'dark': return facet.data?.avatar_dark || ''
  }
}

// -----

function useFacetCreate( onSuccess ) {
  const facet_id = facetID( useFacet() )

  const service = useALService()
  const reload = useUserReload()

  return useMutation(
    async () => {
      return await service( 'api/facets', 'create', {}, paramsForServer( { facet_id } ) )
    },
    {
      onSettled : ( data ) => {
        reload()
        if( onSuccess ) onSuccess( data )
      },
    })
}

//

export {
  useFacetCurrentID,
  useFacetCurrentIDSet,
  useFacet,
  useFacetReload,
  facetID,
  facetDescription,
  facetVisibilityLevel,
  facetName,
  facetNameR,
  facetInitials_1,
  facetInitials_1R,
  facetInitials_2,
  facetInitials_2R,
  facetPronouns,
  facetPronounsR,
  facetAvatar,
  useFacetCreate,
}
