//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useTheme,
 } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

function useMobile() {
  const theme = useTheme()
  const matches = useMediaQuery( theme.breakpoints.down( 'narrow' ) )

  return matches
}

function useNarrow() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('narrow'))

  return matches
}

function useTablet() {
  const theme = useTheme()
  const matches = useMediaQuery( theme.breakpoints.between( 'sm', 'md' ) )

  return matches
}

function useDesktop() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return matches
}

export {
  useNarrow,
  useMobile,
  useTablet,
  useDesktop,
}