//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import { AuthenticatedRoute } from '../../components/ALRoute'

import { useDesktop } from '../../components/ALScreenSizing'

import {
  useFacet,
  facetID,
} from '../../remote-state/facets'

import AL404 from '../../pages/AL404'

import ALLayout2 from '../../components/ALLayout2'

import ALSideRoot from './ALSideRoot'
import ALMainFacet from './ALMainFacet'
import ALMainIdentity from './ALMainIdentity'

export default function ALRouter() {
  const desktop = useDesktop()
  const facet_id = facetID( useFacet() )

  return (
    <Routes>
      <Route element={ <AuthenticatedRoute /> } >
        <Route element={ <ALLayout2 desktop={ desktop } side={ <ALSideRoot /> } /> } >
          <Route path="/" element={ desktop ? <Navigate to={ `facets/${ facet_id }` } /> : <ALSideRoot /> } />
          <Route path="facets" element={ <Navigate to={ `${ facet_id }` } /> } />
          <Route path="facets/:f_id" element={ <ALMainFacet /> } />
          <Route path="identity" element={ <ALMainIdentity /> } />
        </Route>
      </Route>
     <Route path='*' element={ <AL404 /> } />
    </Routes>
  )
}
