//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

import { ReactQueryDevtools } from 'react-query/devtools'

//

const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        retry: ( failureCount, error ) => { return false },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        //cacheTime: 1000 * 30,
        //refetchInterval: 1000 * 30,
        //refetchIntervalInBackground: false,
        //suspense: false,
        //staleTime: 1000 * 30,
        staleTime: 1000 * 60 * 1,
      },
      mutations : {
        //retry: 2,
      }
    }
  } )

function withALQueryProvider( Component ) {
  return function ( ...props ) {
    return <QueryClientProvider client={ queryClient } >
      <Component { ...props }/>
      <ReactQueryDevtools initialIsOpen={ false } />
    </QueryClientProvider>
  }
}

export {
  withALQueryProvider,
}