//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React, {
  useEffect,
  useState,
} from "react"

import {
  useStore,
} from 'react-context-hook'

import {
  Link,
  useLocation,
  Navigate,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import {
  Box,
  Tab,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
} from '@mui/material'

import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab'

import { makeStyles } from '@mui/styles'

import {
  ChevronRight as ChevronRightIcon,
  Group as GroupIcon
} from '@mui/icons-material'

import {
  rsIsLoading,
  rsIsError,
} from '../../remote-state/core'

import {
  useCommunity,
  communityID,
  communityName,
  communityAvatarID,
  communityMemCnt,

  useCommunitiesMyLists,
  communitiesListRole,

  ROLE_MEM,
  ROLE_LEA,
  ROLE_ADM,
  ROLE_OWN,
} from '../../remote-state/communities'

import {
  useImage,
  imageUrl,
 } from '../../remote-state/images'

import {
  useDesktop,
} from '../../components/ALScreenSizing'

import {
  ALSide,
  ALSideList,
} from '../../components/ALSide'

import ALLoading from '../../components/ALLoading'

//

const useStyles = makeStyles((theme) => ({
  tab : {
    minWidth: '0rem',
  },
  tab_panel : {
    padding: '0',
  },
  filter : {
    margin: '0.25rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  chip_filter : {
    borderRadius: '4px',
  },
  list_item_icon : {
    minWidth: 0,
  },
  side_item: props => ({
    margin: '0.25rem',
  }),
}));

//

function ALCommunitiesListItem( { cid, path } ) {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const cmty = useCommunity( cid )
  const name = communityName( cmty, [ i18n.language ] )
  const mem_cnt = communityMemCnt( cmty )
  const avatar_id = communityAvatarID( cmty )
  const img = useImage( avatar_id )

  if( rsIsLoading( cmty ) ) return <ListItem><ALLoading /></ListItem>

  if( rsIsError( cmty ) ) return <ListItem>{ t( 'err.error' ) }</ListItem>

  return (
    <ListItem button component={ Link } to={ `${path}/${ communityID( cmty ) }` } >
      <ListItemAvatar>
        <Avatar src={ imageUrl( img ) } variant='rounded' sx={{ width: '2.5rem', height: '2.5rem ' }} ><GroupIcon /></Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={ <strong>{name}</strong>}
        secondary={ `${ mem_cnt } ${ t('rec.community.mem', {count : mem_cnt } ) }` } />
      <ListItemIcon className={classes.list_item_icon}><ChevronRightIcon /></ListItemIcon>
    </ListItem>
  )
}

//

function ALCommunitiesList( { path, sel, tab, cids } ) {
  return (
    <List component='nav' aria-label=''>
      { cids.map( (cid, idx) => <ALCommunitiesListItem cid={ cid } path={ path } key={idx} /> ) }
    </List>
  )
}

export default function ALSideRoot() {
  const { t } = useTranslation()
  const classes = useStyles()
  const pathcur = useLocation().pathname

  const lists = useCommunitiesMyLists()
  const listAll = communitiesListRole( lists, ROLE_MEM )
  const listLea = communitiesListRole( lists, ROLE_LEA )
  const listAdm = communitiesListRole( lists, ROLE_ADM )
  const listOwn = communitiesListRole( lists, ROLE_OWN )
  const isLea = Boolean( listLea.length )
  const isAdm = Boolean( listAdm.length )
  const isOwn = Boolean( listOwn.length )

  const path='/communities'

  const [ tab, setTab ] = useStore( 'communities.tab', 'my' )

  const [ sel, setSel ] = useState('')

  const desktop = useDesktop()
  const location = useLocation()

  useEffect( () => {
    const pathComp = location.pathname.split('/')

    if ( ! pathComp[2] && desktop ) { setSel('dashboard') }
    else if ( desktop ) { setSel(pathComp[2]) }
    else { setSel('') }
  }, [ location, desktop ] )

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  const dashList = [
    { label: t('mod.communities.dashboard'), to: `${path}/dashboard`, selected: sel === 'dashboard' },
  ]

  const topList = [
    { label: t('mod.communities.discover'), to: `${path}/discover`, selected: sel === 'discover' },
    { label: t('mod.communities.create'), to: `${path}/create`, selected: sel === 'create' },
  ]

  if( ! rsIsLoading( lists ) && ! listAll.length && ( pathcur === path || pathcur === `${path}/dashboard` ) ) { return <Navigate to={ `${path}/discover` } /> }

  // TODO - add "create list" button

  return (
    <Box>
      <ALSide>
          <ALSideList items={ dashList } />
      </ALSide>

      <ALSide>
        <ALSideList items={ topList } />
      </ALSide>

      <ALSide>
        <TabContext value={tab} >
          <TabList
            variant="fullWidth"
            value={tab}
            indicatorColor='primary'
            textColor='primary'
            onChange={handleChange}
            aria-label=''
          >
            <Tab label={ t('mod.communities.joined') } value='my' className={ classes.tab } />
            { isLea && <Tab label={ t('mod.communities.lead') } value='lea' className={ classes.tab } /> }
            { isAdm && <Tab label={ t('mod.communities.admin') } value='adm' className={ classes.tab } /> }
            { isOwn && <Tab label={ t('mod.communities.own') } value='own' className={ classes.tab } /> }
          </TabList>

          <TabPanel value='my' className={ classes.tab_panel } >
            <ALCommunitiesList path={ path } sel={ sel } tab='my' cids={ listAll } />
          </TabPanel>

          { isLea &&
            <TabPanel value='lea' className={classes.tab_panel} >
              <ALCommunitiesList path={ path } sel={ sel } tab='lea' cids={ listLea } />
            </TabPanel>
          }

          { isAdm &&
            <TabPanel value='adm' className={classes.tab_panel} >
              <ALCommunitiesList path={ path } sel={ sel } tab='adm' cids={ listAdm } />
            </TabPanel>
          }

          { isOwn &&
            <TabPanel value='own' className={classes.tab_panel} >
              <ALCommunitiesList path={ path } sel={ sel } tab='own' cids={ listOwn } />
            </TabPanel>
          }
        </TabContext>
      </ALSide>
    </Box>
  )
}
