//
// Copyright 2022 Avvia Life, All Rights Reserved
//

function rsInit( state, type ) {
  const nstate = { ...state, _rs: true, _type: type }
  return nstate
}

function rsIsType( rs, type ) {
  if( ! rs || ! rs._rs || rs._type !== type ) return false
  return true
}

function rsCheckType( rs, type ){
  isRS( rs )
  if( rs._type !== type ) {
    throw new Error('Unknown type')
  }
}

function isRS( rs ) {
  if( ! rs || ! rs._rs ) {
    throw new Error('Expected RS type')
  }
}

function rsIsLoading( rs, reqData = false ) {
  // TODO finish converting
  //isRS( rs )
  if( rs.isLoading ) return true

  if( reqData && ! rs.data ) return true

  return false
}

function rsIsError( rs ) {
  // TODO finish converting
  //isRS( rs )
  return rs.isError
}

function rsError( rs ) {
  // TODO finish converting
  //isRS( rs )
  return rs.error
}

//

function rsiInit( state, type ) {
  const nstate = { _rs: true, _rsi: true, _type: type, ...state }
  return nstate
}

function isRSI( rs ) {
  if( ! rs || ! rs._rs || ! rs._rsi) {
    throw new Error('Expected RSI type')
  }
}

function rsiCheckType( rs, type ){
  isRSI( rs )
  if( rs._type !== type ) {
    throw new Error('Unknown type')
  }
}

function rsiIsFetchingNextPage( rs ) {
  isRSI( rs )
  return rs.isFetchingNextPage
}

function rsiPages( rs ) {
  isRSI( rs )

  return rs.data.pages
}

function rsiFetchNextPage( reply ) {
  isRSI( reply )
  return reply.fetchNextPage()
}

export {
  rsInit,
  rsIsType,
  rsCheckType,
  rsIsLoading,
  rsIsError,
  rsError,

  rsiInit,
  rsiCheckType,
  rsiIsFetchingNextPage,
  rsiPages,
  rsiFetchNextPage,
}