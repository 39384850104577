//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'

import {
  useStoreValue,
  useSetStoreValue,
  store,
} from 'react-context-hook'

import {
  useParams,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import {
  difference,
} from 'lodash'

import {
  format,
  formatDistanceToNowStrict,
 } from 'date-fns'


import {
  Avatar,

  Box,
  Divider,

  Button,
  IconButton,

  Card,
  CardActions,

  Dialog,
  DialogContent,
  DialogTitle,

  List,
  ListItem,
  ListItemIcon,
  ListItemText,

  Menu,
  MenuItem,

  TextField,

  styled,
} from '@mui/material'

import {
  BookmarkAddOutlined as BookmarkAddOutlinedIcon,
  IosShare as IosShareIcon,
  VolumeUpOutlined as VolumeUpOutlinedIcon,
  VolumeDownOutlined as VolumeDownOutlinedIcon,
  MoreVert as MoreVertIcon,

  Close as CloseIcon,
  History as HistoryIcon,
  Flag as FlagIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  PushPin as PushPinIcon,
  Sort as SortIcon,
} from '@mui/icons-material'

import {
  useRenderLanguages,
  useLangCurrent,
  useLocaleCurrent,
  useLangs,
} from './ALI18N'

import {
  rsIsLoading,
  rsIsError,

  rsiFetchNextPage,
  rsiIsFetchingNextPage,
} from '../remote-state/core'

import {
  capabilities,
  object,
  roles,
  checkCapabilities,
  checkObject,
  checkRoles,
} from '../remote-state/authorization'

import {
  useCommunity,
  communityID,
  communityName,
  communityAvatarID,

  communityGroupLangs,

  communityGroup,
  communityGroups,
  communityGroupID,
  communityGroupName,

  useCommunityRoles,
  communityUserRoles,

  ROLE_GRP,
  ROLE_CON,
  ROLE_LEA,
  ROLE_ADM,
  ROLE_OWN,
} from '../remote-state/communities'

import {
  useFileLimits,
  fileLimitsList,
} from '../remote-state/files'

import {
  usePost,
  usePostReload,

  usePostReport,
  usePostDelete,
  usePostUndelete,
  usePostLock,
  usePostUnlock,
  usePostHide,
  usePostUnhide,
  usePostPin,
  usePostUnpin,

  postSID,
  postCommunityID,

  postCreatedAt,
  postCreatedBy,

  postHistory,

  postAuthz,

  postBody,
  postBodies,
  postImages,
  postGroupID,

  postStatsReplies,

  usePosts,
  usePostsRefresh,

  postsIDs,
  postsHasMore,
  postID,
} from '../remote-state/posts'

import {
  useRepliesReload,
} from '../remote-state/replies'

import {
  useImage,
  imageUrl,
} from '../remote-state/images'

import {
  useFacet,
  facetName,
  facetInitials_2,
  facetPronouns,
  facetAvatar,
} from '../remote-state/facets'

import { useMobile } from './ALScreenSizing'

import { ALLink } from './ALLink'

import {
  ALReplies,
  ALRepliesActionAdd,
  ALRepliesActionToggleShow,
} from './ALReplies'

import ALForm, {
  useFieldRequiredOneOf,
  useFieldStringMax,
  fieldChangedOne,
  fieldRequired,
  ALFormTextField,
  ALFormHidden,
  ALFormMedia,
} from './form/ALForm'

import {
  ALEditor,
} from './ALDraftJS'

import {
  ALTile,
} from './ALTile'

import ALLoading from './ALLoading'

//

function urlPost( cid, gid = '' ) {
  return `/communities/${ cid }/posts/${ gid }`
}

// -----

function usePostsAdded() {
  return useStoreValue( [ 'posts', 'added' ], [] )
}

function usePostsAddedAdd() {
  const set = useSetStoreValue( [ 'posts','added' ] )
  const prev = usePostsAdded()

  return useCallback( ( id ) => set( [ id, ...prev ] ), [ set, prev ] )
}

function postsAddedDelete() { store.delete( [ 'posts', 'added' ] ) }

// -----

function usePostsSortValue() {
  return useStoreValue( [ 'posts', 'sort' ], 'newest' )
}

function usePostsSortSet() {
  return useSetStoreValue( [ 'posts', 'sort' ] )
}

function postsSortDelete() { store.delete( [ 'posts', 'sort' ] ) }

// -----

function ALPostCommunity( { post } ) {
  const { t } = useTranslation()
  const cid = postCommunityID( post )
  const cmty = useCommunity( cid )
  const cmty_name = communityName( cmty )
  const cmty_img = useImage( communityAvatarID( cmty ) )

  return (
    <Box sx={{  p: '0.5rem', display:'flex', typography: 'body2', lineHeight: 'normal' }}>
      <Box>{ t( 'mod.posts.in' ) }</Box>
      <ALLink to={ urlPost( cid ) } >
        <Box ml={ '0.25rem' } display='flex' flexWrap='nowrap' >
          <Box sx={{ pr: '0.25rem' }}><Avatar sx={{ height: '1rem', width: '1rem'}} src={ imageUrl( cmty_img ) } variant='rounded' /></Box>
          <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{ cmty_name }</Box>
        </Box>
      </ALLink>
    </Box>
  )

}

// -----

function ALPostMenu( { post } ) {
  const { t } = useTranslation()
  const [ anchorEl, setAnchorEl ] = useState( false )
  const open = Boolean( anchorEl )

  const doReloadPost = usePostReload( post )
  const doReloadReplies = useRepliesReload( [], postSID( post ) )

  const [ showEdit, setShowEdit ] = useState( false )
  const [ showHistory, setShowHistory ] = useState( false )

  // const doEdit = usePostEdit( post, onSuccess )
  const doReport = usePostReport( post, onSuccess )
  const doLock = usePostLock( post, onSuccess )
  const doUnlock = usePostUnlock( post, onSuccess )
  const doHide = usePostHide( post, onSuccess )
  const doUnhide = usePostUnhide( post, onSuccess )
  const doDelete = usePostDelete( post, onSuccess )
  const doUndelete = usePostUndelete( post, onSuccess )
  const doPin = usePostPin( post, onSuccess )
  const doUnpin = usePostUnpin( post, onSuccess )

  const pAuthz = postAuthz( post )

  const isAuthor = checkRoles( pAuthz, roles.author )

  const mayEdit = checkCapabilities( pAuthz, capabilities.edit )

  const mayPin = checkCapabilities( pAuthz, capabilities.pin )
  const isPinned = checkObject( pAuthz, object.pinned )

  const isLockedAuthor = checkObject( pAuthz, object.locked_author )
  const isLockedOther = checkObject( pAuthz, object.locked_other )
  const mayLock = checkCapabilities( pAuthz, capabilities.lock )

  const isHiddenAuthor = checkObject( pAuthz, object.hidden_author )
  const isHiddenOther = checkObject( pAuthz, object.hidden_other )
  const mayHide = checkCapabilities( pAuthz, capabilities.hide )

  const isDeleted = checkObject( pAuthz, object.isDeleted )
  const mayDelete = checkCapabilities( pAuthz, capabilities.delete )

  const history = postHistory( post )

  function onSuccess() {
    doReloadPost()
    doReloadReplies()
  }

  function handleClick( e ) {
    setAnchorEl( e.currentTarget )
  }

  function handleClose() {
    setAnchorEl( null )
  }

  return (
    <div>
      <IconButton
        id="delete-button"
        aria-controls="delete menu"
        aria-haspopup="true"
        aria-expanded={ open ? 'true' : undefined }
        onClick={ handleClick }
        sx={{ p: '0' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="delete-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
        MenuListProps={{
          'aria-labelledby':'delete-button',
        }}
      >
        {   mayEdit &&
          <MenuItem onClick={ () => { setShowEdit( true ); handleClose(); } } >
            <ListItemIcon><EditIcon /></ListItemIcon>
            <ListItemText>{ t('act.edit') }</ListItemText>
          </MenuItem>
        }

        {   history &&
          <MenuItem onClick={ () => { setShowHistory( true ); handleClose(); } } >
            <ListItemIcon><HistoryIcon /></ListItemIcon>
            <ListItemText>{ t('act.history') }</ListItemText>
          </MenuItem>
        }

        { /* add view edit history menu item */ }

        { ! isAuthor &&
          <MenuItem onClick={ () => { doReport.mutate(); handleClose(); } } disabled>
            <ListItemIcon><FlagIcon /></ListItemIcon>
            <ListItemText>{ t('act.report') }</ListItemText>
          </MenuItem>
        }


        {   isPinned && mayPin &&
          <MenuItem onClick={ () => { doUnpin.mutate(); handleClose(); } }>
            <ListItemIcon><PushPinIcon /></ListItemIcon>
            <ListItemText>{ t('act.unpin') }</ListItemText>
          </MenuItem>
        }
        { ! isPinned && mayPin &&
          <MenuItem onClick={ () => { doPin.mutate(); handleClose(); } }>
            <ListItemIcon><PushPinIcon/></ListItemIcon>
            <ListItemText>{ t('act.pin') }</ListItemText>
          </MenuItem>
        }


        {   isAuthor &&   isLockedAuthor && mayLock &&
          <MenuItem onClick={ () => { doUnlock.mutate(); handleClose(); } }>
            <ListItemIcon><LockOpenIcon /></ListItemIcon>
            <ListItemText>{ t('act.unlock') }</ListItemText>
          </MenuItem>
        }
        {   isAuthor && ! isLockedAuthor && mayLock &&
          <MenuItem onClick={ () => { doLock.mutate(); handleClose(); } }>
            <ListItemIcon><LockIcon/></ListItemIcon>
            <ListItemText>{ t('act.lock') }</ListItemText>
          </MenuItem>
        }

        { ! isAuthor &&   isLockedOther && mayLock &&
          <MenuItem onClick={ () => { doUnlock.mutate(); handleClose(); } }>
            <ListItemIcon><LockOpenIcon /></ListItemIcon>
            <ListItemText>{ t('act.unlock') }</ListItemText>
          </MenuItem>
        }
        { ! isAuthor && ! isLockedOther && mayLock &&
          <MenuItem onClick={ () => { doLock.mutate(); handleClose(); } }>
            <ListItemIcon><LockIcon/></ListItemIcon>
            <ListItemText>{ t('act.lock') }</ListItemText>
          </MenuItem>
        }


        {   isAuthor &&   isHiddenAuthor && mayHide &&
          <MenuItem onClick={ () => { doUnhide.mutate(); handleClose(); } }>
            <ListItemIcon><VisibilityIcon /></ListItemIcon>
            <ListItemText>{ t('act.unhide') }</ListItemText>
          </MenuItem>
        }
        {   isAuthor && ! isHiddenAuthor && mayHide &&
          <MenuItem onClick={ () => { doHide.mutate(); handleClose(); } }>
            <ListItemIcon><VisibilityOffIcon/></ListItemIcon>
            <ListItemText>{ t('act.hide') }</ListItemText>
          </MenuItem>
        }

        { ! isAuthor &&   isHiddenOther && mayHide &&
          <MenuItem onClick={ () => { doUnhide.mutate(); handleClose(); } }>
            <ListItemIcon><VisibilityIcon /></ListItemIcon>
            <ListItemText>{ t('act.unhide') }</ListItemText>
          </MenuItem>
        }
        { ! isAuthor && ! isHiddenOther && mayHide &&
          <MenuItem onClick={ () => { doHide.mutate(); handleClose(); } }>
            <ListItemIcon><VisibilityOffIcon/></ListItemIcon>
            <ListItemText>{ t('act.hide') }</ListItemText>
          </MenuItem>
        }


        {   isDeleted && mayDelete &&
          <MenuItem onClick={ () => { doUndelete.mutate(); handleClose(); } }>
            <ListItemIcon><RestoreFromTrashIcon /></ListItemIcon>
            <ListItemText>{ t('act.undelete') }</ListItemText>
          </MenuItem>
        }
        { ! isDeleted && mayDelete &&
          <MenuItem onClick={ () => { doDelete.mutate(); handleClose(); } }>
            <ListItemIcon><DeleteIcon/></ListItemIcon>
            <ListItemText>{ t('act.delete') }</ListItemText>
          </MenuItem>
        }
      </Menu>
      <ALPostDialogEdit post={ post } open={ showEdit } setOpen={ setShowEdit } onSuccess={ onSuccess } />
      <ALPostDialogHistory post={ post } open={ showHistory } setOpen={ setShowHistory } onSuccess={ onSuccess } />
    </div>
  )
}

//


const ChipPost = styled( Box ) ( ( {  color, theme } ) => ( {
  marginLeft : '0.25rem',
  borderRadius : '0.25rem',
  display : 'inline',
  padding : '0 0.25rem 0 0.25rem',
  color : theme.palette[ color ].contrastText,
  backgroundColor : theme.palette[ color ].light,
  whiteSpace : 'nowrap',
  fontWeight : 'normal',
} ) )

function ALPostAuthor( { post, author, menu = true } ) {
  const { t } = useTranslation()
  const locale = useLocaleCurrent()
  const langs = useRenderLanguages()

  const name = facetName( author, langs )
  const initials = facetInitials_2( author, langs )
  const pro = facetPronouns( author, langs )
  const img = useImage( facetAvatar( author ) )

  const history = postHistory( post )
  const edit_at = history ? history[ history.length - 1 ].at : 0

  const at = postCreatedAt( post )

  const timetonow = formatDistanceToNowStrict( new Date( at ), { locale: locale } )
  const timetonow_edit = formatDistanceToNowStrict( new Date( edit_at ), { locale: locale } )

  const pAuthz = postAuthz( post )
  const mayLock = checkCapabilities( pAuthz, capabilities.lock )
  const lockedAuthor = checkObject( pAuthz, object.locked_author )
  const lockedOther = checkObject( pAuthz, object.locked_other )
  const locked = lockedAuthor || lockedOther
  const hiddenAuthor = checkObject( pAuthz, object.hidden_author )
  const hiddenOther = checkObject( pAuthz, object.hidden_other )
  const deleted = checkObject( pAuthz, object.isDeleted )
  const pinned = checkObject( pAuthz, object.pinned )
  const invisible = checkObject( pAuthz, object.isInvisible ) && ! hiddenAuthor && ! hiddenOther && ! deleted
  const immutable = checkObject( pAuthz, object.isImmutable )&& ! hiddenAuthor && ! hiddenOther && ! deleted && ! lockedAuthor && ! lockedOther

  const cmty = useCommunity( postCommunityID( post ) )
  const cmty_roles = useCommunityRoles( cmty )
  const cmty_roles_author = communityUserRoles( cmty_roles, author, communityID( cmty ) )

  const group_id = postGroupID( post )
  const group = communityGroup( cmty, group_id )
  const grp_roles_author = communityUserRoles( cmty_roles, author, group_id )

  const groups_a = communityGroups( cmty )
  const isGroupSingle = groups_a.length === 1

  return(
    <Box sx={{ display: 'flex', flexWrap: 'nowrap', p: '0.5rem', pb: '0' }} >
      <Box sx={{ pr: '0.5rem' }}>
        <Avatar sx={{ height: '3rem', width: '3rem' }} src={ imageUrl( img ) } variant='rounded' >{ initials }</Avatar>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column'}}>
        <Box sx={{ typography: 'body2', lineHeight: 'normal', fontWeight: 'bold', display: 'flex', flexWrap: 'wrap', pb: '1px' }}>
          <Box>{ name } { pro ? `( ${ pro } )` : '' }</Box>
          { checkRoles( grp_roles_author,  roles.contributor ) && <ChipPost color='secondary'>{ t( `role.g_${ ROLE_CON }`, { count : 1 } ) }</ChipPost> }
          { checkRoles( grp_roles_author,  roles.leader      ) && <ChipPost color='secondary'>{ t( `role.g_${ ROLE_LEA }`, { count : 1 } ) }</ChipPost> }
          { checkRoles( grp_roles_author,  roles.admin       ) && <ChipPost color='secondary'>{ t( `role.g_${ ROLE_ADM }`, { count : 1 } ) }</ChipPost> }
          { checkRoles( grp_roles_author,  roles.group       ) && <ChipPost color='secondary'>{ t( `role.${ ROLE_GRP }`, { count : 1 } ) }</ChipPost> }
          { checkRoles( cmty_roles_author, roles.contributor ) && <ChipPost color='info'     >{ t( `role.${ ROLE_CON }`, { count : 1 } ) }</ChipPost> }
          { checkRoles( cmty_roles_author, roles.leader      ) && <ChipPost color='info'     >{ t( `role.${ ROLE_LEA }`, { count : 1 } ) }</ChipPost> }
          { checkRoles( cmty_roles_author, roles.admin       ) && <ChipPost color='info'     >{ t( `role.${ ROLE_ADM }`, { count : 1 } ) }</ChipPost> }
          { checkRoles( cmty_roles_author, roles.owner       ) && <ChipPost color='warning'  >{ t( `role.${ ROLE_OWN }`, { count : 1 } ) }</ChipPost> }
        </Box>
        <Box sx={{ typography: 'body2', lineHeight: 'normal', display: 'flex', flexWrap: 'wrap', pt : '1px' }}>
          <Box>{ history ? `${ t( 'act.edit' ) }: ${ timetonow_edit }` : timetonow }</Box>
          { pinned && <ChipPost color='info' >{t( 'chip.pinned' )}</ChipPost> }

          { ! mayLock && locked && <ChipPost color='warning' >{t( 'chip.locked' )}</ChipPost> }
          {   mayLock && lockedAuthor && <ChipPost color='warning' >{t( 'chip.locked_author' )}</ChipPost> }
          {   mayLock && lockedOther && <ChipPost color='warning' >{t( 'chip.locked_other' )}</ChipPost> }
          { hiddenAuthor && <ChipPost color='warning' >{t( 'chip.hidden_author' )}</ChipPost> }
          { hiddenOther && <ChipPost color='warning' >{t( 'chip.hidden_other' )}</ChipPost> }

          { deleted && <ChipPost color='error' >{t( 'chip.deleted' )}</ChipPost> }
          { invisible && <ChipPost color='warning' >{t( 'chip.invisible' )}</ChipPost> }
          { immutable && <ChipPost color='warning' >{t( 'chip.immutable' )}</ChipPost> }
        </Box>
        { ! isGroupSingle &&
          <Box sx={{ typography: 'body2', lineHeight: 'normal', display: 'flex', flexWrap: 'wrap', pt : '1px' }}>
            { communityGroupName( group ) }
          </Box>
        }
      </Box>
      { menu &&
        <Box ml='auto'>
          <ALPostMenu post={ post }/>
        </Box>
      }
    </Box>
  )
}

function ALPostContent( { post } ) {
  const imgs = postImages( post )

  return (
    <Box>
      <Box sx={{ p: '0.5rem' }} >
        <ALEditor value={ postBody( post ) } />
      </Box>
      <ALTile objs={ imgs } />
    </Box>
  )
}

function ALPostActions( { post } ) {
  return(
    <>
    <CardActions sx={{ p: '0' }} >
      <IconButton
        color='default'
        aria-label="Promote"
        disabled
      >
        <VolumeUpOutlinedIcon />
      </IconButton>

      <IconButton
        color='default'
        aria-label="Mute"
        disabled
      >
        <VolumeDownOutlinedIcon />
      </IconButton>

      <Divider orientation="vertical" flexItem sx={{ml: '0.5rem'}} />

      <ALRepliesActionAdd id={ postSID( post ) } create={ checkCapabilities( postAuthz( post ), capabilities.create ) } />

      <Divider orientation="vertical" flexItem />

      <IconButton
        color='default'
        aria-label="Bookmark"
        disabled
      >
        <BookmarkAddOutlinedIcon />
      </IconButton>

      <IconButton
        color='default'
        aria-label="Share"
        disabled
      >
        <IosShareIcon />
      </IconButton>

      <Box ml='auto' />
      <ALRepliesActionToggleShow id={ postSID( post ) } pids={ [] } count={ postStatsReplies( post ) } />
    </CardActions>
    </>
  )
}

function ALPost( { id }) {
  const { t } = useTranslation()
  const { cid: pcid } = useParams()
  const post = usePost( id )
  const group_id = postGroupID( post )
  const author = useFacet( postCreatedBy( post ) )

  if( rsIsLoading( post ) ) return <ALLoading />
  if( rsIsError( post ) ) return <Card sx={{ mt: '0.5rem', p: '0.5rem' }}>{ t('err.error') }</Card>

  if( ! checkCapabilities( postAuthz( post ), capabilities.read ) ) {
    const removed = checkObject( postAuthz( post ), object.isHidden | object.isDeleted | object.isInvisible )
    return (
      <Card sx={{ mt: '0.5rem', p : '0.5rem' }}>
        { removed ? t( 'err.error_nolongeravailable' ) : t( 'err.error_load' ) }
      </Card>
    )
  }

  return (
    <Card sx={{ mt: '0.5rem' }}>
      <Box>
        { ! pcid && (
          <>
            <ALPostCommunity post={ post } />
            <Divider />
          </>
        )}
        <ALPostAuthor post={ post } author={ author } />
        <ALPostContent post={ post } />
        <Divider />
        <ALPostActions post={ post } />
      </Box>
      <ALReplies oaid={ postCreatedBy( post ) } cid={ postCommunityID( post ) } sid={ postSID( post ) } group_id={ group_id } />
    </Card>
  )
}

// -----

function ALPostsMenuSort() {
  const { t } = useTranslation()
  const postsRefresh = usePostsRefresh()
  const [ anchorEl, setAnchorEl ] = useState( false )
  const open = Boolean( anchorEl )
  const sort = usePostsSortValue()
  const sortSet = usePostsSortSet()

  // Cleanup of sort handled by caller

  function handleClick( e ) {
    setAnchorEl( e.currentTarget )
  }

  function handleClose() {
    setAnchorEl( null )
  }

  function onClick( val ) {
    if( val !== sort ) {
      sortSet( val )
      postsRefresh()
    }
    handleClose()
  }

  return (
    <Box sx={{ display: 'inline' }}>
      <IconButton
        id="sort-button"
        aria-controls="sort menu"
        aria-haspopup="true"
        aria-expanded={ open ? 'true' : undefined }
        onClick={ handleClick }
        sx={{ p: '0', pr: '0.5rem' }}
      >
        <SortIcon />
      </IconButton>
      <Menu
        id="sort-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
        MenuListProps={{
          'aria-labelledby':'sort-button',
        }}
      >
        <MenuItem onClick={ () =>{ onClick( 'trending' ) } } selected={ sort === 'trending' } disabled>
          <ListItemText>{ t('act.sort.trending') }</ListItemText>
        </MenuItem>
        <MenuItem onClick={ () =>{ onClick( 'newest' ) } } selected={ sort === 'newest' }>
          <ListItemText>{ t('act.sort.newest') }</ListItemText>
        </MenuItem>
        <MenuItem onClick={ () =>{ onClick( 'oldest' ) } } selected={ sort === 'oldest' }>
          <ListItemText>{ t('act.sort.oldest') }</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  )
}

// -----

function ALPosts( { cmty, group_id } ) {
  const { t } = useTranslation()
  const postsRefresh = usePostsRefresh()
  const cid = communityID( cmty )
  const sort = usePostsSortValue()
  const idsAdded = usePostsAdded()
  const idsPinned = postsIDs( usePosts( { community_id : cid, group_id, sort, pinned : true } ) )
  const posts = usePosts( { community_id: cid, group_id, sort } )
  const ids = difference( postsIDs( posts ), idsAdded )
  const more = postsHasMore( posts )
  const create = Boolean( cmty ) && Boolean( communityGroups( cmty, capabilities.create ).length )

  // Cleanup on unmount
  useEffect( () => {
    return () => {
      postsSortDelete()
    }
  }, [] )

  useEffect( () => {
    return () => {
      if( idsAdded?.length > 0 ) {
        postsRefresh()
        postsAddedDelete()
      }
    }
  }, [ idsAdded, postsRefresh ] )

  if( ! idsAdded.length && ! ids.length ) return <>{ create && <Card ><ALPostNew cmty={ cmty } group_id={ group_id } /> </Card> }<Card sx={{ mt: '0.5rem', p: '0.5rem' }}>{ t( 'mod.posts.empty' ) }</Card></>

  return (
    <Box>
      { create && <Card ><ALPostNew cmty={ cmty } group_id={ group_id } /> </Card> }
      <Card sx={{ p: '0.375rem', mt: create ? '0.5rem' : '0' }}>
        <Box display='flex' justifyContent='flex-end'>
          <ALPostsMenuSort />
        </Box>
      </Card>
      { idsPinned.map( id => ( <ALPost id={ id } key={ id } /> ) ) }
      { idsAdded.map( id => ( <ALPost id={ id } key={ id } /> ) ) }
      { ids.map( id => ( <ALPost id={ id } key={ id } /> ) ) }
      { ( rsIsLoading( posts ) || rsiIsFetchingNextPage( posts ) ) && <ALLoading /> }
      { rsIsError( posts ) && <Card sx={{ mt: '0.5rem', p: '0.5rem' }}>{ t('err.error') }</Card> }
      { more && <Button variant="text" onClick={ () => { rsiFetchNextPage( posts ) } } >{ t('act.load_more' ) }</Button> }
    </Box>
  )
}

// -----

function ALPostNew( { cmty, group_id } ) {
  const { t } = useTranslation()

  const cmty_id = communityID( cmty )

  const groups_a = communityGroups( cmty )
  const isGroupSingle = groups_a.length === 1

  const groups_c = communityGroups( cmty, capabilities.create )

  const [ show, setShow ] = useState( false )
  const [ inpGID, setInpGID ] = useState( group_id )
  const [ selGID, setSelGID ] = useState( isGroupSingle ? communityGroupID( groups_c[ 0 ] ) : group_id )

  const group = communityGroup( cmty, selGID )

  const lang_cur = useLangCurrent()
  const langs_group = communityGroupLangs( group )
  const langs = useLangs( langs_group ).map( lang => { return { value: lang.code, label: lang.nativeName } } )
  const lang = selGID ? ( langs_group.includes( lang_cur.code ) ? lang_cur.code : '' ) : ''
  // TODO #309
  // TODO how to handle text written in a language supported by 1 group, when changing to another group that doesn't have that language.

  const lDisabled = selGID === cmty_id

  const fieldRequiredOneOf = useFieldRequiredOneOf( [ 'body', 'images' ] )
  const fieldMax1024 = useFieldStringMax( 1024 )


  const addNew = usePostsAddedAdd ()
  const onSuccess = ( rec_new ) => { addNew( rec_new.id ); setShow( false ) }

  const fileLimitsQ = useFileLimits( { role : 'content' } )
  const fileLimits = fileLimitsList( fileLimitsQ )

  function onChange( e, next ) {
    setSelGID( e.target.value )
    next( e )
  }

  // Reset form by closing if group_id changes
  useEffect( () => {
    if( group_id !== inpGID ) {
      setShow( false )
      setInpGID( group_id )
      setSelGID( group_id )
    }

  }, [ group_id, inpGID, setShow, setInpGID, setSelGID ] )

  const formElements = [
    { Comp: ALFormHidden, id: 'community_id', value: communityID( cmty ) },

    { Comp: ALFormTextField, id: 'language', name : t( 'rec.posts.lang' ), check: [ fieldRequired ], value: lang, select: true, options : langs, local: true, disabled: lDisabled },

    { Comp: ALFormTextField, id: 'body', sub: 'language', name: t( 'rec.posts.body' ), type: 'text', minRows: 2, maxRows: 10, autoFocus: true, check: [ fieldRequiredOneOf, fieldMax1024 ] },
    { Comp: ALFormMedia, id: 'images', role: 'content', fileLimits, langs, lang, value: [], check: [ fieldRequiredOneOf ] },

    ...( ! isGroupSingle ? [ { Comp: ALFormTextField, id: 'group_id',  name : t( 'rec.posts.group', { count: 2 } ), check: [ fieldRequired ], value: selGID, select: true, onChange, options : [ { value: '', label: t( 'rec.posts.group_sel' ), disabled : true }, ...groups_c.map( group => { return { value: communityGroupID( group ), label: communityGroupName( group, [ lang_cur ] )} } ) ] } ] : [] ),
    ...(   isGroupSingle ? [ { Comp: ALFormHidden, id: 'group_id', value: selGID } ] : [] ),
  ]

  if( ! group ) return <></>

  if( rsIsLoading( fileLimitsQ ) ) return <ALLoading />

  if( show ) {
    return (
      <ALForm
        elements={ formElements }
        service='api/posts'
        method='create'
        onSuccess={ onSuccess }
        submitName={ t( 'act.post') }
        buttonSecondary={ <Button onClick={ () => { setShow( false ) } } >{ t( 'act.cancel' ) }</Button> }
      />
    )
  }
  else {
    return (
      <Box sx={{ p: '0.5rem 1rem 0.5rem 1rem' }} onClick={ () => setShow( true ) } >
        <TextField
          sx={{ p: '0', m: '0' }}
          type='text'
          fullWidth
          margin='dense'
          size='small'
          variant='outlined'
          id='body'
          name={ t( 'rec.posts.start' ) }
          label={ t( 'rec.posts.start' ) }
          autoFocus={ false }
        />
      </Box>
    )
  }

}

// -----

function ALPostDialogEdit( { post, open, setOpen, onSuccess } ) {
  const { t } = useTranslation()
  const mobile = useMobile()

  const cmty = useCommunity( postCommunityID( post ) )
  const group = communityGroup( cmty, postGroupID( post ) )

  const author = useFacet( postCreatedBy( post ) )

  const onClose = () => setOpen( false )

  const lang_cur = useLangCurrent()
  const langs_group = communityGroupLangs( group )
  const langs = useLangs( langs_group ).map( lang => { return { value: lang.code, label: lang.nativeName } } )
  const lang = langs_group.includes( lang_cur.code ) ? lang_cur.code : langs_group[ 0 ].code
  const lDisabled = langs_group.length <= 1

  const fileLimitsQ = useFileLimits( { role : 'content' } )
  const fileLimits = fileLimitsList( fileLimitsQ )

  const fieldRequiredOneOf = useFieldRequiredOneOf( [ 'body', 'images' ] )
  const fieldMax1024 = useFieldStringMax( 1024 )

  const bodies = postBodies( post )
  const images = postImages( post )

  const formElements = [
    { Comp: ALFormHidden, id: 'op', value: 'edit' },

    { Comp: ALFormTextField, id: 'language', name : t( 'rec.posts.lang' ), check: [ fieldRequired ], value: lang, select: true, options : langs, local: true, disabled : lDisabled },

    { Comp: ALFormTextField, id: 'body', sub: 'language', value : bodies, name: t( 'rec.posts.body' ), type: 'text', minRows: 2, maxRows: 10, check: [ fieldChangedOne, fieldRequiredOneOf, fieldMax1024 ] },
    { Comp: ALFormMedia, id: 'images', role: 'content', fileLimits, langs, lang, value: images, check: [ fieldChangedOne, fieldRequiredOneOf ] },
  ]

  return (
    <Dialog onClose={ onClose } open={ open } fullScreen={ mobile } fullWidth sx={{ '& .MuiDialog-paper' : { margin: 0, maxWidth: '40rem', width: 'min( 40rem, 100% )', maxHeight: '100%' } }} >
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center' >
          <Box>{ t( 'mod.posts.act.edit' ) }</Box>
          <IconButton aria-label='close' onClick={ onClose } sx={{ p: '0.25rem' }} ><CloseIcon /></IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers sx={{ px: '0' }}>
        <ALPostAuthor post={ post } author={ author } menu={ false } />
        <ALForm
          elements={ formElements }
          service='api/posts'
          method='patch'
          oid={ postID( post ) }
          onSuccess={ onSuccess }
          submitName={ t( 'act.update') }
          buttonSecondary={ <Button onClick={ onClose } >{ t( 'act.cancel' ) }</Button> }
          allowRepeat
        />
      </DialogContent>
    </Dialog>
  )
}

// -----

function ALPostDialogHistory( { post, open, setOpen, onSuccess } ) {
  const { t } = useTranslation()
  const mobile = useMobile()
  const locale = useLocaleCurrent()

  const history = postHistory( post )

  const onClose = () => setOpen( false )

  return (
    <Dialog onClose={ onClose } open={ open } fullScreen={ mobile } fullWidth sx={{ '& .MuiDialog-paper' : { margin: 0, maxWidth: '40rem', width: 'min( 40rem, 100% )', maxHeight: '100%' } }} >
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center' >
          <Box>{ t( 'mod.posts.act.history' ) }</Box>
          <IconButton aria-label='close' onClick={ onClose } sx={{ p: '0.25rem' }} ><CloseIcon /></IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers sx={{ px: '0' }}>
        <List>
          { [ ...( history ?? [] ) ].reverse().map( elem => {
            const time = format( new Date( elem.at ), 'PPPPp', { locale: locale } )
            return (
            <ListItem key={ elem.at }>{ time }</ListItem>
            )
          } ) }
        </List>
      </DialogContent>
    </Dialog>
  )
}

//

export {
  ALPosts,
  urlPost,
}
