//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  widget : {
    margin: '0.5rem 0rem 0.5rem 0rem',

    [ theme.breakpoints.up('md') ] : {
      margin: '1rem',
    },
  },

  side : {
    minWidth: `min(100vw, 40rem)`,
    maxWidth: `min(100vw, 40rem)`,

    [ theme.breakpoints.up('md') ] : {
      minWidth: '20rem',
      maxWidth: '20rem',
    },
  },

  main : {
    minWidth: `min(100vw, 40rem)`,
    maxWidth: `min(100vw, 40rem)`,

    [ theme.breakpoints.up('md') ] : {
      minWidth : `min(40rem, (100vw - 20rem) - 3rem )`,
      maxWidth : `min(40rem, (100vw - 20rem) - 3rem )`,
      marginLeft: 0,
    },
  },

  title : {
    minHeight: 0,
    marginBottom: 0,
  },
}));

export default function ALWidget ( { side, main, nocard, title, ...props }) {
  const classes = useStyles()

  let style = ''
  if( side ) style = classes.side
  else if ( main ) style = classes.main
  else if ( title ) style = clsx(classes.main, classes.title)

  if( nocard ) return (<Box className={ `${ classes.widget } ${ style }` } >{ props.children }</Box>)

  return (
    <Card className={ `${ classes.widget } ${ style }` } >
      { props.children }
    </Card>
  )
}