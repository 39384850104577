//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useState
} from 'react'

import makeStyles from '@mui/styles/makeStyles';

import {
  useNarrow,
  useMobile,
} from './ALScreenSizing'

import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import { Button } from '@mui/material'

import ALForm from './form/ALForm'
import {
  ALFormTextField,
} from './form/ALForm'

import { useTranslation } from 'react-i18next'

//

export const useStyles = makeStyles( (theme) => ({
  item : props => ({
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: props.mobile ? '0.5rem' : '',
    paddingRight: props.mobile ? '0.5rem' : '',
  }),
  item_label: {
    minWidth: '10rem',
    maxWidth: '15rem',
  },
  item_value: {
    textAlign: 'left',
    width: '100%',
  },
  item_edit: {
    minWidth: '4rem',
    maxWidth: '4rem',
    textAlign: 'right',
    marginLeft: 'auto',
  },
  item_sel : {
    backgroundColor: theme.palette.action.selected,
  },
}))

//

function ALSettingsItem( { id, stateSel, setStateSel, label, value, placeholder, action, valueonly, form } ) {
  const { t } = useTranslation()
  const mobile = useNarrow()
  const classes = useStyles( {mobile} )

  if( mobile ) {
    if( valueonly ) {
      return (
        <ListItem className={ `${ classes.item } `} sx={{ flexDirection: 'column' }} >
          <ListItemText className={ classes.item_label } primary={label} primaryTypographyProps={{ variant: 'button' }} />
          {   value && <ListItemText className={ classes.item_value } primary={ value } /> }
          { ! value && <ListItemText className={ classes.item_value } primary={ placeholder } primaryTypographyProps={{ fontStyle: 'italic', color: 'text.disabled' }} /> }
        </ListItem>
      )
    }
    else if( id === stateSel ) {
      return (
        <ListItem className={ `${ classes.item } ${ classes.item_sel }` } sx={{ flexDirection: 'column' }} >
          <ListItemText className={ classes.item_label } primary={ label } primaryTypographyProps={{ variant: 'button' }} />
          <ListItemText className={ classes.item_value } primary={ form } />
        </ListItem>
      )
    }
    else {
      return (
        <ListItem button className={ `${ classes.item } `} onClick={ () => { setStateSel( id ) } } sx={{ flexDirection: 'column' }} >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <ListItemText className={ classes.item_label } primary={ label } primaryTypographyProps={{ variant: 'button' }} />
            <ListItemText className={ classes.item_edit } primary={ t(`act.${ action ?? 'edit' }`) } primaryTypographyProps={{ variant: 'button' }} />
          </Box>
          {   value && <ListItemText className={ classes.item_value } primary={ value } /> }
          { ! value && <ListItemText className={ classes.item_value } primary={ placeholder } primaryTypographyProps={{ fontStyle: 'italic', color: 'text.disabled' }} /> }
        </ListItem>
      )
    }
  }
  else {
    if( valueonly ) {
      return (
        <ListItem className={ `${ classes.item } `} >
          { label && <ListItemText className={ classes.item_label} primary={ label } primaryTypographyProps={{ variant: 'button' }} /> }
          {   value && <ListItemText className={ classes.item_value} primary={ value } /> }
          { ! value && <ListItemText className={ classes.item_value} primary={ placeholder } primaryTypographyProps={{ fontStyle: 'italic', color: 'text.disabled' }} /> }
        </ListItem>
      )
    }
    else if( id === stateSel ) {
      return (
        <ListItem className={ `${ classes.item } ${ classes.item_sel }` }>
          <ListItemText className={ classes.item_label } primary={ label } primaryTypographyProps={{ variant: 'button' }} />
          <ListItemText className={ classes.item_value } primary={ form } />
        </ListItem>
      )
    }
    else {
      return (
        <ListItem button className={ `${ classes.item } `} onClick={ () => { setStateSel(id) } } >
          <ListItemText className={ classes.item_label } primary={label} primaryTypographyProps={{ variant: 'button' }} />
          { value && <ListItemText className={ classes.item_value } primary={ value } /> }
          { ! value && <ListItemText className={ classes.item_value } primary={ placeholder } primaryTypographyProps={{ fontStyle: 'italic', color: 'text.disabled' }} /> }
          <ListItemText className={ classes.item_edit } primary={ t( `act.${ action ?? 'edit' }` ) } primaryTypographyProps={{ variant: 'button' }} />
        </ListItem>
      )
    }
  }
}

function ALSettingsSelect( { id, label, value, update, options } ) {
  const mobile = useMobile()
  const classes = useStyles( {mobile} )

  return (
    <ListItem className={ `${ classes.item }` }>
      <ListItemText className={ classes.item_label} primary={label} primaryTypographyProps={ {variant: 'button'} } />
      <ListItemText className={ classes.item_value} primary={
         <ALFormTextField
           select={ true }
           id={ id }
           state={ { values: { [id] : value }, errors: { [id] : '' } } }
           onChange={ (e) => { update( e.target.value ) } }
           options={ options }
           />
        }
      />
    </ListItem>
  )
}

function ALSettings( { items } ) {
  const [ sel, setSel ] = useState ('')
  const { t } = useTranslation()

  const buttonCancel = (
    <Button
      variant='outlined'
      onClick={ () => { setSel('') } }
    >
      { t( 'act.cancel' ) }
    </Button>
  )

  return (
    <List>
      { items.map( (item, idx ) =>
        {
          if( item.divider ) return <Divider key={idx} />
          else if ( item.options ) return (
            <ALSettingsSelect key={idx} id={ item.id } label={ item.label } value={ item.value } update={ item.update } options={ item.options } />
          )
          else if ( item.elems ) return (
            <ALSettingsItem key={idx} id={ idx } stateSel={ sel } setStateSel={ setSel } label={ item.label } value={ item.value } placeholder={ item.placeholder } action={ item.action } valueonly={ item.valueonly } form={<ALForm
              margin={item.margin}
              elements={ item.elems }
              values={ item.hidden || {} }
              service={ item.service }
              method='patch'
              oid={ item.oid }
              op={ item.op }
              allowRepeat={ true }
              onSuccess={ item.onSuccess}
              submitName={ t('act.save') }
              buttonSecondary={ buttonCancel }/>} />
          )
          else return <></>
        }
      ) }
    </List>
  )
}

export {
  ALSettings,
  ALSettingsItem,
}