//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useMobile,
} from './ALScreenSizing'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import {
  grey,
} from '@mui/material/colors';

import {
  useImage,
  imageUrl,
} from '../remote-state/images'

import {
  useColorModeID,
 } from './ALColorMode'

//

function ALPageBox( { color, bgImage, children } ) {
  const selColor = color ?? 'default'
  const mode = useColorModeID()
  const colorMap = {
    'default' : {
      backgroundColor : mode === 'light' ? grey[100] : grey[800],
      backgroundImage : mode === 'light' ? 'bg-gray-light-svg' : 'bg-gray-dark-svg' }
  }
  const bgImageUrl = imageUrl( useImage( bgImage ?? colorMap[ selColor ].backgroundImage ) )

  const sx = {
    backgroundColor: colorMap[ selColor ].backgroundColor,
    backgroundImage: `url( ${ bgImageUrl } )`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight : '100vh',
    minWidth: '100vw',
  }

  return <Box sx={ sx } >{ children } </Box>
}

function ALPageSpacerToolbar ( ) {
  const mobile = useMobile()

  const sx = {
    minHeight: mobile ? '56px' : '56px',
    maxHeight: mobile ? '56px' : '56px',
  }

  return <Box sx={ sx } />
}

function ALContainer( { justifyContent, alignItems, children } ) {
  const mobile = useMobile()

  const sx = {
    maxWidth: 'lg',
    minHeight: `calc(100vh - ${ mobile ? '112px' : '56px' })`,
    display: 'flex',
    flexWrap: mobile ? 'wrap' : 'nowrap',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'center',
  }

  return <Container sx={ sx } >{ children } </Container>
}

export default function ALPage( { color, bgImage, justifyContent, alignItems, children } ) {
  return (
    <ALPageBox color={ color } bgImage={ bgImage } >
      <ALPageSpacerToolbar />
      <ALContainer justifyContent={ justifyContent } alignItems={ alignItems } >
        { children }
      </ALContainer>
    </ALPageBox>
  )
}

export {
  ALPageSpacerToolbar,
}