//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  useStoreValue,
  useSetStoreValue,
  store,
} from 'react-context-hook'

import { useTranslation } from 'react-i18next'

import {
  format,
  formatDistanceToNowStrict,
} from 'date-fns'

import { difference } from 'lodash'


import {
  Avatar,

  Box,
  Divider,

  Button,
  IconButton,

  CardActions,

  Dialog,
  DialogContent,
  DialogTitle,

  List,
  ListItem,
  ListItemIcon,
  ListItemText,

  Menu,
  MenuItem,

  styled,
} from '@mui/material'

import {
  BookmarkAddOutlined as BookmarkAddOutlinedIcon,
  IosShare as IosShareIcon,
  VolumeUpOutlined as VolumeUpOutlinedIcon,
  VolumeDownOutlined as VolumeDownOutlinedIcon,
  MoreVert as MoreVertIcon,

  Close as CloseIcon,
  History as HistoryIcon,
  Flag as FlagIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  Sort as SortIcon,
  Report as ReportIcon,
  AddComment as AddCommentIcon,
  AddCommentOutlined as AddCommentOutlinedIcon,
} from '@mui/icons-material'

import {
  grey,
} from '@mui/material/colors';

import {
  useLangCurrent,
  useLocaleCurrent,
  useLangs,
  useRenderLanguages,
} from './ALI18N'

import {
  rsIsLoading,
  rsIsError,
  rsError,

  rsiIsFetchingNextPage,
  rsiFetchNextPage,
} from '../remote-state/core'

import {
  capabilities,
  object,
  roles,
  checkCapabilities,
  checkObject,
  checkRoles,
} from '../remote-state/authorization'

import {
  useFileLimits,
  fileLimitsList,
} from '../remote-state/files'

import { useMobile } from './ALScreenSizing'

import {
  useReply,
  useRepliesReload,

  useReplyReport,
  useReplyDelete,
  useReplyUndelete,
  useReplyLock,
  useReplyUnlock,
  useReplyHide,
  useReplyUnhide,

  replyID,
  replySID,

  replyCommunityID,

  replyCreatedAt,
  replyCreatedBy,

  replyHistory,

  replyAuthz,

  replyBody,
  replyBodies,
  replyImages,

  replyStatsReplies,

  replyParents,

  useRepliesTo,

  useRepliesRefreshAll,
  repliesIDs,
  repliesHasMore,
} from '../remote-state/replies'

import {
  useCommunity,
  communityID,

  communityGroup,
  communityGroupLangs,

  useCommunityRoles,
  communityUserRoles,

  ROLE_GRP,
  ROLE_CON,
  ROLE_LEA,
  ROLE_ADM,
  ROLE_OWN,
} from '../remote-state/communities'

import {
  useImage,
  imageUrl,
} from '../remote-state/images'

import {
  useFacet,
  facetName,
  facetInitials_2,
  facetPronouns,
  facetAvatar,
} from '../remote-state/facets'

import {
  useColorModeID,
 } from './ALColorMode'

import ALForm, {
  fieldRequired,
  fieldChangedOne,
  useFieldRequiredOneOf,
  useFieldStringMax,
  ALFormTextField,
  ALFormMedia,
  ALFormHidden,
} from './form/ALForm'

import {
  ALEditor,
} from './ALDraftJS'

import {
  ALTile,
} from './ALTile'

import ALLoading from './ALLoading'

//

function useRepliesAdded( id ) {
  const idsAdded = useStoreValue( [ 'reply', id, 'added replies' ], [] )
  return idsAdded
}

function useRepliesAddedAdd( id ) {
  const set = useSetStoreValue( [ 'reply', id, 'added replies' ] )
  const prev = useRepliesAdded( id )

  return useCallback( new_id => { set( [ new_id, ...prev ] ) }, [ set, prev ] )
}

function repliesAddedDelete( id ) { store.delete( [ 'reply', id, 'added replies' ] ) }

// -----

function useRepliesShow( id ) {
  return useStoreValue( [ 'reply', id, 'show replies' ], false )
}

function useRepliesShowSet( id ) {
  return useSetStoreValue( [ 'reply', id, 'show replies' ] )
}

function repliesShowDelete( id ) { store.delete( [ 'reply', id, 'show replies' ] ) }

// -----

function useReplyShowAddValue( id ) {
  return useStoreValue( [ 'reply', id, 'show add' ], false )
}

function useReplyShowAddSet( id ) {
  return useSetStoreValue( [ 'reply', id, 'show add' ] )
}

function replyShowAddDelete( id ) { store.delete( [ 'reply', id, 'show add' ] ) }

// -----

function useReplySortValue( id ) {
  return useStoreValue( [ 'reply', id, 'sort' ], 'newest' )
}

function useReplySortSet( id ) {
  return useSetStoreValue( [ 'reply', id, 'sort' ] )
}

function replySortDelete( id ) { store.delete( [ 'reply', id, 'sort' ] ) }

//

function ALReplyAvatar( { oaid, reply, author } ) {
  const langs = useRenderLanguages()

  const initials = facetInitials_2( author, langs )
  const img = useImage( facetAvatar( author ) )

  return(
    <Avatar sx={{ height: '2rem', width: '2rem', mr : '0.25rem' }} src={ imageUrl( img ) } variant='rounded' >{ initials }</Avatar>
  )
}

function ALReplyAvatarError() {
  return(
    <Box sx={{ pr: '0.5rem', pt: '0.5rem' }}>
      <Avatar sx={{ height: '2rem', width: '2rem' }} ><ReportIcon /></Avatar>
    </Box>
  )
}

// -----

function ALReplyMenu( { reply, group_id, oaid } ) {
  const { t } = useTranslation()
  const [ anchorEl, setAnchorEl ] = useState( false )
  const open = Boolean( anchorEl )

  const doReload = useRepliesReload( replyParents( reply ), replyID( reply ) )

  function onSuccess() {
    doReload( reply )
  }

  const [ showEdit, setShowEdit ] = useState( false )
  const [ showHistory, setShowHistory ] = useState( false )

  const doReport = useReplyReport( reply, onSuccess )
  const doLock = useReplyLock( reply, onSuccess )
  const doUnlock = useReplyUnlock( reply, onSuccess )
  const doHide = useReplyHide( reply, onSuccess )
  const doUnhide = useReplyUnhide( reply, onSuccess )
  const doDelete = useReplyDelete( reply, onSuccess )
  const doUndelete = useReplyUndelete( reply, onSuccess )

  const rAuthz = replyAuthz( reply )

  const isAuthor = checkRoles( rAuthz, roles.author )

  const mayEdit = checkCapabilities( rAuthz, capabilities.edit )

  const isLockedAuthor = checkObject( rAuthz, object.locked_author )
  const isLockedOther = checkObject( rAuthz, object.locked_other )
  const mayLock = checkCapabilities( rAuthz, capabilities.lock )

  const isHiddenAuthor = checkObject( rAuthz, object.hidden_author )
  const isHiddenOther = checkObject( rAuthz, object.hidden_other )
  const mayHide = checkCapabilities( rAuthz, capabilities.hide )

  const isDeleted = checkObject( rAuthz, object.isDeleted )
  const mayDelete = checkCapabilities( rAuthz, capabilities.delete )

  const history = replyHistory( reply )

  function handleClick( e ) {
    setAnchorEl( e.currentTarget )
  }

  function handleClose() {
    setAnchorEl( null )
  }

  return (
    <div>
      <IconButton
        id="delete-button"
        aria-controls="delete menu"
        aria-haspopup="true"
        aria-expanded={ open ? 'true' : undefined }
        onClick={ handleClick }
        sx={{ p: '0'}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="delete-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
        MenuListProps={{
          'aria-labelledby':'delete-button',
        }}
      >
        {   mayEdit &&
          <MenuItem onClick={ () => { setShowEdit( true ); handleClose(); } }>
            <ListItemIcon><EditIcon /></ListItemIcon>
            <ListItemText>{ t('act.edit') }</ListItemText>
          </MenuItem>
        }
        {   history &&
          <MenuItem onClick={ () => { setShowHistory( true ); handleClose(); } }>
            <ListItemIcon><HistoryIcon /></ListItemIcon>
            <ListItemText>{ t('act.history') }</ListItemText>
          </MenuItem>
        }

        { ! isAuthor &&
          <MenuItem onClick={ () => { doReport.mutate(); handleClose(); } } disabled>
            <ListItemIcon><FlagIcon /></ListItemIcon>
            <ListItemText>{ t('act.report') }</ListItemText>
          </MenuItem>
        }


        {   isAuthor &&   isLockedAuthor && mayLock &&
          <MenuItem onClick={ () => { doUnlock.mutate(); handleClose(); } }>
            <ListItemIcon><LockOpenIcon /></ListItemIcon>
            <ListItemText>{ t('act.unlock') }</ListItemText>
          </MenuItem>
        }
        {   isAuthor && ! isLockedAuthor && mayLock &&
          <MenuItem onClick={ () => { doLock.mutate(); handleClose(); } }>
            <ListItemIcon><LockIcon/></ListItemIcon>
            <ListItemText>{ t('act.lock') }</ListItemText>
          </MenuItem>
        }

        { ! isAuthor &&   isLockedOther && mayLock &&
          <MenuItem onClick={ () => { doUnlock.mutate(); handleClose(); } }>
            <ListItemIcon><LockOpenIcon /></ListItemIcon>
            <ListItemText>{ t('act.unlock') }</ListItemText>
          </MenuItem>
        }
        { ! isAuthor && ! isLockedOther && mayLock &&
          <MenuItem onClick={ () => { doLock.mutate(); handleClose(); } }>
            <ListItemIcon><LockIcon/></ListItemIcon>
            <ListItemText>{ t('act.lock') }</ListItemText>
          </MenuItem>
        }


        {   isAuthor &&   isHiddenAuthor && mayHide &&
          <MenuItem onClick={ () => { doUnhide.mutate(); handleClose(); } }>
            <ListItemIcon><VisibilityIcon /></ListItemIcon>
            <ListItemText>{ t('act.unhide') }</ListItemText>
          </MenuItem>
        }
        {   isAuthor && ! isHiddenAuthor && mayHide &&
          <MenuItem onClick={ () => { doHide.mutate(); handleClose(); } }>
            <ListItemIcon><VisibilityOffIcon/></ListItemIcon>
            <ListItemText>{ t('act.hide') }</ListItemText>
          </MenuItem>
        }

        { ! isAuthor &&   isHiddenOther && mayHide &&
          <MenuItem onClick={ () => { doUnhide.mutate(); handleClose(); } }>
            <ListItemIcon><VisibilityIcon /></ListItemIcon>
            <ListItemText>{ t('act.unhide') }</ListItemText>
          </MenuItem>
        }
        { ! isAuthor && ! isHiddenOther && mayHide &&
          <MenuItem onClick={ () => { doHide.mutate(); handleClose(); } }>
            <ListItemIcon><VisibilityOffIcon/></ListItemIcon>
            <ListItemText>{ t('act.hide') }</ListItemText>
          </MenuItem>
        }


        {   isDeleted && mayDelete &&
          <MenuItem onClick={ () => { doUndelete.mutate(); handleClose(); } }>
            <ListItemIcon><RestoreFromTrashIcon /></ListItemIcon>
            <ListItemText>{ t('act.undelete') }</ListItemText>
          </MenuItem>
        }
        { ! isDeleted && mayDelete &&
          <MenuItem onClick={ () => { doDelete.mutate(); handleClose(); } }>
            <ListItemIcon><DeleteIcon/></ListItemIcon>
            <ListItemText>{ t('act.delete') }</ListItemText>
          </MenuItem>
        }
      </Menu>
      <ALReplyDialogEdit reply={ reply } group_id={ group_id } oaid={ oaid } open={ showEdit } setOpen={ setShowEdit } onSuccess={ onSuccess } />
      <ALReplyDialogHistory reply={ reply } open={ showHistory } setOpen={ setShowHistory } onSuccess={ onSuccess } />
    </div>
  )
}

// -----

const ChipReply = styled( Box ) ( ( {  color, theme } ) => ( {
  marginLeft : '0.25rem',
  borderRadius : '0.25rem',
  display : 'inline',
  padding : '0 0.25rem 0 0.25rem',
  color : theme.palette[ color ].contrastText,
  backgroundColor : theme.palette[ color ].light,
  whiteSpace : 'nowrap',
  fontWeight : 'normal',
} ) )

function ALReplyAuthor( { oaid, group_id, reply, author, menu = true } ) {
  const { t } = useTranslation()
  const locale = useLocaleCurrent()
  const langs = useRenderLanguages()

  const history = replyHistory( reply )
  const edit_at = history ? history[ history.length - 1 ].at : 0

  const at = replyCreatedAt( reply )
  const by = replyCreatedBy( reply )
  const originalAuthor = oaid === by

  const name = facetName( author, langs )
  const pro = facetPronouns( author, langs )

  const timetonow = formatDistanceToNowStrict( new Date( at ), { locale: locale } )
  const timetonow_edit = formatDistanceToNowStrict( new Date( edit_at ), { locale: locale } )

  const rAuthz = replyAuthz( reply )
  const mayLock = checkCapabilities( rAuthz, capabilities.lock )
  const lockedAuthor = checkObject( rAuthz, object.locked_author )
  const lockedOther = checkObject( rAuthz, object.locked_other )
  const locked = lockedAuthor || lockedOther
  const hiddenAuthor = checkObject( rAuthz, object.hidden_author )
  const hiddenOther = checkObject( rAuthz, object.hidden_other )
  const deleted = checkObject( rAuthz, object.isDeleted )
  const invisible = checkObject( rAuthz, object.isInvisible ) && ! hiddenAuthor && ! hiddenOther && ! deleted
  const immutable = checkObject( rAuthz, object.isImmutable ) && ! hiddenAuthor && ! hiddenOther && ! deleted && ! lockedAuthor && ! lockedOther

  const cmty = useCommunity( replyCommunityID( reply ) )
  const cmty_roles = useCommunityRoles( cmty )
  const cmty_roles_author = communityUserRoles( cmty_roles, author, communityID( cmty ) )

  const grp_roles_author = communityUserRoles( cmty_roles, author, group_id )

  return(
    <Box sx={{ display: 'flex', flexWrap: 'nowrap', width:'100%' }} >
      <ALReplyAvatar oaid={ oaid } reply={ reply } author={ author } />
      <Box sx={{ px: '0.5rem', display: 'flex', flexDirection: 'column'}}>
        <Box sx={{ typography: 'body2', lineHeight: 'normal', fontWeight: 'bold', display: 'flex', flexWrap: 'wrap', pb : '1px' }}>
          <Box>{ name } { pro ? `( ${ pro } )` : '' }</Box>
          { originalAuthor && <ChipReply color='success' >{t( 'chip.author' )}</ChipReply> }
          { checkRoles( grp_roles_author,  roles.contributor ) && <ChipReply color='secondary'>{ t( `role.g_${ ROLE_CON }`, { count : 1 } ) }</ChipReply> }
          { checkRoles( grp_roles_author,  roles.leader      ) && <ChipReply color='secondary'>{ t( `role.g_${ ROLE_LEA }`, { count : 1 } ) }</ChipReply> }
          { checkRoles( grp_roles_author,  roles.admin       ) && <ChipReply color='secondary'>{ t( `role.g_${ ROLE_ADM }`, { count : 1 } ) }</ChipReply> }
          { checkRoles( grp_roles_author,  roles.group       ) && <ChipReply color='secondary'>{ t( `role.${ ROLE_GRP }`, { count : 1 } ) }</ChipReply> }
          { checkRoles( cmty_roles_author, roles.contributor ) && <ChipReply color='info'     >{ t( `role.${ ROLE_CON }`, { count : 1 } ) }</ChipReply> }
          { checkRoles( cmty_roles_author, roles.leader      ) && <ChipReply color='info'     >{ t( `role.${ ROLE_LEA }`, { count : 1 } ) }</ChipReply> }
          { checkRoles( cmty_roles_author, roles.admin       ) && <ChipReply color='info'     >{ t( `role.${ ROLE_ADM }`, { count : 1 } ) }</ChipReply> }
          { checkRoles( cmty_roles_author, roles.owner       ) && <ChipReply color='warning'  >{ t( `role.${ ROLE_OWN }`, { count : 1 } ) }</ChipReply> }
        </Box>
        <Box sx={{ typography: 'body2', lineHeight: 'normal', display: 'flex', flexWrap: 'wrap', pt : '1px' }}>
          <Box>{ history ? `${ t( 'act.edit' ) }: ${ timetonow_edit }` : timetonow }</Box>
          { ! mayLock && locked && <ChipReply color='warning' >{t( 'chip.locked' )}</ChipReply> }
          {   mayLock && lockedAuthor && <ChipReply color='warning' >{t( 'chip.locked_author' )}</ChipReply> }
          {   mayLock && lockedOther && <ChipReply color='warning' >{t( 'chip.locked_other' )}</ChipReply> }
          { hiddenAuthor && <ChipReply color='warning' >{t( 'chip.hidden_author' )}</ChipReply> }
          { hiddenOther && <ChipReply color='warning' >{t( 'chip.hidden_other' )}</ChipReply> }

          { deleted && <ChipReply color='error' >{t( 'chip.deleted' )}</ChipReply> }
          { invisible && <ChipReply color='warning' >{t( 'chip.invisible' )}</ChipReply> }
          { immutable && <ChipReply color='warning' >{t( 'chip.immutable' )}</ChipReply> }
        </Box>
      </Box>
      { menu &&
        <Box ml='auto'>
          <ALReplyMenu reply={ reply } group_id={ group_id } oaid={ oaid } />
        </Box>
      }
    </Box>
  )
}

function ALReplyContent( { reply } ) {
  const imgs = replyImages( reply )

  return (
    <Box sx={{ p: '0.5rem', }}>
      <Box sx={{ py: '0.5rem', }} >
        <ALEditor value={ replyBody( reply ) } />
      </Box>
      <ALTile objs={ imgs } />
    </Box>
  )
}

function ALReplyActions( { reply } ) {
  return(
    <>
    <CardActions sx={{ p: '0rem' }} >
      <IconButton
        color='default'
        aria-label="Promote"
        disabled
      >
        <VolumeUpOutlinedIcon />
      </IconButton>

      <IconButton
        color='default'
        aria-label="Mute"
        disabled
      >
        <VolumeDownOutlinedIcon />
      </IconButton>

      <Divider orientation="vertical" flexItem  sx={{ml: '0.5rem'}} />
      <ALRepliesActionAdd id={ replyID( reply) } create={ checkCapabilities( replyAuthz( reply ), capabilities.create ) } />

      <Divider orientation="vertical" flexItem />

      <IconButton
        color='default'
        aria-label="Bookmark"
        disabled
      >
        <BookmarkAddOutlinedIcon />
      </IconButton>

      <IconButton
        color='default'
        aria-label="Share"
        disabled
      >
        <IosShareIcon />
      </IconButton>

      <Box ml='auto' />
      <ALRepliesActionToggleShow id={ replyID( reply) } pids={ replyParents( reply ) } count={ replyStatsReplies( reply ) } />
    </CardActions>
    </>
  )
}

function ALReply( { oaid, group_id, pids, id } ) {
  const { t } = useTranslation()
  const reply = useReply( pids, id )
  const author = useFacet( replyCreatedBy( reply ) )

  const mode = useColorModeID()
  const idsAdded = useRepliesAdded( id )
  const showReplies = useRepliesShow( id )

  useEffect( () => { return () => { repliesAddedDelete( id ) } }, [ id ] )

  if( rsIsLoading( reply ) ) return <ALLoading />
  if( rsIsError( reply ) ) {
    return (
      <Box sx={{ pt: '0.5rem', display: 'flex', flexWrap: 'nowrap' }} >
        <ALReplyAvatarError />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderRadius: 2, bgcolor: grey[ mode === 'light' ? 700 : 300 ], color: 'white', minHeight: '3rem' }}>
            <Box sx={{ p: '0.5rem' }} >
              { rsError( reply ).code === 403 ? t( 'err.error_nolongeravailable' ) : t( 'err.error_load' ) }
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  if( ! checkCapabilities( replyAuthz( reply ), capabilities.read ) ) {
    const removed = checkObject( replyAuthz( reply ), object.isHidden | object.isDeleted | object.isInvisible )
    return (
      <Box sx={{ pt: '0.5rem', display: 'flex', flexWrap: 'nowrap' }} >
        <ALReplyAvatarError />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderRadius: 2, bgcolor: grey[ mode === 'light' ? 700 : 300 ], color: 'white', minHeight: '3rem' }}>
            <Box sx={{ p: '0.5rem' }} >
              { removed ? t( 'err.error_nolongeravailable' ) : t( 'err.error_load' ) }
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <>
    <Box sx={{ pt: '0.5rem', display: 'flex', flexWrap: 'nowrap' }} >
      <Box sx={{ width: '100%', }}>
        <Box w='1rem' />
        <Box sx={{ pt : '0.5rem', borderRadius: 2, bgcolor: grey[ mode === 'light' ? 300 : 700 ] }}>
          <Box display='flex' pl='0.5rem' >
            <ALReplyAuthor oaid={ oaid } group_id={ group_id } reply={ reply } author={ author } />
          </Box>
          <ALReplyContent reply={ reply } />
          <Divider />
          <ALReplyActions reply={ reply } />
        </Box>
        <Box>
          <ALReplyNew cid={ replyCommunityID( reply) } group_id={ group_id } id={ id } sid={ replySID( reply ) } sx={{ ml: '0rem', mt: '0.5rem' }} />
        </Box>
      </Box>
    </Box>
    { ( Boolean( idsAdded.length ) || Boolean( showReplies ) ) && (
      <Box sx={{ pl: '1rem' }} >
        <ALRepliesInner oaid={ oaid } group_id={ group_id } pids={ replyParents( reply ) } id={ replyID( reply ) } sid={ replySID( reply ) } />
      </Box>
    ) }
    </>
  )
}

//

function ALRepliesInner( { oaid, group_id, pids, id, sid } ) {
  const { t } = useTranslation()
  const rid = pids.length > 0 ? pids[ 0 ] : id
  const sort = useReplySortValue( rid )
  const idsAdded = useRepliesAdded( id )
  const showReplies = useRepliesShow( id )
  const replies = useRepliesTo( pids, showReplies ? sid : 0, sort )
  const setShowReplies = useRepliesShowSet( id )
  const mode = useColorModeID()
  const ids = difference( repliesIDs( replies ), idsAdded )
  const more = repliesHasMore( replies )

  if( ! idsAdded.length && ! showReplies ) return <></>
  return (
    <Box>
      { idsAdded.map( rid => ( <ALReply oaid={ oaid } group_id={ group_id } pids={ [ ...( pids || [] ), id ] } id={ rid } key={ rid } /> ) ) }
      { ids.map( rid => ( <ALReply oaid={ oaid } group_id={ group_id } pids={ [ ...( pids || [] ), id ] } id={ rid } key={ rid } /> ) ) }
      { ( rsIsLoading( replies ) || rsiIsFetchingNextPage( replies ) ) && <ALLoading /> }
      { ( rsIsError( replies ) ) &&  ( <Box sx={{ pt: '0.5rem', display: 'flex', flexWrap: 'nowrap'}} ><Box sx={{ p: '0.5rem', width: '100%', borderRadius: 2, bgcolor: grey[ mode === 'light' ? 700 : 300 ] }}>{ t( 'err.error_load' ) }</Box></Box> ) }
      { more && <Button variant="text" onClick={ () => { showReplies ? rsiFetchNextPage( replies ) : setShowReplies( true ) } } >{ t('act.load_more' ) }</Button> }
    </Box>
  )
}

function ALReplies( { oaid, group_id, sid, cid } ) {
  const idsAdded = useRepliesAdded( sid )
  const showReplies = Boolean( useRepliesShow( sid ) )
  const showAdd = Boolean( useReplyShowAddValue( sid ) )

  useEffect( () => { return () => { repliesAddedDelete( sid ) } }, [ sid ] )

  if( ! idsAdded.length && ! showReplies && ! showAdd ) return <></>

  return (
    <Box >
      <Divider />
      <Box sx={{ p: '0 0.5rem 0.5rem' }} >
        <ALReplyNew cid={ cid } group_id={ group_id } id={ sid } sid={ sid } sx={{ pt: '0.5rem' }} />
        <ALRepliesInner oaid={ oaid } group_id={ group_id } pids={ [] } id={ sid } sid={ sid } />
      </Box>
    </Box>
  )
}

//

function ALRepliesActionAdd( { id, create } ) {
  const showAdd = useReplyShowAddValue( id )
  const setShowAdd = useReplyShowAddSet( id )

  // Cleanup Toggle on unmount
  useEffect( () => { return () => { replyShowAddDelete( id ) } }, [ id ] )

  return (
    <IconButton
      color={ showAdd ? 'primary' : 'default' }
      onClick={ () => setShowAdd( ! showAdd ) }
      aria-label="Add Comment"
      disabled= { ! create }
    >
      { showAdd ? <AddCommentIcon /> : <AddCommentOutlinedIcon /> }
    </IconButton>
  )
}

function ALReplyNew( { cid, group_id, id, sid, sx } ) {
  const { t } = useTranslation()
  const cmty = useCommunity( cid )
  const group = communityGroup( cmty, group_id )

  const showAdd = Boolean( useReplyShowAddValue( id ) )
  const setShowAdd = useReplyShowAddSet( id )
  const lang_cur = useLangCurrent()
  const langs_group = communityGroupLangs( group )
  const langs = useLangs( langs_group ).map( lang => { return { value: lang.code, label: lang.nativeName } } )
  const fieldRequiredOneOf = useFieldRequiredOneOf( [ 'body', 'images' ] )
  const fieldMax1024 = useFieldStringMax( 1024 )
  const lang = langs_group.includes( lang_cur.code ) ? lang_cur.code : ''

  const addNew = useRepliesAddedAdd( id )
  const onSuccess = ( rec_new ) => { addNew( rec_new.id ); setShowAdd( false ) }

  const fileLimitsQ = useFileLimits( { role : 'content' } )
  const fileLimits = fileLimitsList( fileLimitsQ )

  const formElements = [
    { Comp: ALFormHidden, id: 'parent_id', value: sid },
    { Comp: ALFormTextField, id: 'language', name : t( 'rec.replies.lang' ), check: [ fieldRequired ], value: lang, select: true, options : langs, local: true, disabled : langs.length <= 1 },

    { Comp: ALFormTextField, id: 'body', sub: 'language', name: t( 'rec.replies.body' ), type: 'text', minRows: 2, maxRows: 10, autoFocus: true, check: [ fieldRequiredOneOf, fieldMax1024 ] },
    { Comp: ALFormMedia, id: 'images', role: 'content', fileLimits, langs, lang, value: [], check: [ fieldRequiredOneOf ] },
  ]

  if( ! showAdd ) return <></>

  if( rsIsLoading( fileLimitsQ ) ) return <ALLoading />

  return (
    <Box sx={{ ...sx, borderRadius: 2, border: '1px solid' }}>
      <ALForm
        elements={formElements}
        service='api/replies'
        method='create'
        onSuccess={ onSuccess }
        submitName={ t( 'act.reply') }
        buttonSecondary={ <Button onClick={ () => { setShowAdd( false ) } } >{ t( 'act.cancel' ) }</Button> }
        />
    </Box>
  )
}

// -----

function ALReplyMenuSort( { pids, id } ) {
  const { t } = useTranslation()

  const [ anchorEl, setAnchorEl ] = useState( false )
  const open = Boolean( anchorEl )

  const rid = pids.length > 0 ? undefined : id

  const sort = useReplySortValue( rid )
  const sortSet = useReplySortSet( rid )

  const [ sortSaved, setSortSaved ] = useState( sort )

  const refreshQueries = useRepliesRefreshAll( pids, id )

  function handleClick( e ) {
    setAnchorEl( e.currentTarget )
  }

  function handleClose() {
    setAnchorEl( null )
  }

  function onClick( val ) {
    if( val === sort ) return

    sortSet( val )

    handleClose()
  }

  // Update queries dependent on sort
  useEffect( () => {
    if( sort !== sortSaved ) {
      setSortSaved( sort )
      refreshQueries( pids, id )
    }
  }, [ pids, id, sort, sortSaved, refreshQueries ] )

  // Cleanup on unmount
  useEffect( () => {
    return () => {
      replySortDelete( rid )
    }
  }, [ rid ] )

  if( !rid ) return <></>

  return (
    <Box sx={{ display: 'inline' }}>
      <IconButton
        id="sort-button"
        aria-controls="sort menu"
        aria-haspopup="true"
        aria-expanded={ open ? 'true' : undefined }
        onClick={ handleClick }
        sx={{ p: '0', pr: '0.5rem' }}
      >
        <SortIcon />
      </IconButton>
      <Menu
        id="sort-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
        MenuListProps={{
          'aria-labelledby':'sort-button',
        }}
      >
        <MenuItem onClick={ () =>{ onClick( 'trending' ) } } selected={ sort === 'trending' } disabled>
          <ListItemText>{ t('act.sort.trending') }</ListItemText>
        </MenuItem>
        <MenuItem onClick={ () =>{ onClick( 'newest' ) } } selected={ sort === 'newest' }>
          <ListItemText>{ t('act.sort.newest') }</ListItemText>
        </MenuItem>
        <MenuItem onClick={ () =>{ onClick( 'oldest' ) } } selected={ sort === 'oldest' }>
          <ListItemText>{ t('act.sort.oldest') }</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  )
}

// -----

function ALRepliesActionToggleShow( { pids, id, count } ) {
  const { t } = useTranslation()
  const showReplies = useRepliesShow( id )
  const setShowReplies = useRepliesShowSet( id )
  const refreshQueries = useRepliesRefreshAll( pids, id )

  function onClick() {
    if( ! showReplies ) {
      refreshQueries( pids, id )
      setShowReplies( true )
    }
    else {
      setShowReplies( false )
    }
  }

  // Cleanup on unmount
  useEffect( () => {
    return () => {
      repliesShowDelete( id )
    }
  }, [ id ] )

  if( ! count ) return <></>

  return (
    <Box>
      <Button variant="text" onClick={ onClick } >
        { t( showReplies ? 'act.replies_hide' : 'act.replies_show', { count : count } ) }
      </Button>
      <ALReplyMenuSort pids={ pids } id={ id } />
    </Box>
  )
}

// -----

function ALReplyDialogEdit( { reply, group_id, oaid, open, setOpen, onSuccess } ) {
  const { t } = useTranslation()
  const mobile = useMobile()

  const cmty = useCommunity( replyCommunityID( reply ) )
  const group = communityGroup( cmty, group_id )

  const author = useFacet( replyCreatedBy( reply ) )

  const onClose = () => setOpen( false )

  const lang_cur = useLangCurrent()
  const langs_group = communityGroupLangs( group )
  const langs = useLangs( langs_group ).map( lang => { return { value: lang.code, label: lang.nativeName } } )
  const lang = langs_group.includes( lang_cur.code ) ? lang_cur.code : langs_group[ 0 ].code
  const lDisabled = langs_group.length <= 1

  const fileLimitsQ = useFileLimits( { role : 'content' } )
  const fileLimits = fileLimitsList( fileLimitsQ )

  const fieldRequiredOneOf = useFieldRequiredOneOf( [ 'body', 'images' ] )
  const fieldMax1024 = useFieldStringMax( 1024 )

  const bodies = replyBodies( reply )
  const images = replyImages( reply )

  const formElements = [
    { Comp: ALFormHidden, id: 'op', value: 'edit' },

    { Comp: ALFormTextField, id: 'language', name : t( 'rec.replies.lang' ), check: [ fieldRequired ], value: lang, select: true, options : langs, local: true, disabled : lDisabled },

    { Comp: ALFormTextField, id: 'body', sub: 'language', value : bodies, name: t( 'rec.replies.body' ), type: 'text', minRows: 2, maxRows: 10, check: [ fieldChangedOne, fieldRequiredOneOf, fieldMax1024 ] },
    { Comp: ALFormMedia, id: 'images', role: 'content', fileLimits, langs, lang, value: images, check: [ fieldChangedOne, fieldRequiredOneOf ] },
  ]

  return (
    <Dialog onClose={ onClose } open={ open } fullScreen={ mobile } fullWidth sx={{ '& .MuiDialog-paper' : { margin: 0, maxWidth: '40rem', width: 'min( 40rem, 100% )', maxHeight: '100%' } }} >
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center' >
          <Box>{ t( 'mod.replies.act.edit' ) }</Box>
          <IconButton aria-label='close' onClick={ onClose } sx={{ p: '0.25rem' }} ><CloseIcon /></IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers sx={{ px: '0' }} >
        <Box sx={{ px: '1rem' }} display='flex' >
          <ALReplyAuthor reply={ reply } author={ author } menu={ false } />
        </Box>
        <ALForm
          elements={ formElements }
          service='api/replies'
          method='patch'
          oid={ replyID( reply ) }
          onSuccess={ onSuccess }
          submitName={ t( 'act.update') }
          buttonSecondary={ <Button onClick={ onClose } >{ t( 'act.cancel' ) }</Button> }
          allowRepeat
        />
      </DialogContent>
    </Dialog>
  )
}

// -----

function ALReplyDialogHistory( { reply, open, setOpen, onSuccess } ) {
  const { t } = useTranslation()
  const mobile = useMobile()
  const locale = useLocaleCurrent()

  const history = replyHistory( reply )

  const onClose = () => setOpen( false )

  return (
    <Dialog onClose={ onClose } open={ open } fullScreen={ mobile } fullWidth sx={{ '& .MuiDialog-paper' : { margin: 0, maxWidth: '40rem', width: 'min( 40rem, 100% )', maxHeight: '100%' } }} >
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center' >
          <Box>{ t( 'mod.replies.act.history' ) }</Box>
          <IconButton aria-label='close' onClick={ onClose } sx={{ p: '0.25rem' }} ><CloseIcon /></IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers sx={{ px: '0' }}>
        <List>
          { [ ...( history ?? [] ) ].reverse().map( elem => {
            const time = format( new Date( elem.at ), 'PPPPp', { locale: locale } )
            return (
            <ListItem key={ elem.at }>{ time }</ListItem>
            )
          } ) }
        </List>
      </DialogContent>
    </Dialog>
  )
}

//

export {
    ALReplies,
    ALRepliesActionAdd,
    ALRepliesActionToggleShow,
}