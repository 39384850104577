//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import '../App.css';
import React from "react"

import {
  Box,
  Card,
  Typography
} from '@mui/material'

import ALPage from '../components/ALPage'

//

export default function ALRoot()  {

  return (
    <>
    <ALPage >
      <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' >

        <Card sx={{ p : '1rem', maxWidth : '320px' }} >
          <Typography component='h2' variant='h4' align='center'>
            OOPS!  Page Not Found!
          </Typography>
        </Card>

      </Box>
    </ALPage>
    </>
  )
}
