//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useCallback,
} from 'react'

import {
  useQuery,
  useQueryClient,
  useMutation,
} from 'react-query'

import { paramsForServer } from 'feathers-hooks-common'

import {
  rsInit,
  rsCheckType,
  rsIsLoading,
  rsIsError,
} from '../remote-state/core'

import {
  useALService,
} from '../components/client'

import {
  capabilities,
  roles,
  checkCapabilities,
  checkRoles,
} from './authorization'

import {
  useFacet,
  facetID,
} from './facets'

import {
  fieldTextLangs,
} from '../components/ALI18N'

import { useColorModeID } from '../components/ALColorMode'

// -----

const ROLE_PUB = 'p'
const ROLE_STE = 's'
const ROLE_MEM = 'm'
const ROLE_GRP = 'g'
const ROLE_CON = 'c'
const ROLE_LEA = 'l'
const ROLE_ADM = 'a'
const ROLE_OWN = 'o'

// -----

function authzToRole( arole ) {
  switch( arole ) {
    case roles.member : return ROLE_MEM
    case roles.group : return ROLE_GRP
    case roles.contributor : return ROLE_CON
    case roles.leader : return ROLE_LEA
    case roles.admin : return ROLE_ADM
    case roles.owner : return ROLE_OWN
    default : return null
  }
}

function roleToAuthz( role ) {
  switch( role ) {
    case ROLE_MEM : return roles.member
    case ROLE_GRP : return roles.group
    case ROLE_CON : return roles.contributor
    case ROLE_LEA : return roles.leader
    case ROLE_ADM : return roles.admin
    case ROLE_OWN : return roles.owner
    default : return null
  }
}

// -----

function useCommunity( cid ) {
  const mode = useColorModeID()
  const service = useALService()
  const facet_id = facetID( useFacet() )

  const cmty = useQuery( [ facet_id, 'community', cid ], async () => {
    return await service( 'api/communities', 'get', cid, paramsForServer( { facet_id } ) )
  })

  const rsCmty = rsInit( cmty, 'cmty' )

  if( rsCmty?.data ) {
    const group = communityGroup( rsCmty, communityID( rsCmty ) )
    if( group.data?.images ) {
      group.data.images.avatar = group.data.images[ `avatar_${ mode }` ] || group.data.images.avatar_light || ''
      group.data.images.image = group.data.images[ `image_${ mode }` ] || group.data.images.image_light || ''
    }
  }

  return rsCmty
}

function useCommunityReload( cid ) {
  const queryClient = useQueryClient()
  const facet_id = facetID( useFacet() )

  return useCallback( () => {
    return queryClient.invalidateQueries( [ facet_id, 'community', cid ] )
  }, [ facet_id, queryClient, cid ] )
}

// ----- Accessors

function ckCommunity( cmty ) {
  rsCheckType( cmty, 'cmty' )
}

function communityID( cmty ) {
  ckCommunity( cmty )
  return cmty.data?.id
}

/*
function communityAltID( cmty ) {
  ckCommunity( cmty )
  return cmty.data?.id_alt
}
*/

function communityAuthz( cmty ) {
  ckCommunity( cmty )
  const group = communityGroup( cmty, communityID( cmty ) )
  return communityGroupAuthz( group )
}

function communityName( cmty, langs, nodefault ) {
  ckCommunity( cmty )
  const group = communityGroup( cmty, communityID( cmty ) )
  return communityGroupName( group, langs, nodefault )
}

function communityNames( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return {}
  const group = communityGroup( cmty, communityID( cmty ) )
  return communityGroupNames( group )
}

function communityHead( cmty, user_langs, nodefault ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return ''
  const group = communityGroup( cmty, communityID( cmty ) )
  return fieldTextLangs( group?.data?.headline ?? {}, user_langs, nodefault ) ?? ''
}

function communityHeadlines( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return {}
  const group = communityGroup( cmty, communityID( cmty ) )
  return group?.data?.headline ?? {}
}

function communityDesc( cmty, user_langs, nodefault ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return ''
  const group = communityGroup( cmty, communityID( cmty ) )
  return fieldTextLangs( group?.data?.description ?? {}, user_langs, nodefault ) ?? ''
}

function communityDescriptions( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return {}
  const group = communityGroup( cmty, communityID( cmty ) )
  return group?.data?.description ?? {}
}

function communityImageID( cmty, mode ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return ''
  const group = communityGroup( cmty, communityID( cmty ) )
  switch( mode ) {
    default: return group.data.images?.image || ''
    case 'light': return group.data.images?.image_light || ''
    case 'dark': return group.data.images?.image_dark || ''
  }
}

function communityAvatarID( cmty, mode ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return ''
  const group = communityGroup( cmty, communityID( cmty ) )
  switch( mode ) {
    default: return group.data.images?.avatar || ''
    case 'light': return group.data.images?.avatar_light || ''
    case 'dark': return group.data.images?.avatar_dark || ''
  }
}

function communityVisibility( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return 'hidden'
  const cmty_group = communityGroup( cmty, communityID( cmty ) )
  const cnt_ri = communityGroupAuthzRolesCountBits( cmty_group, capabilities.read_info, [ 'public', 'site', 'member' ] )
  const cnt_r = communityGroupAuthzRolesCountBits( cmty_group, capabilities.read, [ 'public', 'site', 'member' ] )
  const visibility = cnt_ri < 3
    ? 'hidden'
    : cnt_r < 3
      ? 'private'
      : ''

  return visibility
}

function communityArchived( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return false
  const group = communityGroup( cmty, communityID( cmty ) )
  return group?.data?.archived ?? false
}

function communityMemCnt( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return 0
  const group = communityGroup( cmty, communityID( cmty ) )
  return communityGroupRoleCnt( group, ROLE_MEM )
}

function communityLeaCnt( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return 0
  const group = communityGroup( cmty, communityID( cmty ) )
  return communityGroupRoleCnt( group, ROLE_LEA )
}

function communityAdmCnt( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return 0
  const group = communityGroup( cmty, communityID( cmty ) )
  return communityGroupRoleCnt( group, ROLE_ADM )
}

function communityOwnCnt( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return 0
  const group = communityGroup( cmty, communityID( cmty ) )
  return communityGroupRoleCnt( group, ROLE_OWN )
}

function communityInterests( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return []
  const group = communityGroup( cmty, communityID( cmty ) )
  return Object.keys( group.data?.interests || {} )    // TODO - still to be defined and implemented
}

function communityInterestLabel( cmty, interest_id, langs, nodefault ) {
  ckCommunity( cmty )
  return fieldTextLangs( cmty.data?.interests?.[ interest_id ] ?? {}, langs, nodefault )    // TODO - still to be defined and implemented
}

function communityLangs( cmty ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return []
  const group = communityGroup( cmty, communityID( cmty ) )
  return group?.data?.languages ?? []
}

// -----

function communityGroup( cmty, group_id ) {
  ckCommunity( cmty )

  const cmty_group_id = communityID( cmty )
  const cmty_group = {
    data : ( cmty.data?.groups ?? [] ).find( group => group.id === ( group_id ?? cmty_group_id ) )
  }
  return rsInit( cmty_group, 'cmty group' )
}

function communityGroups( cmty, capability ) {
  ckCommunity( cmty )
  if( ! cmty?.data ) return []

  const groups = ( cmty.data.groups || [] ).reduce( ( acc, elem ) => {
    if( elem.parent_id && ( capability ? checkCapabilities( elem.authz, capability ) : true ) ) {
      const group = { data : elem }
      rsInit( group, 'cmty group' )
      acc.push( rsInit( group, 'cmty group' ) )
    }
    return acc
  }, [] )

  return groups
}

function ckCommunityGroup( cmty_group ) {
  rsCheckType( cmty_group, 'cmty group' )
}

function communityGroupID( cmty_group ) {
  ckCommunityGroup( cmty_group )
  return cmty_group.data?.id
}

function communityGroupAuthz( cmty_group ) {
  ckCommunityGroup( cmty_group )
  return cmty_group?.data?.authz ?? {}
}

function communityGroupAuthzRoles( group, type ) {
  ckCommunityGroup( group )
  return group?.data?.authz_roles?.[ type ] ?? 0
}

function communityGroupAuthzRolesCountBits( group, mask, roles ) {
  ckCommunityGroup( group )
  const authz_roles = group?.data?.authz_roles ?? {}
  const keys = Object.keys( authz_roles )
  const value = keys.reduce( ( acc, role ) => {
    const inc = roles.find( elem => elem === role )
    if( inc && ( authz_roles[ role ] & mask ) ) acc++
    return acc
  }, 0 )
  return value
}

function communityGroupParentID( group ) {
  ckCommunityGroup( group )
  return group?.data?.parent_id
}

function communityGroupName( cmty_group, langs, nodefault ) {
  ckCommunityGroup( cmty_group )
  return fieldTextLangs( cmty_group?.data?.name ?? {}, langs, nodefault )
}

function communityGroupNames( cmty_group ) {
  ckCommunityGroup( cmty_group )
  return cmty_group?.data?.name ?? {}
}

function communityGroupLangs( cmty_group ) {
  ckCommunityGroup( cmty_group )
  return cmty_group?.data?.languages ?? []
}

function communityGroupRoleCnt( cmty_group, role ) {
  // ckCommunityGroup( cmty_group )
  return cmty_group?.data?.stats?.roles?.[ role ] ?? 0
}

// function communityGroupAuthz( cmty, group_id ) {
//   ckCommunity( cmty )
//   const group = communityGroup( cmty, group_id )
//   return group?.authz ?? {}
// }

function communityGroupHead( cmty_group, user_langs, nodefault ) {
  ckCommunityGroup( cmty_group )
  if( ! cmty_group?.data ) return ''
  return fieldTextLangs( cmty_group?.data?.headline ?? {}, user_langs, nodefault ) ?? ''
}

function communityGroupHeadlines( cmty_group, user_langs, nodefault ) {
  ckCommunityGroup( cmty_group )
  if( ! cmty_group?.data ) return {}
  return cmty_group?.data?.headline ?? {}
}

function communityGroupDesc( cmty_group, user_langs, nodefault ) {
  ckCommunityGroup( cmty_group )
  if( ! cmty_group?.data ) return ''
  return fieldTextLangs( cmty_group?.data?.description ?? {}, user_langs, nodefault ) ?? ''
}

function communityGroupDescriptions( cmty_group, user_langs, nodefault ) {
  ckCommunityGroup( cmty_group )
  if( ! cmty_group?.data ) return {}
  return cmty_group?.data?.description ?? {}
}

function communityGroupVisibility( cmty_group ) {
  ckCommunityGroup( cmty_group )
  if( ! cmty_group?.data ) return 'hidden'
  const cnt_ri = communityGroupAuthzRolesCountBits( cmty_group, capabilities.read_info, [ 'public', 'site', 'member', 'group' ] )
  const cnt_r = communityGroupAuthzRolesCountBits( cmty_group, capabilities.read, [ 'public', 'site', 'member', 'group' ] )
  const visibility = cnt_ri < 4
    ? 'hidden'
    : cnt_r < 4
      ? 'private'
      : ''

  return visibility
}

// -----

function useCommunityMembers( cmty, options ) {
  ckCommunity( cmty )

  const group_id = options?.group_id
  const query = options?.query
  const limit = options?.limit

  const service = useALService()
  const facet_id = facetID( useFacet() )

  const valid = ! rsIsLoading( cmty )

  // TODO - add sort capabilities

  const mbs = useQuery( [ facet_id, 'community', communityID( cmty ), 'members', group_id, query, limit ] , async () => {
    if( ! valid || ! options.enabled ) return []
    return await service( 'api/communities', 'find', paramsForServer( { query: { op: 'members', community_id: communityID( cmty ), query, limit, group_id }, facet_id } ) )
  } )

  return rsInit( mbs, 'cmty members' )
}

//

function ckCommunityMembers( mbs ) {
  rsCheckType( mbs, 'cmty members' )
}

function communityMembers( mbs ) {
  ckCommunityMembers( mbs )

  if( rsIsLoading( mbs ) || rsIsError( mbs ) ) return []

  return mbs.data.facet_ids ?? []
}

function communityMembersHasMore( mbs ) {
  ckCommunityMembers( mbs )

  if( rsIsLoading( mbs ) || rsIsError( mbs ) ) return false

  return mbs.data.has_more
}

function communityMembersInsufficient( mbs ) {
  ckCommunityMembers( mbs )

  if( rsIsLoading( mbs ) || rsIsError( mbs ) ) return false

  return mbs.data.insufficient ?? false
}

// -----

function useCommunityRoles( cmty ) {
  ckCommunity( cmty )

  const service = useALService()
  const facet_id = facetID( useFacet() )

  const cid = communityID( cmty )

  const cmty_roles = useQuery( [ facet_id, 'community', cid, 'members_roles' ] , async () => {
    if( ! cid ) return {}
    return service( 'api/communities', 'find', paramsForServer( { query: { op: 'members_roles', community_id: cid }, facet_id } ) )
  } )

  return rsInit( cmty_roles, 'cmty roles' )
}


function useCommunityRolesReload( cmty ) {
  ckCommunity( cmty )

  const cid = communityID( cmty )
  const facet_id = facetID( useFacet() )

  const queryClient = useQueryClient()

  return useCallback( () => {
    return queryClient.invalidateQueries( [ facet_id, 'community', cid, 'members_roles' ] )
  }, [ queryClient, facet_id, cid ] )
}

//

function useCommunityRolesModify( cmty ) {
  ckCommunity( cmty )

  const count_own = communityOwnCnt( cmty )

  const cid = communityID( cmty )

  const cmty_reload = useCommunityReload( cid )
  const cmtys_reload = useCommunitiesReload()

  const service = useALService()
  const facet_id = facetID( useFacet() )

  return useMutation(
    async ( { role, op, subject_id, group_id } ) => {
      const group = communityGroup( cmty, group_id )
      const authz = communityGroupAuthz( group )
      if( ! group ) throw new Error( 'useCommunityAddRole.mutate - Invalid group_id' )
      if( ! (
        checkCapabilities( authz, capabilities[ `manage_${ role }` ] )
        || ( op === 'add' && role === ROLE_OWN && count_own === 0 )
        || ( op === 'remove' && facet_id === subject_id )
      ) ) throw new Error( 'useCommunityRolesModify.mutate - May not modify roles ' )

      return await service( 'api/communities', 'patch', cid, { op: `role_${ op }`, role, subject_id, group_id }, paramsForServer( { facet_id } ) )
    },
    {
      onSettled: () => {
        cmty_reload()
        cmtys_reload()
      }
    } )
}

function communityRolesRole( cmty_roles, role, group_id ) {
  ckCommunityRoles( cmty_roles )
  if( rsIsLoading( cmty_roles ) || rsIsError( cmty_roles ) ) return []

  const arole = roleToAuthz( role )
  const group = group_id ? group_id : undefined

  const list = ( cmty_roles.data.roles ?? [] ).reduce( ( acc, elem ) => {
    if( elem.group_id === group && checkRoles( { roles : elem.roles }, arole ) ) acc.push( elem )
    return acc
  }, [] )

  return list

}

// ----- Accessors

function ckCommunityRoles( cmty_roles ) {
  rsCheckType( cmty_roles, 'cmty roles' )
}


function communityUserRoles( cmty_roles, facet, group_id ) {
  ckCommunityRoles( cmty_roles )

  if( rsIsLoading( cmty_roles ) || rsIsError( cmty_roles ) ) return 0
  if( rsIsLoading( facet ) || rsIsError( facet ) ) return 0

  if( ! Array.isArray( cmty_roles.data.roles ) ) return 0

  const val = ( cmty_roles.data.roles ?? [] ).find( elem => elem.facet_id === facetID( facet ) && elem.group_id === group_id ) || {}
  return { roles : val.roles ?? 0 }
}

// -----

function useCommunitiesReload() {
  const queryClient = useQueryClient()
  const facet_id = facetID( useFacet() )

  return useCallback( () => {
    return queryClient.invalidateQueries( [ facet_id, 'communities' ] )
  }, [ queryClient, facet_id ] )

}

// -----

function useCommunitiesMyLists() {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  const userLists = useQuery( [ facet_id, 'communities', 'user_lists' ] , async () => {
    return await service( 'api/communities', 'find', paramsForServer( { query: { op: 'user_lists' }, facet_id } ) )
  } )

  return rsInit( userLists, 'cmty user_lists' )
}

function useCommunitiesMyListsReload() {
  const queryClient = useQueryClient()
  const facet_id = facetID( useFacet() )

  return useCallback( () => {
    return queryClient.invalidateQueries( [ facet_id, 'communities', 'user_lists' ] )
  }, [ queryClient, facet_id ] )
}

function communitiesListRole( user_lists, role ) {
  rsCheckType( user_lists, 'cmty user_lists')

  if( rsIsLoading( user_lists ) || rsIsError( user_lists ) ) return []

  return user_lists.data?.[ role ] ?? []
}

// -----

function useCommunityCreate( facet ) {
  const list_reload = useCommunitiesMyListsReload( facet )

  return useCallback( () => { list_reload() }, [ list_reload ] )
}

//

function useCommunityJoin( cmty ) {
  ckCommunity( cmty )

  const service = useALService()
  const facet_id = facetID( useFacet() )

  const cid = communityID( cmty )

  const cmty_reload = useCommunityReload( cid )
  const cmtys_reload = useCommunitiesReload()

  return useMutation(
    async ( { group_id = communityID( cmty ), subject_id } = {} ) => {
      if( ! cid ) throw new Error( 'useCommunityJoin.mutate - Invalid CID' )
      const group = communityGroup( cmty, group_id )
      const manage = cid === group_id ? capabilities.manage_m : capabilities.manage_g
      if( ! checkCapabilities( communityGroupAuthz( group ), capabilities.join | manage ) ) throw new Error( 'useCommunityJoin.mutate - Join Forbidden' )
      return await service( 'api/communities', 'patch', cid, { op: 'join', group_id, subject_id }, paramsForServer( { facet_id } ) )
    },
    {
      onSettled : () => {
        cmty_reload()
        cmtys_reload()
      }
    })
}

//

function useCommunityLeave( cmty, onSettled ) {
  ckCommunity( cmty )

  const service = useALService()
  const facet_id = facetID( useFacet() )

  const cid = communityID( cmty )

  const cmty_reload = useCommunityReload( cid )
  const cmtys_reload = useCommunitiesReload()

  return useMutation(
    async ( { group_id = communityID( cmty ), subject_id } = {} ) => {
      if( ! cid ) throw new Error( 'useCommunityLeave.mutate - Invalid CID' )
      const group = communityGroup( cmty, group_id )
      const manage = cid === group_id ? capabilities.manage_m : capabilities.manage_g
      if( ! checkCapabilities( communityGroupAuthz( group ), capabilities.leave | manage ) ) throw new Error( 'useCommunityJoin.mutate - Leave Forbidden' )
      return await service( 'api/communities', 'patch', cid, { op: 'leave', group_id, subject_id }, paramsForServer( { facet_id } ) )
    },
    {
      onSettled: () => {
        cmty_reload()
        cmtys_reload()
        if( onSettled ) onSettled()
      }
    } )
}

// -----

function useCommunitiesDiscover() {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  const cmty = useQuery( [ facet_id, 'communities', 'discover' ], async () => {
    return await service( 'api/communities', 'find', paramsForServer( { query: { op: 'featured' }, facet_id } ) )

  })
  return rsInit( cmty, 'cmty discover' )
}

function useCommunitiesDiscoverReload() {
  const queryClient = useQueryClient()
  const facet_id = facetID( useFacet() )

  return useCallback( () => {
    return queryClient.invalidateQueries( [ facet_id, 'communities', 'discover' ] )
  }, [ facet_id, queryClient ] )
}

function communitiesDiscover( disc_lists, type ) {
  rsCheckType( disc_lists, 'cmty discover')
  if( rsIsLoading( disc_lists ) || rsIsError( disc_lists ) ) return false

  return disc_lists.data
}

// -----

function useCommunityMemberRoles( cmty, subject_id ) {
  ckCommunity( cmty )

  const service = useALService()
  const facet_id = facetID( useFacet() )
  const cid = communityID( cmty )

  const valid = ! rsIsLoading( cmty ) && Boolean( subject_id ) && Boolean( facet_id )

  const groups_roles = useQuery( [ facet_id, 'communities', 'member_roles', subject_id ], async () => {
    if( ! valid ) return []
    return await service( 'api/communities', 'find', paramsForServer( { query: { op: 'member_roles', community_id: cid, subject_id }, facet_id } ) )
  } )
  return rsInit( groups_roles, 'cmty member roles' )
}

// function useCommunityMemberRolesReload() {
//   const queryClient = useQueryClient()
//   const facet_id = facetID( useFacet() )

//   return useCallback( () => {
//     return queryClient.invalidateQueries( [ facet_id, 'communities', 'member_roles' ] )
//   }, [ queryClient, facet_id ] )
// }

function ckCommunityMemberRoles( groups_roles ) {
  rsCheckType( groups_roles, 'cmty member roles' )
}

function communityMemberRoles( member_roles ) {
  ckCommunityMemberRoles( member_roles )

  if( rsIsLoading( member_roles ) || rsIsError( member_roles ) ) return []

  return member_roles.data ?? []
}

// -----

export {
  authzToRole,
  roleToAuthz,

  useCommunity,
  useCommunityReload,

  communityID,
  //communityAltID,
  communityAuthz,
  communityName,
  communityNames,
  communityHead,
  communityHeadlines,
  communityDesc,
  communityDescriptions,
  communityImageID,
  communityAvatarID,
  communityVisibility,
  communityArchived,
  communityMemCnt,
  communityLeaCnt,
  communityAdmCnt,
  communityOwnCnt,

  communityInterests,
  communityInterestLabel,
  communityLangs,

  communityGroup,
  communityGroups,
  communityGroupID,
  communityGroupAuthz,
  communityGroupAuthzRoles,
  communityGroupAuthzRolesCountBits,
  communityGroupParentID,
  communityGroupName,
  communityGroupNames,
  communityGroupLangs,
  communityGroupRoleCnt,
  communityGroupHead,
  communityGroupHeadlines,
  communityGroupDesc,
  communityGroupDescriptions,
  communityGroupVisibility,

  useCommunityMembers,
  communityMembers,
  communityMembersHasMore,
  communityMembersInsufficient,

  useCommunityRoles,
  useCommunityRolesReload,
  useCommunityRolesModify,
  communityRolesRole,
  communityUserRoles,

  useCommunitiesMyLists,
  communitiesListRole,

  useCommunityCreate,
  useCommunityJoin,
  useCommunityLeave,

  useCommunitiesDiscover,
  useCommunitiesDiscoverReload,
  communitiesDiscover,

  useCommunityMemberRoles,
  communityMemberRoles,

  ROLE_PUB,
  ROLE_STE,
  ROLE_MEM,
  ROLE_GRP,
  ROLE_CON,
  ROLE_LEA,
  ROLE_ADM,
  ROLE_OWN,
}
