//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  Helmet,
  HelmetProvider,
} from 'react-helmet-async'

//

function ALHelmet() {
  return (
    <Helmet>
      <title>Avvia Life</title>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  )
}

//

function withHelmet( Component ) {
  return function (...props) {
    return(
      <HelmetProvider >
        <Component {...props} />
      </HelmetProvider>
    )
  }
}

//

export {
  ALHelmet,
  withHelmet,
}
