//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  Link,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

import {
  Paper,
  Box,
  Typography,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from '@mui/material'

import {
  Group as GroupIcon,
  Shield as ShieldIcon,
  LocalPolice as LocalPoliceIcon,
  Public as PublicIcon,
  LockOutlined as LockedIcon,
  VisibilityOffOutlined as HiddenIcon,
} from '@mui/icons-material'

import {
  rsIsLoading,
  rsIsError,
} from '../../remote-state/core'

import {
  capabilities,
  checkCapabilities,
} from '../../remote-state/authorization'

import {
  useCommunity,
  communityID,
  communityName,
  communityHead,
  communityImageID,
  communityMemCnt,
  communityLeaCnt,
  communityAdmCnt,
  useCommunityJoin,
  communityAuthz,
  communityVisibility,
} from '../../remote-state/communities'

import {
  useImage,
  imageUrl,
  imageAlt,
} from '../../remote-state/images'

import {
  useDesktop,
} from '../../components/ALScreenSizing'

import {
  ALChip,
  ALChipGroup,
} from '../../components/ALChip'

import ALLoading from '../../components/ALLoading'

// -----

function ALHeroSide ( { cmty } ) {
  const { t, i18n } = useTranslation()
  const img = useImage( communityImageID( cmty ) )
  const vis = communityVisibility( cmty )

  if( ! cmty ) return <ALLoading />

  return (
    <Paper elevation={0}>
      <img src={ imageUrl( img ) } alt={ imageAlt( img ) } style={{ height: '8rem', width: '100%', objectFit: 'cover' }} />
      <Box mx='1rem' mt='0.5rem'>
        <Typography component='h1' variant='h5'  >{ communityName( cmty, [ i18n.language ] ) }</Typography>
        { ! vis               && <Box display='flex' alignItems='center' ><PublicIcon sx={{ fontSize : '1rem', mr: '0.5rem' }} /><span>{ t( 'rec.community.vis.public' ) }</span></Box>}
        {   vis === 'private' && <Box display='flex' alignItems='center' ><LockedIcon sx={{ fontSize : '1rem', mr: '0.5rem' }} /><span>{ t( 'rec.community.vis.private' ) }</span></Box>}
        {   vis === 'hidden'  && <Box display='flex' alignItems='center' ><HiddenIcon sx={{ fontSize : '1rem', mr: '0.5rem' }} /><span>{ t( 'rec.community.vis.hidden' ) }</span></Box>}
      </Box>
    </Paper>
  )
}

// -----

const useStyles = makeStyles({
  root: {
    minWidth: '19rem',
    maxWidth: '19rem',
  },
  media: {
    height: '8rem',
  },
  content: {
   padding: '0',
   margin: '0.25rem',
  },
  actions: {
   padding: '0',
   margin: '0.25rem',
  }
});


export default function ALCardDiscover( { cid, className } ) {
  const { t, i18n } = useTranslation()
  const classes = useStyles();
  const desktop = useDesktop()
  const cmty = useCommunity( cid )
  const mayJoin = checkCapabilities( communityAuthz ( cmty ), capabilities.join )
  const communityJoin = useCommunityJoin( cmty )
  const img = useImage( communityImageID( cmty ) )
  const vis = communityVisibility( cmty )

  const head = communityHead( cmty, i18n.languages )

  if( rsIsLoading( cmty ) || rsIsLoading( img ) ) return ( <Card className={clsx(classes.root, className)} ><CardContent className={classes.content}><ALLoading /></CardContent></Card> )
  if( rsIsError( cmty ) )   return ( <Card className={clsx(classes.root, className)} ><CardContent className={classes.content}><p>t('err.error')</p></CardContent></Card> )

  return (
    <Card className={clsx(classes.root, className)} >
      <CardActionArea component={Link} to={`/communities/${ communityID( cmty ) }/${desktop ? 'about' : ''}`} >
        <CardMedia
          className={classes.media}
          image={ imageUrl( img ) }
          title=''
        />
        <CardContent className={classes.content}>
          <Typography variant="h5" component="h2">
            { communityName( cmty, [ i18n.language ] ) }
          </Typography>
          <ALChipGroup>
            <ALChip icon={<GroupIcon/>} label={`${ communityMemCnt( cmty ) } ${ t('rec.community.mem', { count: communityMemCnt( cmty ) }) }`} />
            { communityLeaCnt( cmty ) && <ALChip icon={<ShieldIcon/>} label={`${ communityLeaCnt( cmty ) } ${ t( 'rec.community.lea', { count: communityLeaCnt( cmty ) }) }`} /> }
            <ALChip icon={<LocalPoliceIcon/>} label={`${ communityAdmCnt( cmty ) } ${ t( 'rec.community.adm', { count: communityAdmCnt( cmty ) }) }`} />
          </ALChipGroup>

          <Typography variant="body2" color="textSecondary" component="p" noWrap>
            { head }
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        { vis === 'private' && <Box display='flex' alignItems='center' ><LockedIcon sx={{ fontSize : '1rem', mr: '0.5rem' }} /><span>{ t( 'rec.community.vis.private' ) }</span></Box>}
        { mayJoin && (
          <Button
            size='small'
            color='primary'
            onClick={ () => { communityJoin.mutate() } }
            >
            { t('act.join') }
          </Button>
        ) }
      </CardActions>
    </Card>
  )
}

// -----

export {
  ALHeroSide,
  ALCardDiscover,
}