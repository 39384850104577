//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useParams,
  Link,
  useLocation,
  Navigate,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import {
  Box,
  Card,
  Tabs,
  Tab,
} from '@mui/material'

import {
  LockOutlined as LockedIcon,
  VisibilityOffOutlined as HiddenIcon,
} from '@mui/icons-material'

import {
  rsIsLoading,
  rsIsError,
} from '../../remote-state/core'

import {
  useCommunity,
  communityName,
  communityArchived,

  communityGroup,
  communityGroups,
  communityGroupID,
  communityGroupName,
  communityGroupVisibility,

} from '../../remote-state/communities'

import {
  ALMain,
} from '../../components/ALMain'

import {
  ALPosts,
  urlPost,
} from '../../components/ALPosts'

import ALLoading from '../../components/ALLoading'

//

export default function ALMainCIDPosts() {
  const { t, i18n } = useTranslation()
  const { cid, gid } = useParams()
  const path = [...useLocation().pathname.split('/').slice(0,3), 'posts'].join('/')

  const cmty = useCommunity( cid )
  const groups = communityGroups( cmty )

  if( rsIsLoading( cmty ) ) return ( <ALMain><ALLoading /></ALMain>)
  if( rsIsError( cmty ) ) return ( <ALMain><p>t('err.error')</p></ALMain>)

  const group = communityGroup( cmty, gid )
  if( gid && communityGroupID( group ) !== gid ) return <Navigate to={ urlPost( cid ) } />

  return (
    <ALMain title={ communityName( cmty, [ i18n.language ] ) } nocard alerts={ communityArchived( cmty ) ? [ { severity : 'warning', message: t( `mod.community.archived_${ communityArchived( cmty ) }` ) } ] : null }>
      { groups.length > 1 && (
        <>
          <Card sx={{ pb: '2px' }} >
          <Tabs
            value={ gid || cid }
            variant="scrollable"
            scrollButtons="auto"
            aria-label="community groups"
            sx={{ '& .MuiButtonBase-root' : {  minHeight : '1rem', } }}
            >
            <Tab value={ cid } label={ t( 'rec.community.group_all' ) } to={ path } component={ Link } />
            { groups.map( group => {
              const group_id = communityGroupID( group )
              const cmty_group = communityGroup( cmty, group_id )
              const vis = communityGroupVisibility( cmty_group )
              const icon = vis === 'hidden'
                ? <HiddenIcon sx={{ fontSize : '1rem' }} />
                : vis === 'private'
                  ? <LockedIcon sx={{ fontSize : '1rem' }} />
                  : <></>

              return <Tab value={ group_id } label={ communityGroupName( group, [ i18n.language ] ) } icon={ icon } iconPosition='end' to={ `${ path }/${ group_id }` } component={ Link } key={ group_id } />
            } ) }
          </Tabs>
          </Card>
          <Box sx={{ pb: '0.5rem' }} />
        </>
      ) }
      <ALPosts group_id={ gid ?? cid } cmty={ cmty } />
    </ALMain>
  )
}
