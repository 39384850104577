//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useState,
} from 'react'

import { useNavigate } from 'react-router'

import { useTranslation } from 'react-i18next'

import { rsIsLoading } from '../../remote-state/core'

import {
  useFacet,
} from '../../remote-state/facets'

import {
  useLangs,
  useLangCurrent,
} from '../../components/ALI18N'

import {
  useCommunityCreate,
} from '../../remote-state/communities'

import ALForm from '../../components/form/ALForm'
import {
  fieldRequired,
  fieldChanged,
  useFieldStringMax,
  ALFormTextField,
} from '../../components/form/ALForm'

import {
  ALMain,
} from '../../components/ALMain'
import { useEffect } from 'react'

//

export default function ALMainCreate () {
  const { t } = useTranslation()
  const { code } = useLangCurrent()
  const facet = useFacet()
  const list_reload = useCommunityCreate( facet )
  const [ cid, setCID ] = useState( null )
  const navigate = useNavigate()

  const fieldMax64 = useFieldStringMax( 64 )
  const fieldMax80 = useFieldStringMax( 80 )
  const fieldMax2048 = useFieldStringMax( 2048 )

  const vOptions = t( 'rec.community.visibility-options', { returnObjects: true } )

  function successHandler( { id } ) {
    list_reload()
    setCID( id )
  }

  useEffect( () => {
    if( cid ) navigate( `/communities/${ cid }/admin` )
  }, [ cid, navigate ] )

  const ls = useLangs().map( lang => { return { value: lang.code, label: lang.nativeName } } )

  if( rsIsLoading( facet ) ) return <></>

  const formElements = [
    { Comp: ALFormTextField, id: 'language', local : true, name : t('rec.community.lang'), check: [ fieldRequired ], value: code, select: true, options : [ { value: '', label: t( 'act.sellang' ) }, ...ls ] },

    { Comp: ALFormTextField, id: 'name', sub : 'language', name: t('rec.community.name'), autoFocus: true, check: [ fieldRequired, fieldMax64 ] },
    { Comp: ALFormTextField, id: 'visibility', name : t('rec.community.visibility'), check: [ fieldRequired, fieldChanged ], value: '-', select: true, options : vOptions },
    { Comp: ALFormTextField, id: 'headline', sub : 'language', name: t('rec.community.head'), minRows: 2, maxRows: 4, check: [ fieldMax80 ] },
    { Comp: ALFormTextField, id: 'description', sub : 'language', name: t('rec.community.desc'), minRows: 6, maxRows : 10, check: [ fieldMax2048 ] },
  ]

  // TODO - translate title
  return (
    <ALMain title={ t( 'mod.communities.name' ) } >
      <ALForm elements={formElements} service='api/communities' method='create' onSuccess={successHandler} submitName={ t( 'act.create' ) } />
    </ALMain>
  )
}
