//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React from "react"

import { useTranslation } from 'react-i18next'

import {
  Card,
} from '@mui/material'

import ALPage from '../components/ALPage'

// -----

export default function ALUserAgreement() {
  const { t } = useTranslation()

  return (
    <ALPage>
      <Card sx={{ p : '1rem' }} >
        { t( 'pages.under-construction' ) }
      </Card>
    </ALPage>
  )
}
