//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import { Link as RouterLink } from 'react-router-dom'

import Link from '@mui/material/Link'

function ALLink( props ) {
    return <Link component={ RouterLink } { ...props } />
}

export {
    ALLink,
}