//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useMemo,
} from 'react'

import i18n from 'i18next'
import {
  initReactI18next,
  useTranslation,
 } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

import { enUS, es, fr, pt } from 'date-fns/locale'

import {
  useUser,
  userLanguages,
} from '../remote-state/users'

const langs_sup = [
  'en',
  'es',
  'fr',
  'pt',
]

const locales = {
  en : enUS,
  es,
  fr,
  pt,
}

//

i18n
  .use(Backend)

  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    debug: true,
    // TODO - find a way to use facet preference for language order
    fallbackLng: langs_sup,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      caches: [ 'sessionStorage' ],
    }
  })

//

function useRenderLanguages() {
  const lang = useLangCurrent()
  const user = useUser()

  return useMemo( () => { return [ lang.code, userLanguages( user ), ...langs_sup ] }, [ lang, user ] )
}

//

function useLangCurrent() {
  const { t, i18n } = useTranslation()

  const code = useMemo( () => {
    const segs = i18n.language.split( '-' )
    const lorder = segs.map( ( elem, idx ) => segs.slice( 0, idx+1 ).join( '-' ) ).reverse()

    function findcode() {
      for( let c=0; c<lorder.length; c++ ) {
        for( let l=0; l<langs_sup.length; l++ ) {
          if( lorder[ c ] === langs_sup[ l ] ) return lorder[ c ]
        }
      }
      return langs_sup[0]
    }
    return findcode()

  }, [ i18n.language ])

  return { code: code, fullcode: i18n.language, name: t( 'nativename' ) }
}

//

function useLocaleCurrent() {
  const lang = useLangCurrent()
  return locales[ lang.code ]
}

//

function useLangs( langs = langs_sup ) {
  return useMemo( () => {
    return langs.map( lang => { return { code: lang, nativeName: i18n.t( 'nativename', { lng: lang } ) } } )
  }, [ langs ])
}

function objGetLang( obj ) {
  if( ! obj ) return ''

  for( let idx=0; idx<i18n.languages.length; idx++ ) {
    if( obj[ i18n.languages[ idx ] ] ) return obj[ i18n.languages[ idx ] ]
  }

  return obj[Object.keys(obj)[0]]
}

//

function fieldTextLangs( field, langs, nodefault ) {
  if( ! field ) return ''
  const slangs = [ ...( langs || [] ), ...langs_sup ]

  for( let l=0; l<slangs.length; l++ ) {
    if( field[ slangs[ l ] ] ) return field[ slangs[ l ] ]
  }

  return nodefault ? '' : field[ Object.keys( field )[ 0 ] ]
}

//

export {
  useRenderLanguages,
  useLangCurrent,
  useLocaleCurrent,
  useLangs,
  objGetLang,
  fieldTextLangs,
}

export default i18n