//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  Link,
} from "react-router-dom"

import { useTranslation } from 'react-i18next'


import { styled } from '@mui/system'

import {
  Alert,
  AlertTitle,
  Button,
  Card,
  Box,
  Divider,
  Typography,
} from '@mui/material'

import {
  LinkedIn as LinkedInIcon,
} from '@mui/icons-material'

import {
  useAuth,
} from '../components/ALAuth'

import {
  useMobile,
} from '../components/ALScreenSizing'

import ALPage from '../components/ALPage'

import {
  ALSignInForm,
} from '../modules/auth/ALSignIn'

import { useColorModeID } from '../components/ALColorMode'

const Item = styled( Card ) ( ( {  color, theme } ) => ( {
  padding: '0.5rem',
  marginBottom: '0.5rem',
  minHeight: '2rem',
  opacity: '0.9',
  fontWeight : 'bold',
} ) )

export default function ALRoot()  {
  const mode = useColorModeID()
  const mobile = useMobile()
  const { t } = useTranslation()

  const auth = Boolean( useAuth() )

  return (
    <>
      <ALPage bgImage='bg-color-svg' alignItems='flex-start' justifyContent={ mobile ? 'center' : 'flex-end' } >
        <Box width='100%' display='flex' flexDirection='row' >
          <Box ml='auto' mr={ mobile ? 'auto' : '0' } width='100%' maxWidth={ '350px' } py='2rem' display='flex' flexDirection='column' >
            <Item sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center' }} >
              <Box
                component="img"
                py='0.5rem'
                sx={{
                  width: '90%',
                }}
                alt="Avvia Life"
                src={ `/logo_${ mode }.png` }
              />
              <Typography variant="h6" textAlign='center' my='0.5rem'>{ t( 'pages.root.tag' ) }</Typography>
            </Item>
            { ! auth &&
              <Alert severity="warning" sx={{ marginTop: '0.5rem', marginBottom: '1rem' }} ><AlertTitle>Status: Prototype</AlertTitle>Follow us to track our progress on<Button component='a' href="https://www.linkedin.com/company/avvia-life/" target='#' endIcon={ <LinkedInIcon /> } >LinkedIn</Button></Alert>
            }
            { ! auth &&
              <Item p='0' sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center' }} >
                <Box width='100%' minHeight='3rem' display='flex' alignItems='center' justifyContent='center' >
                  <Typography variant="h5" >{ t( 'mod.auth.signin' ) }</Typography>
                </Box>
                <Divider width='100%' />
                <ALSignInForm />
              </Item>
            }
            <Link to='/story'>
              <Item sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center' }} >
              { t( 'pages.story.title-read' ) }
              </Item>
            </Link>
            <Link to='/public_benefits'>
              <Item sx={{ display : 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center' }} >
              { t( 'pages.benefits.title' ) }
              </Item>
            </Link>
          </Box>
        </Box>
      </ALPage>
    </>
  )
}
