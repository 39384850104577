//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useStoreValue,
  store,
} from 'react-context-hook'

import { useTranslation } from 'react-i18next'

import useMediaQuery from '@mui/material/useMediaQuery'

import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import Brightness4Icon from '@mui/icons-material/Brightness4'

//

function useColorModeStore() {
  return useStoreValue( 'theme.colorMode', 'system' )
}

function setColorMode( mode ) {
  store.set( 'theme.colorMode' , mode )
}

//

function useColorModes() {
  const { t } = useTranslation()
  const modes = [
    { id : 'system', name: t( 'thm.system' ), icon: <Brightness4Icon /> },
    { id : 'light', name: t( 'thm.light' ), icon: <LightModeIcon /> },
    { id : 'dark', name: t( 'thm.dark' ), icon: <DarkModeIcon /> },
  ]

  return modes
}

//

function useColorMode() {
  const modeStore = useColorModeStore()
  const modes = useColorModes()
  const mode = modes.find( elem => elem.id === modeStore )

  return mode
}

//

function useColorModeID() {
  const modeStore = useColorModeStore()
  const modeQuery = useMediaQuery( '(prefers-color-scheme: light)' ) ? 'light' : 'dark'


  return modeStore === 'system' ? modeQuery : modeStore
}

//

export {
  setColorMode,
  useColorModes,
  useColorMode,
  useColorModeID,
}