//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  Routes,
  Route,
  useParams,
  Navigate,
} from 'react-router-dom'

import { capabilities } from '../../remote-state/authorization'

import {
  useCommunity,
  communityAuthz
} from '../../remote-state/communities'

import {
  AuthenticatedRoute, AuthorizedRoute,
} from '../../components/ALRoute'

import ALLayout2 from '../../components/ALLayout2'
import { useDesktop } from '../../components/ALScreenSizing'

import ALSideRoot from './ALSideRoot'
import ALSideCID from './ALSideCID'
import ALSideAdm from './ALSideAdm'

import ALMainDashboard from './ALMainDashboard'
import ALMainDiscover from './ALMainDiscover'
import ALMainCreate from './ALMainCreate'

import ALMainCIDPosts from './ALMainCIDPosts.js'
import ALMainCIDArticles from './ALMainCIDArticles.js'
import ALMainCIDAbout from './ALMainCIDAbout.js'
import ALMainCIDMembers from './ALMainCIDMembers.js'

import ALMainAdmSettings from './ALMainAdmSettings.js'
import ALMainAdmGroup from './ALMainAdmGroup.js'
import ALMainAdmGroupNew from './ALMainAdmGroupNew.js'

import AL404 from '../../pages/AL404'

export default function ALRouter() {
  const desktop = useDesktop()
  return (
    <Routes>
      <Route element={ <AuthenticatedRoute /> } >
        <Route element={ <ALLayout2 desktop={ desktop } side={ <ALSideRoot /> } /> } >
          <Route path="/" element={ desktop ? <Navigate to={ `dashboard` } /> : <ALSideRoot /> } />
          <Route path="dashboard" element={ <ALMainDashboard /> } />
          <Route path="discover" element={ <ALMainDiscover /> } />
          <Route path="create" element={ <ALMainCreate /> } />
        </Route>
        <Route path=':cid/*' element={ <ALRouterCID /> } />
      </Route>
      <Route path='*' element={ <AL404 /> } />
    </Routes>
  )
}

function ALRouterCID() {
  const desktop = useDesktop()
  const { cid } =  useParams()
  const cmty = useCommunity( cid )
  const authz = communityAuthz( cmty )
  return (
    <Routes>
      <Route element={ <AuthorizedRoute redirectPath='..'  authz={ authz } capability={ capabilities.read_info } /> } >
        <Route element={ <ALLayout2 desktop={ desktop } side={ <ALSideCID /> } /> } >
          <Route path="/" element={ desktop ? <Navigate to={ `posts` } /> : <ALSideCID /> } />
          <Route path="posts" element={ <ALMainCIDPosts /> } />
          <Route path="posts/:gid" element={ <ALMainCIDPosts /> } />
          <Route path="articles" element={ <ALMainCIDArticles /> } />
          <Route path="about" element={ <ALMainCIDAbout /> } />
          <Route path="members" element={ <ALMainCIDMembers /> } />
        </Route>

        <Route path='admin/*' element={ <ALRouterCIDAdmin /> } />
      </Route>
      <Route path='*' element={ <AL404 /> } />
    </Routes>
  )
}

function ALRouterCIDAdmin() {
  const desktop = useDesktop()
  const { cid } =  useParams()
  const cmty = useCommunity( cid )
  const authz = communityAuthz( cmty )
  return (
    <Routes>
      <Route element={ <AuthorizedRoute redirectPath='..' authz={ authz } capability={ capabilities.edit } /> } >
        <Route element={ <ALLayout2 desktop={ desktop } side={ <ALSideAdm /> } /> } >
          <Route path="/" element={ desktop ? <Navigate to={ `settings` } /> : <ALSideAdm /> } />
          <Route path="settings" element={ <ALMainAdmSettings /> } />
          <Route path="group/new" element={ <ALMainAdmGroupNew /> } />
          <Route path="group/:gid" element={ <ALMainAdmGroup /> } />
        </Route>
      </Route>
      <Route path='*' element={ <AL404 /> } />
    </Routes>
  )
}
