//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import './App.css';

import React from 'react'
import {
  useMemo,
} from 'react'

import { withStore } from 'react-context-hook'

import { useColorModeID } from './components/ALColorMode'

import {
  withAuth,
} from './components/ALAuth'

import {
  withALQueryProvider,
} from './components/ALReactQuery'

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import { useInviteCodeInit } from './modules/auth/ALSignUp'

import {
  ALHelmet,
  withHelmet,
 } from './components/ALHelmet'

import {
  Routes,
  Route,
} from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'

import {
  UnauthenticatedRoute,
} from './components/ALRoute'

import ALHeader from './components/header/ALHeader.js'

import ALRoot from './pages/ALRoot.js'
import AL404 from './pages/AL404'

import ALFoundersStory from './pages/ALFoundersStory'
import ALPublicBenefits from './pages/ALPublicBenefits'
import ALSocialContract from './pages/ALSocialContract'
import ALUserAgreement from './pages/ALUserAgreement'
import ALPrivacyPolicy from './pages/ALPrivacyPolicy'
import ALCookiesPolicy from './pages/ALCookiesPolicy'

import ALAccount from './modules/account/ALRouter.js'
import ALCommunities from './modules/communities/ALRouter.js'

import ALSignUp from './modules/auth/ALSignUp'
import ALSignIn from './modules/auth/ALSignIn'

import ALFooter from './components/footer/ALFooter.js'

//

function useCreateTheme( ) {
  const mode = useColorModeID()

  return useMemo(
    () =>
      createTheme( {
        palette: {
          mode : mode,
          background : {
            input_filled : mode === 'dark' ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.08)',
            bar :  'rgba( 0, 0, 0, 0.5 )',
            disabledBackground :  'rgba( 0, 0, 0, 0.12 )',
          },
          border : {
            input_filled : mode === 'dark' ? 'rgba(255,255,255,0.16)' : 'rgba(0,0,0,0.16)',
          },
        },
        breakpoints : {
          values : {
            xs : 0,
            sm : 600,
            md : 900,
            lg : 1200,
            xl : 1536,
            narrow : 480,
          }
        }
      } ),
    [ mode ],
  )
}

function withTheme( Component ) {
  return function (...props) {
    const theme = useCreateTheme()
    return(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Component {...props} />
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
}

function App() {
  useInviteCodeInit()

  return (
    <>
        <ALHeader />

        <main >
          <ALHelmet />
          <ScrollToTop />
          <Routes>
            <Route index element={< ALRoot/> } />

            <Route path="story" element={ <ALFoundersStory /> } />
            <Route path="public_benefits" element={ <ALPublicBenefits /> } />
            <Route path="social_contract" element={ <ALSocialContract /> } />
            <Route path="user_agreement" element={ <ALUserAgreement /> } />
            <Route path="privacy_policy" element={ <ALPrivacyPolicy /> } />
            <Route path="cookies_policy" element={ <ALCookiesPolicy /> } />

            <Route element={ <UnauthenticatedRoute /> } >
              <Route path="auth/signup" element={ <ALSignUp /> } />
              <Route path="auth/signin" element={ <ALSignIn /> } />
            </Route>

            <Route path='account/*' element={ <ALAccount /> } />
            <Route path="communities/*" element={ <ALCommunities /> } />

            <Route path='*' element={ <AL404 /> } />
          </Routes>
        </main>

        <ALFooter />
    </>
  );
}
// TODO - toggle logging only on production
export default withStore( withTheme( withALQueryProvider( withAuth( withHelmet( App ) ) ) ), {}, {logging: true})
