//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useEffect,
  useCallback,
} from 'react'

import {
   useStoreValue,
   store,
 } from 'react-context-hook'

import {
  useQueryClient,
} from 'react-query'

import {
  useNavigate,
  useLocation,
} from 'react-router-dom'

import feathersClient from '../feathersClient/feathersClient'

import {
  rsIsError,
  rsIsLoading,
} from '../remote-state/core'

import {
  useUser,
} from '../remote-state/users'

import {
  useFacet,
} from '../remote-state/facets'

import {
  ALLoading,
} from './ALLoading'

// -----

const { sessionStorage } = global

// -----

//
// Handle ReAuthentication from valid auth in local storage

function useIsReAuthenticating() {
  return useStoreValue('auth.isReAuthenticating', true)
}

function setReAuthenticating(value) {
  return store.set('auth.isReAuthenticating', value)
}

//
// Handle Login Process

function useLogin () {
  const login = useCallback( () => {
    setIsLoggingIn( true )
  }, [ ] )

  return login
}

function setIsLoggingIn( value ) {
  if( value ) store.set( 'auth.isLoggingIn', value )
  else store.delete( 'auth.isLoggingIn' )
}

function useIsLoggingIn() {
  return useStoreValue( 'auth.isLoggingIn' )
}

//
// Handle ReLogin after timed out auth creds

function useReLogin() {
  const logout = useDoLogout()
  const loginpath = useLocation().pathname

  return useCallback( () => {
    return logout( loginpath )
  }, [ logout, loginpath ] )
}

//
// Handle Logout process

function useLogout () {
  const logout = useCallback( () => {
    setIsLoggingOut( true )
  }, [ ] )

  return logout
}

function setIsLoggingOut( value ) {
  if( value ) store.set( 'auth.isLoggingOut', value )
  else store.delete( 'auth.isLoggingOut' )
}

function useIsLoggingOut() {
  return useStoreValue( 'auth.isLoggingOut', false )
}

//

function useDoLogout() {
  const queryClient = useQueryClient()

  const doLogout = useCallback( async ( loginpath ) => {
    try {
      await feathersClient.logout()
    }
    catch ( error ) {
      await feathersClient.authentication.handleError(error, 'logout')
    }
    finally {
      await feathersClient.authentication.removeAccessToken()
      queryClient.removeQueries()
      queryClient.clear()
      sessionStorage.clear()
      store.reset( {} )
      if( loginpath ) setLoginPath( loginpath )
    }
  }, [ queryClient ] )

  return doLogout
}

//
// Manage Auth records

function useAuth() {
  return useStoreValue('auth', null)
}

function setAuth(user) {
  return store.set('auth', user)
}

//
// Helpers

function useLoginPath() {
  return useStoreValue( 'auth.loginpath', '/communities' )
}

function setLoginPath( path ) {
  if( path === '/' ) return
  if( path === '/auth/signup' ) path = '/communities'

  if( path ) store.set( 'auth.loginpath', path )
  else store.delete( 'auth.loginpath' )
}

//

function useIsAuthenticated() {
  const auth = useAuth()
  const user = useUser()
  const facet = useFacet()

  return Boolean( auth ) && ! rsIsLoading( user ) && ! rsIsError( user ) && ! rsIsLoading( facet ) && ! rsIsError( facet )
}

function useIsSessionReady() {
  const auth = useAuth()
  const user = useUser()
  const facet = useFacet()

  if( ! auth ) return true

  if( ! rsIsLoading( user, true )  && ! rsIsLoading( facet, true ) ) return true

  return false
}

//

function Login() {
  const navigate = useNavigate()
  const loginpath = useLoginPath()
  const queryClient = useQueryClient()

  useEffect( () => {
    queryClient.removeQueries()
    queryClient.clear()

    feathersClient.get( 'authentication' )
    .then( auth => {
      setAuth( auth )
      setIsLoggingIn( false )
      navigate( loginpath )
      setLoginPath( null )
    }).catch( () => {} )
  }, [ navigate, loginpath, queryClient ] )

  return (
    <main>
      <ALLoading label='logging_in' />
    </main>
  )
}

//

function Reauthenticate() {
  const navigate = useNavigate()
  const loginpath = useLocation().pathname
  const queryClient = useQueryClient()

  useEffect( () => {
    queryClient.removeQueries()
    queryClient.clear()

    feathersClient.reAuthenticate()
      .then( reUser => {
        setAuth( reUser )
        if( loginpath === '/') navigate( '/communities' )
      })
      .catch( error => {
        feathersClient.authentication.handleError( error, 'authenticate' )
          .catch( () => {} )
        feathersClient.authentication.removeAccessToken()
          .catch( () => {} )
      } )
      .finally( () => {
        setReAuthenticating( false )
      })
  }, [ loginpath, navigate, queryClient ] )

  return (
    <main>
      <ALLoading label='logging_in' />
    </main>
  )
}

//

function Logout() {
  const doLogout = useDoLogout()
  const navigate = useNavigate()
  const path = useLocation().pathname

  useEffect( () => {
    if( path !== '/' ) navigate( '/' )
    else doLogout()
  }, [ doLogout, navigate, path ] )

  return (
    <main>
      <ALLoading label='logging_out' />
    </main>
  )
}

//

function withAuth( Component ) {
  return function (...props) {
    const isReAuthenticating = useIsReAuthenticating()
    const isLoggingIn = useIsLoggingIn()
    const isLoggingOut = useIsLoggingOut()
    const ready = useIsSessionReady()

    if( isLoggingIn ) return <Login />
    else if( isReAuthenticating ) return <Reauthenticate />
    else if( isLoggingOut ) return <Logout />
    else if ( ! ready ) {
      return (
        <main >
          <ALLoading label='logging_in' />
        </main>
      )
    }
    else {
      return <Component {...props} />
    }
  }
}

export {
  withAuth,

  useIsAuthenticated,

  useAuth,

  useLogin,
  useReLogin,

  useLogout,
  useIsLoggingOut,
  setLoginPath,
}