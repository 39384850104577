//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React, {
  useEffect,
  useState,
} from "react"

import {
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import _ from 'lodash'

import { useTranslation } from 'react-i18next'

import {
  useLangCurrent,
} from '../../components/ALI18N'

import {
  rsIsLoading,
  rsIsError,
} from '../../remote-state/core'

import {
  useCommunity,

  communityGroups,
  communityGroupID,
  communityGroupName,

} from '../../remote-state/communities'

import {
  useDesktop,
} from '../../components/ALScreenSizing'

import {
  ALSide,
  ALSideButton,
  ALSideList,
} from '../../components/ALSide'

import { ALHeroSide } from './ALComponents'

import ALLoading from '../../components/ALLoading'

//

export default function ALSideAdm() {
  const { t } = useTranslation()
  const desktop = useDesktop()
  const navigate = useNavigate()
  const location = useLocation()
  const lastPath = _.last( location.pathname.split( '/' ) )

  const { cid, gid } = useParams()
  const { code : lang } = useLangCurrent()

  const cmty = useCommunity( cid )
  const groups = communityGroups( cmty )

  const pathMain = `/communities/${cid}`
  const path = `/communities/${cid}/admin`

  const [ sel, setSel ] = useState('')

  useEffect( () => {
    const pathComp = location.pathname.split('/')
    if ( ! pathComp[4] && desktop ) { setSel('settings') }
    else if( desktop ) { setSel(pathComp[4]) }
    else { setSel('') }
  }, [ location, desktop ] )

  if( rsIsLoading( cmty ) ) return ( <ALSide><ALLoading /></ALSide>)
  if( rsIsError( cmty ) ) return ( <ALSide><p>t('err.error')</p></ALSide>)

  const listItems = [
    { label: t( 'mod.community.admin.settings' ), to: `${path}/settings`, selected: sel === 'settings' },
    { type: 'label', label: t( 'mod.community.admin.group', { count: 2 } ) },
    { type: 'nested', items : [
      ...( groups.map( ( group, idx ) => { return {
        label : communityGroupName( group, [ lang ], true ),
        to : `${ path }/group/${ communityGroupID( group ) }`,
        selected : communityGroupID( group ) === gid,
      } } ) ),
      { type : 'divider' },
      { label : t( 'mod.community.admin.group_new' ), selected : lastPath === 'new', to : `${ path }/group/new` }
    ] },
  ]

  return (
    <ALSide>
      <ALHeroSide cmty={ cmty } />

      <ALSideList items={listItems} />
      <ALSideButton onClick={ () => navigate( pathMain ) } label={ t( 'mod.community.admin.return' ) } variant='outlined' />
    </ALSide>
  )
}