//
// Copyright 2022 Avvia Life, All Rights Reserved
//


import {
  Link,
} from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import Divider from '@mui/material/Divider'

import Avatar from '@mui/material/Avatar'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import ALWidget from './ALWidget'

import {
  useImage,
  imageID,
  imageUrl,
} from '../remote-state/images'

//

export const useStyles = makeStyles({
  list_item_icon : {
    minWidth: 0,
  },
  side_item: props => ({
    margin: '0.25rem',
  })
})

function ALSide( { children } ) {
  return (
    <ALWidget side>
      { children }
    </ALWidget>
  )
}

function ALSideItem( { children } ) {
  const classes = useStyles()

  return (
    <Box className={classes.side_item}>
      {children}
    </Box>
  )
}

function ALSideLabel( { label } ) {
  return ( <ListItem >{ label }</ListItem> )
  /*
  const classes = useStyles()

  return (
    <Box className={classes.side_item}>
      { label }
    </Box>
  )
  */
}

function ALSideButton ( { label, onClick, to, variant, color } ) {
  return (
    <ALSideItem>
      <Button
        size='small'
        color={ color ? color : 'primary' }
        variant={ variant ? variant : 'contained' }
        onClick={ onClick }
        component={ to ? Link : 'button' }
        to={ to ? to : '' }
        fullWidth
        >
        { label }
      </Button>
    </ALSideItem>
  )
}

function ALSideListItem( { item } ) {
  const classes = useStyles()
  const img = useImage( item?.img )
  const aimg = useImage( item?.avatar?.image )

  switch( item.type ) {
    case 'label' : {
      return <ALSideLabel label={ item.label } />
    }

    case 'divider' : {
      return <ALSideItem><Divider /></ALSideItem>
    }

    case 'button' : {
      return <ALSideButton label={ item.label } to={ item.to } onClick={ item.onClick } variant={ item.variant } color={ item.color } />
    }

    case 'nested' : {
      return ( <ALSideList items={ item.items } nested /> )
    }

    case 'function' : return item.function

    case 'item' :
    default : {
      return (
        <ListItem button component={ Link } to={ item.to } selected={ item.selected } disabled={ item.disabled ? true : false } sx={{ ml: item.sub ? '0.5 rem' : '0' }}>
          {
            Boolean( imageID( img ) ) && (
              <ListItemAvatar>
                <Avatar src={ imageUrl( img ) } variant='rounded' />
              </ListItemAvatar>
            )
          }
          {
            Boolean( item.avatar ) && (
              <ListItemAvatar>
                <Avatar src={ imageUrl( aimg ) } variant={ item.avatar.variant }>{ item.avatar.initials }</Avatar>
              </ListItemAvatar>
            )
          }
          <ListItemText primary={ item.label } secondary={ item.secondary || '' } />
          <ListItemIcon className={ classes.list_item_icon }><ChevronRightIcon /></ListItemIcon>
        </ListItem>
      )
    }
  }
}

function ALSideList( { items, nested } ) {
  return (
    <List component={ nested ? 'div' : 'nav' } disablePadding={ nested } aria-label='' sx={{ ml: nested ? '1rem' : '0rem' }}>
      { items.map( (item, idx) => <ALSideListItem item={ item } key={idx} nested={ nested } /> ) }
    </List>
  )
}

export {
  ALSide,
  ALSideItem,
  ALSideButton,
  ALSideList,
  ALSideListItem,
}