//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useQuery,
  useMutation,
} from 'react-query'

import { paramsForServer } from 'feathers-hooks-common'

import axios from 'axios'

import Compressor from 'compressorjs'

import {
  ALError,
} from '../errors'

import {
  rsCheckType,
  rsInit,
} from '../remote-state/core'

import {
  useFacet,
  facetID,
} from './facets'


import {
  useALService,
} from '../components/client'

import {
  v4 as uuidv4,
} from 'uuid'
import { cloneDeep } from 'lodash'

//

function useFile( id, type = '' ) {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  const file = useQuery( [ 'file', id ], async () => {
    if( ! id ) return {}
    try {
      const fileResult = await service( 'api/files', 'get', id, paramsForServer( { facet_id } ) )
      if( fileResult ) {
        if( ! fileResult.file.type.startsWith( type ) ) return {}
        return fileResult
      }
      return {}
    }
    catch( e ) {
      return {}
    }
  } )

  return rsInit( file, 'file' )
}

function ckFile( file ) { rsCheckType( file, 'file' ) }

//

function useFileLimits( { role } ) {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  const limits = useQuery( [ 'file_limits', role ], async () => {
    if( ! role ) return {}
    return await service( 'api/files', 'find', paramsForServer( { query: { op : 'file_limits', role }, facet_id } ) )
  } )

  return rsInit( limits, 'file_limits' )
}

function ckFileLimits( fileLimits ) { rsCheckType( fileLimits, 'file_limits' ) }

function fileLimitsList( fileLimits ) {
  ckFileLimits( fileLimits )
  return fileLimits.data
}

function fileLimitsType( fileLimits, type ) {
  ckFileLimits( fileLimits )
  return ( fileLimits?.data || [] ).find( elem => elem.type === type)
}

//

function useFileCreate() {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  return useMutation( async ( { role, file, meta } ) => {
    const data = cloneDeep( meta )
    delete data.image.ratio
    const result = await service( 'api/files', 'create', {
        role,
        name : file?.name,
        size: file?.size,
        type: file?.type,
        ...data,
      }, paramsForServer( { facet_id } ) )
    return { ...result, file }
  } )
}

//

function useFileDelete() {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  return useMutation( async ( { file_id } ) => {
    const result = await service( 'api/files', 'remove', file_id, paramsForServer( { facet_id } ) )
    return result
  } )
}

//

function useFileUIPKeepAlive() {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  return useMutation( async ( { file_id } ) => {
    if( ! file_id ) throw new ALError( 'parameter.missing', { name : 'file_id' } )
    const result = await service( 'api/files', 'patch', file_id, { op: 'uip_keep_alive' }, paramsForServer( { facet_id } ) )
    return result
  } )
}

function useFileUIPFinalize() {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  return useMutation( async ( { file_id } ) => {
    if( ! file_id ) throw new ALError( 'parameter.missing', { name : 'file_id' } )
    const result = await service( 'api/files', 'patch', file_id, { op: 'uip_finalize' }, paramsForServer( { facet_id } ) )
    return result
  } )
}

function useFileUIPClear() {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  return useMutation( async ( { file_id } ) => {
    if( ! file_id ) throw new ALError( 'parameter.missing', { name : 'file_id' } )
    const result = await service( 'api/files', 'remove', file_id, paramsForServer( { facet_id } ) )
    return result
  } )
}

// FilesUploader ( class )

const uploads_max = 4
const keepAlivePeriod = 1000 * ( process.env.REACT_APP_PRODUCTION ? 300 : 30 ) // TODO - make this a config variable

class FilesUploader {
  static #all = []
  static #queued = []
  static #uploading = []
  static #uploaded = []
  static #keepalive = []

  static #keepAliveInt = undefined

  // ----- signalUpdate       // TODO - make this private

  static _signalUpdate( item, code, message, data = {} ) {
    if( ! item ) return
    item.status.code = code
    item.status.message = message
    item.status.data = data
    if( item?.onUpdate ) item.onUpdate( item.id, { ...item.status } )
  }

  // ----- signalError       // TODO - make this private

  static _signalError( item, message, data = {} ) {
    if( ! item ) return

    FilesUploader.cancel( { id : item.id, code : 'error' } )

    item.status.code = 'error'
    item.status.message = message
    item.status.data = data

    if( item?.onError ) item.onError( item.id, { ...item.status } )
  }

  // ----- clearItem          // TODO - make this private
  static _clearItem( workItem ) {
    if( workItem ) {
      const id = workItem.id
      FilesUploader.#all = FilesUploader.#all.filter( elem => elem.id !== id )
      FilesUploader.#queued = FilesUploader.#queued.filter( elem => elem.id !== id )
      FilesUploader.#uploading = FilesUploader.#uploading.filter( elem => elem.id !== id )
      FilesUploader.#uploaded = FilesUploader.#uploaded.filter( elem => elem.id !== id )
      FilesUploader.#keepalive = FilesUploader.#keepalive.filter( elem => elem.id !== id )
    }
  }

  // ----- keepAlive          // TODO - make this private
  static async _keepAlive() {
    FilesUploader.#keepalive = await FilesUploader.#keepalive.reduce( async ( pacc, item ) => {
      const acc = await pacc

      try {
        if( item.status.code !== 'accepted' ) return
        await item.doKeepAlive( item.upload_info )
        if( item.status.code === 'canceled' ) return

        acc.push( item )
      }
      catch( e ) {
        FilesUploader._clearItem( item )
        if( e.message === 'not-found' ) {
          if( item.status.code !== 'canceled' ) {
            FilesUploader._signalError( item, 'ka.upload-timed-out' )
          }
          // silently ignore if already canceled
        }
        else {
          FilesUploader._signalError( item, e.message, e.data )
        }
      }

      return acc
    }, [] )

    if( ! FilesUploader.#keepalive.length ) {
      clearInterval( FilesUploader.#keepAliveInt )
      FilesUploader.#keepAliveInt = undefined
    }
  }

  // ----- keepAliveAdd    // TODO - make this private
  static _keepAliveAdd( workItem ) {
    if( ! workItem.doKeepAlive ) {
      return
    }
    FilesUploader.#keepalive.unshift( workItem )

    if( ! FilesUploader.#keepAliveInt ) {
      FilesUploader.#keepAliveInt = setInterval( FilesUploader._keepAlive, keepAlivePeriod )
    }
  }

  // ----- uploader         // TODO - make this private
  static async _uploader( workItem ) {
    FilesUploader._signalUpdate( workItem, 'accepted', 'uploading', { progress : 0 } )

    try {
      // get upload url
      workItem.upload_info = await workItem.doCreate( { role : workItem.role, file: workItem.file, meta : workItem.meta } )
      if( workItem.status.code === 'canceled' ) return

      // add to keep alive queue
      FilesUploader._keepAliveAdd( workItem )

      // upload
      const uploadConfig = {
        onUploadProgress: ( { total, loaded } ) => {
          FilesUploader._signalUpdate( workItem, 'accepted', 'uploading', { progress : Math.floor( ( loaded / total ) / 100 ) } )
        },
        signal : workItem.abortController.signal,
      }

      const payload = new FormData()
      Object.entries( workItem.upload_info.postDetails.fields ).forEach( ( [ key, val ] ) => payload.append( key, val ) )
      payload.append( 'Content-Type', workItem.file.type)
      payload.append( 'file', workItem.file )

      await axios.post( workItem.upload_info.postDetails.url, payload, uploadConfig )
      if( workItem.status.code === 'canceled' ) return

      // Move to uploaded list
      FilesUploader.#uploading = FilesUploader.#uploading.filter( item => item.id !== workItem.id )
      FilesUploader.#uploaded.unshift( workItem )

      FilesUploader._signalUpdate( workItem, 'accepted', 'uploaded', { progress : 100 } )
      FilesUploader._updateUploader()

      await workItem.onUploaded( workItem.id, workItem.upload_info.file_id )
      if( workItem.status.code === 'canceled' ) return
    }
    catch( e ) {
      if( workItem.status.code === 'canceled' ) return
      FilesUploader._signalError( workItem, e.message, e.data )
    }
  }

  // ----- updateUploader     // TODO - make this private
  static _updateUploader() {
    if( FilesUploader.#queued.length === 0 ) return
    if( FilesUploader.#uploading.length >= uploads_max ) return

    const workItem = FilesUploader.#queued.pop()
    FilesUploader.#uploading.unshift( workItem )

    FilesUploader._uploader( workItem )
  }

  // --- printState
  static printState() {
    console.log('FilesUploader - printState')
    console.log('FilesUploader - printState - all: ', FilesUploader.#all )
    console.log('FilesUploader - printState - queued: ', FilesUploader.#queued )
    console.log('FilesUploader - printState - uploading: ', FilesUploader.#uploading )
    console.log('FilesUploader - printState - uploaded: ', FilesUploader.#uploaded )
    console.log('FilesUploader - printState - keepalive: ', FilesUploader.#keepalive )
  }

  // ----- optimize       // TODO - make this private
  static async _optimize( workItem ) {
    const fileType = workItem.file.type.split( '/', 2 )[ 0 ]
    const limits = ( workItem.fileLimits || [] ).find( elem => elem.type === fileType )

    switch( fileType ) {
      case 'image' : {
        new Compressor( workItem.file, {
          ...( limits.size_max ? { convertSize : limits.size_max } : {} ),
          ...( limits.height_max ? { maxHeight : limits.height_max } : {} ),
          ...( limits.width_max ? { maxWidth : limits.width_max } : {} ),
          success( file_new ) {
            workItem.file = file_new
            FilesUploader._signalUpdate( workItem, 'accepted', 'validating' )
            return FilesUploader._validate( workItem )
          },
          error( e ) {
            return FilesUploader._signalError( workItem, 'error.error' )
          }
        } )
        break
      }
      default: {
        FilesUploader._signalUpdate( workItem, 'accepted', 'validating' )
        FilesUploader._validate( workItem )
        break
      }
    }

  }

  // ----- validate       // TODO - make this private
  static async _validate( workItem ) {
    if( workItem.fileLimits ) {
      const fileType = workItem.file.type.split( '/', 2 )[ 0 ]
      if( ! fileType ) return FilesUploader._signalError( workItem, 'file.unknown-type', { type : workItem.file.type } )

      const limits = ( workItem.fileLimits || [] ).find( elem => elem.type === fileType )
      if( ! limits ) return FilesUploader._signalError( workItem, 'file.file-invalid-type', { types : ( workItem.fileLimits.reduce( ( acc, elem ) => [ ...acc, elem.types ] , [] ) ).join( ',' ) } )

      if( fileType === 'image' ) {
        const imgLoad = new Promise( resolve => {
          const url = URL.createObjectURL( workItem.file )
          const image = new Image()
          image.onload = () => {
            workItem.meta = { image : {} }
            workItem.meta.image.height = image.height
            workItem.meta.image.width = image.width
            workItem.meta.image.ratio = image.width / image.height
            URL.revokeObjectURL( url )
            //placeInQueue( workItem )
            resolve( true )
          }
          image.src = url
        } )

        await imgLoad
        if( workItem.status.code === 'canceled' ) return

        if( workItem.file.name.length < ( limits.name_min || 0 ) ) return FilesUploader._signalError( workItem, 'file.name-too-short', { sizeMin : limits.name_min } )
        if( workItem.file.name.length > ( limits.name_max || Infinity ) ) return FilesUploader._signalError( workItem, 'file.name-too-long', { sizeMax : limits.name_max } )

        if( workItem.file.size < ( limits.size_min || 0 ) ) return FilesUploader._signalError( workItem, 'file.size-too-small', { sizeMin : limits.size_min } )
        if( workItem.file.size > ( limits.size_max || Infinity ) ) return FilesUploader._signalError( workItem, 'file.size-too-big', { sizeMax : limits.size_max } )

        if( workItem.meta.image.height < ( limits.height_min || 0 ) ) return FilesUploader._signalError( workItem, 'image.height-too-small', { sizeMin : limits.height_min } )
        if( workItem.meta.image.height > ( limits.height_max || Infinity ) ) return FilesUploader._signalError( workItem, 'image.height-too-big', { sizeMax : limits.height_max } )

        if( workItem.meta.image.width < ( limits.width_min || 0 ) ) return FilesUploader._signalError( workItem, 'image.width-to-small', { sizeMin : limits.width_min } )
        if( workItem.meta.image.width > ( limits.width_max || Infinity ) ) return FilesUploader._signalError( workItem, 'image.width-too-big', { sizeMax : limits.width_max } )

        if( workItem.meta.image.ratio < ( limits.ratio_min || 0 ) ) return FilesUploader._signalError( workItem, 'image.ratio-too-small', { sizeMin : limits.ratio_min } )
        if( workItem.meta.image.ratio > ( limits.ratio_max || Infinity ) ) return FilesUploader._signalError( workItem, 'image.ratio-too-big', { sizeMax : limits.ratio_max } )
      }
      else {
        // TODO - support other file types
      }
    }

    FilesUploader.#queued.unshift( workItem )
    FilesUploader._signalUpdate( workItem, 'accepted', 'queued' )
    FilesUploader._updateUploader()
  }

  // ----- enqueue
  static enqueue( { role, file, fileLimits, id, doCreate, doDelete, doKeepAlive, onUpdate, onUploaded, onError } ) {
    if( ! file ) throw new Error( 'parameter-missing,file' )
    if( ! doCreate ) throw new Error( 'parameter-missing,doCreate' )
    if( ! doDelete ) throw new Error( 'parameter-missing,doDelete' )
    if( ! onUploaded ) throw new Error( 'parameter-missing,onUploaded' )

    const workItem = {
      role,
      file,
      fileLimits,
      id : id ? id : `${ file.name }-${ uuidv4() }`,
      doCreate,
      doKeepAlive,
      doDelete,
      onUpdate,
      onUploaded,
      onError,
      status : {
        code : 'ok',
        message : 'accepted',
        data : {},
      },
      abortController : new AbortController(),
    }
    FilesUploader.#all.unshift( workItem )
    FilesUploader._signalUpdate( workItem, 'accepted', 'optimizing' )
    FilesUploader._optimize( workItem )

    return workItem.id
  }

  // ----- finalize

  static finalize( { id } ) {
    if( ! id ) return

    const workItem = FilesUploader.#uploaded.find( elem => elem.id === id )

    if( ! workItem ) throw new ALError( 'not-found', { id } )

    FilesUploader._signalUpdate( workItem, 'ok', 'finalized' )

    FilesUploader._clearItem( workItem )
  }

  // ----- cancel
  static cancel( { id, code = 'canceled', signal = false } ) {
    if( ! id ) return

    const workItem = FilesUploader.#all.find( elem => elem.id === id )

    if( workItem ) {
      FilesUploader._clearItem( workItem )

      workItem.abortController.abort()

      if( workItem.upload_info ) {
        workItem.doDelete( workItem.upload_info )
          .then( removed => {
            if( removed && signal ) FilesUploader._signalUpdate( workItem, code, 'canceled' )
          } )
      }
    }

    FilesUploader._updateUploader()
  }
}

export {
  useFile,
  ckFile,

  useFileCreate,
  useFileDelete,

  useFileUIPKeepAlive,
  useFileUIPFinalize,
  useFileUIPClear,

  useFileLimits,
  ckFileLimits,
  fileLimitsList,
  fileLimitsType,

  FilesUploader,
}