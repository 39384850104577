//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React from "react"

import { useTranslation } from "react-i18next"

import ReactMarkdown from 'react-markdown'

import { styled } from '@mui/system'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import Typography from '@mui/material/Typography'

import ALPage from '../components/ALPage'

// -----

const ALCardContent = styled( Card )( {
  textAlign: 'left',
  padding: '0 1rem',
  maxWidth: '600px',
} )

const ALSpacer = styled( Box )( {
  paddingBottom : '2rem',
})

function ALCardMarkdown( { children } ) {
  return <ALCardContent><ReactMarkdown>{ children }</ReactMarkdown></ALCardContent>
}

// -----

export default function ALFoundersStory() {
  const { t } = useTranslation()

  return (
    <>
    <ALPage>
      <Box sx={{ display: 'flex', flexDirection : 'column', alignItems: 'center' }} >
        <Typography component='h2' variant='h4' mt='1rem' >{ t( 'pages.benefits.mission-title' ) }</Typography>

        <ALCardMarkdown>{ t('pages.benefits.mission' ) }</ALCardMarkdown>

        <ALSpacer />
        <Typography component='h2' variant='h4' mt='1rem' >{ t( 'pages.benefits.inc-title' ) }</Typography>

        <ALCardMarkdown>{ t( 'pages.benefits.inc' ) }</ALCardMarkdown>

        <ALSpacer />
        <Typography component='h2' variant='h4' mt='1rem' >{ t( 'pages.benefits.benefits-title' ) }</Typography>

        <ALCardMarkdown>{ t( 'pages.benefits.benefit-1' ) }</ALCardMarkdown>
        <ALSpacer />
        <ALCardMarkdown>{ t( 'pages.benefits.benefit-2' ) }</ALCardMarkdown>
        <ALSpacer />
        <ALCardMarkdown>{ t( 'pages.benefits.benefit-3' ) }</ALCardMarkdown>
        <ALSpacer />
        <ALCardMarkdown>{ t( 'pages.benefits.benefit-4' ) }</ALCardMarkdown>
        <ALSpacer />
        <ALCardMarkdown>{ t( 'pages.benefits.benefit-5' ) }</ALCardMarkdown>

        <ALSpacer />
        <Typography component='h2' variant='h4' mt='1rem' >{ t( 'pages.benefits.ammendment-title' ) }</Typography>

        <ALCardMarkdown>{ t( 'pages.benefits.ammendment' ) }</ALCardMarkdown>

        <ALSpacer />
      </Box>
    </ALPage>
    </>  )
}
