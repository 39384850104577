//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  Link,
} from "react-router-dom"

import {
  Divider,
  Button,
} from '@mui/material'

import { storage } from '../../feathersClient/feathersClient'

import ALForm, {
  fieldRequired,
  fieldEmail,
  ALFormTextField,
  ALFormCheckbox,
} from '../../components/form/ALForm'

import ALPage from '../../components/ALPage'

import {
  useLogin,
} from '../../components/ALAuth'

import {
  ALAuthCard,
  ALAuthCardHead,
  //ALAuthCardContent,
  //ALAuthCardActions,
} from '../../components/ALAuthCard/ALAuthCard'

import { useTranslation } from 'react-i18next'

//

export default function ALSignIn() {
  const { t } = useTranslation()

  return (
    <ALPage>
      <ALAuthCard >
        <ALAuthCardHead >{ t( 'mod.auth.signin' ) }</ALAuthCardHead>

        <Divider light />

        <ALSignInForm />
      </ALAuthCard>
    </ALPage>
  )
}
function ALSignInForm() {
  const { t } = useTranslation()
  const login = useLogin()

  const formElements = [
    { Comp: ALFormTextField, id: 'email', name: t( 'mod.auth.email' ), type: 'email', autoFocus: true, check: [ fieldRequired, fieldEmail ] },
    { Comp: ALFormTextField, id: 'password', name: t( 'mod.auth.password' ), type: 'password', check: [ fieldRequired ] },
    { Comp: ALFormCheckbox, id: 'persist', name: t( 'mod.auth.persist' ), local: true },
  ]

  function onSubmit( values ) {
    const persist =  values?.persist
    storage.setShouldPersist( persist )

    return values
  }

  const buttonSecondary = (
    <Button
      component={Link}
      variant='outlined'
      to="/auth/signup"
    >
      { t( 'act.signup' ) }
    </Button>
  )

  return (
    <ALForm
      elements={ formElements }
      method='authenticate'
      onSubmit={ onSubmit }
      onSuccess={ login }
      submitName={ t('act.signin') }
      buttonSecondary={ buttonSecondary }
    />
  )
}

export {
  ALSignInForm,
}