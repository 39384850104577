//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  Link,
  useLocation,
} from 'react-router-dom'

import {
  useStore,
  store,
} from 'react-context-hook'

import makeStyles from '@mui/styles/makeStyles';

import {
  useDesktop,
} from '../components/ALScreenSizing'

import {
  Alert,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,

  Tab,
} from '@mui/material'

import {
  TabContext,
  TabList,
  TabPanel
} from '@mui/lab'

import {
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material'

import ALWidget from './ALWidget'

//

function setMainTab( contextID, tabID ) {
  store.set( contextID + '.sel', tabID )
}

//

export const useStyles = makeStyles({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'noWrap',
    justifyContent: 'flex-start',
  },

  list: {
    padding: 0,
  },
  list_item: {
    padding: 0,
  },
  list_icon: {
    minWidth: 0,
  },

  tab : {
    minWidth: '0rem',
  },
  tab_panel : {
    padding: '0.25rem',
  },
})

function ALMainTabs ( { contextID, tabs, ariaLabel } ) {
  const classes = useStyles()

  const [ sel, setSel ] = useStore( contextID + '.sel', tabs[0].id)

  const handleChange = (event, newValue) => {
    setSel(newValue)
  }

  return (
    <TabContext value={sel} >
      <TabList
        variant="scrollable"
        scrollButtons="auto"
        value={sel}
        indicatorColor='primary'
        textColor='primary'
        onChange={handleChange}
        aria-label={ariaLabel}
      >
        { tabs.map( tab => {
          const { id, label } = tab
          return (
            <Tab label={ label } value={ id } className={ classes.tab } key={ id } />
          )
        } ) }
      </TabList>

      <Divider light />

      { tabs.map( tab => {
        const { id, label, Comp, ...props } = tab
        return (
          <ALMainTabsPanel value={ id } key={ id } >
            <Comp { ...props } />
          </ALMainTabsPanel>
        )
      } ) }

    </TabContext>
  )
}

function ALMainTabsPanel ( { children, ...props } ) {
  const classes = useStyles()

  return (
    <TabPanel className={classes.tab_panel} { ...props } >
      { children }
    </TabPanel>
  )
}

function ALMainMenu( { title, backTo } ) {
  const classes = useStyles()

  const location = useLocation()
  const pathComp = location.pathname.split('/')
  const to = backTo ?? ( pathComp.slice(0,pathComp.length-1).join('/') || '' )

  return (
    <ALWidget title>
      <List component='nav' aria-label='' className={classes.list}>
        <ListItem button component={ Link } to={ to } key={ 1 } className={ classes.list } >
          <ListItemIcon className={classes.list_item_icon}><ChevronLeftIcon /></ListItemIcon>
          <ListItemText primary={ title || ''} primaryTypographyProps={{noWrap:true, variant:'subtitle1'}} />
        </ListItem>
      </List>
    </ALWidget>
  )
}

function ALMain( { title, nocard, backTo, alerts, children } ) {
  const classes = useStyles()
  const desktop = useDesktop()

  const showMenu = ! desktop && title

  return (
    <Box className={ classes.flex }>
      { showMenu && <ALMainMenu title={title} backTo={ backTo } /> }

      { alerts && alerts.map( ( alert, idx ) => <Alert severity={ alert.severity } sx={{ mt: '1rem', mr: '1rem' }} key={ idx } >{ alert.message }</Alert> ) }

      <ALWidget main nocard={ nocard } >
        { children }
      </ALWidget>
    </Box>
  )
}

export {
  setMainTab,
  ALMain,
  ALMainTabs,
}