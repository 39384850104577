//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React from "react"

import { useTranslation } from "react-i18next"

import {
  useMobile,
} from '../ALScreenSizing'

import {
  ALPageSpacerToolbar,
} from '../ALPage'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";

import { ALLink } from '../ALLink'

export default function ALFooter() {
  const mobile = useMobile()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Paper
        component='footer'
        variant='outlined'
      >
        <Typography variant='caption' >
          <Box>
            <Box sx={{ display: 'flex', flexDirect: 'row', flexWrap: 'wrap '}}>
              <Box sx={{ display: 'flex', flexDirection : 'column', px: '1rem' }} >
                <Box>{ t( 'pages.aboutus' ) }</Box>
                <ALLink to='/story'>{ t( 'pages.story.title' ) }</ALLink>
                <ALLink to='/public_benefits'>{ t( 'pages.benefits.title' ) }</ALLink>
              </Box>
              <Box sx={{ display: 'flex', flexDirection : 'column', px: '1rem'  }} >
                <Box>{ t( 'pages.agreements' ) }</Box>
                <ALLink to='/social_contract'>{ t( 'pages.social-contract.title' ) }</ALLink>
                <ALLink to='/user_agreement'>{ t( 'pages.user-agreement.title' ) }</ALLink>
                <ALLink to='/privacy_policy'>{ t( 'pages.privacy-policy.title' ) }</ALLink>
                <ALLink to='/cookies_policy'>{ t( 'pages.cookies-policy.title' ) }</ALLink>
              </Box>
            </Box>
            <Box>
              { t( 'copyright', { year : new Date().getFullYear() } ) }
            </Box>
            { mobile && <ALPageSpacerToolbar /> }
          </Box>
        </Typography>
      </Paper>
    </React.Fragment>
  )
}
