//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useState,
} from 'react'

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'

import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'

import {
  ALMain,
} from '../../components/ALMain'

// TODO - translate title
// TODO - track and pass in group_list


export default function ALMainDashboard() {
  const [ str, setStr ] = useState( 's' )

  function changeStr( e, newValue ) {
    setStr( newValue )
  }

  const data = [
    { grouping : 'm', str : 's', item : <span>@QQ mentioned you in a post<Button disabled variant='text'>visit</Button></span> },
    { grouping : 'r', str : 's', item : <span>@cdel has replied to your post in QQ's Place<Button disabled variant='text'>visit</Button></span> },
    { grouping : 'r', str : 'w', item : <span>@karen has replied to your post in QQ's Place<Button disabled variant='text'>visit</Button></span> },
    { grouping : 'o', str : 's', item : <span>@cdel posted in QQ's Place<Button disabled variant='text'>visit</Button></span> },
  ]

  const mentions = data.filter( item => item.grouping === 'm' && item.str === str )
  const replies = data.filter( item => item.grouping === 'r' && item.str === str )
  const other = data.filter( item => item.grouping === 'o' && item.str === str )

  console.log('mentions: ', mentions)
  console.log('replies: ', replies)
  console.log('other: ', other)

  return (
    <ALMain title='Community Dashboard' nocard >
      <Card>
        <CardContent>
          <CardHeader title='Dashboard Placeholder' />
        </CardContent>
      </Card>

      <Card sx={{ mt: '1rem' }} >
        <CardHeader title='Nurture Communities' />
        <CardContent>
          <ul>
            <li>You haven't engaged with <Button disabled variant='text'>QQ's Place</Button> in a while <Button disabled variant='text'>silence</Button></li>
            <li>Your engagement level with <Button disabled variant='text'>Balanced Blends</Button> is falling <Button disabled variant='text'>silence</Button></li>
          </ul>
        </CardContent>
      </Card>

      <Card sx={{ mt: '1rem' }} >
        <CardContent>
        <CardHeader title='Nurture Community Connections' />
          <Tabs value={ str } onChange={ changeStr } variant="scrollable" scrollButtons="auto" >
            <Tab label='Engaged' value='e' />
            <Tab label='Strong' value='s' />
            <Tab label='Weak' value='w' />
            <Tab label='Distant' value='d' />
          </Tabs>
          { !! mentions.length &&
            <Accordion defaultExpanded >
              <AccordionSummary expandIcon={ <ExpandMoreIcon /> } >
                { 'Mentions: ' + mentions.length }
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  { mentions.map( ( item, idx ) => <li key={ idx }>{ item.item } </li> ) }
                </ul>
              </AccordionDetails>
            </Accordion>
          }
          { !! replies.length &&
            <Accordion>
              <AccordionSummary expandIcon={ <ExpandMoreIcon /> } >
                { 'Replies: ' + replies.length }
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  { replies.map( ( item, idx ) => <li key={ idx }>{ item.item } </li> ) }
                </ul>
              </AccordionDetails>
            </Accordion>
          }
          { !! mentions.length &&
            <Accordion>
              <AccordionSummary expandIcon={ <ExpandMoreIcon /> } >
                { 'Other Activity: ' + other.length }
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  { other.map( ( item, idx ) => <li key={ idx }>{ item.item } </li> ) }
                </ul>
              </AccordionDetails>
            </Accordion>
          }
        </CardContent>
      </Card>

    </ALMain>
  )
}