//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useEffect,
  useState,
  forwardRef,
} from 'react'

import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  RichUtils,
  ContentState,
} from 'draft-js'
import 'draft-js/dist/Draft.css'

import Editor from '@draft-js-plugins/editor'
import createLinkifyPlugin, { extractLinks } from '@draft-js-plugins/linkify'

import makeStyles from '@mui/styles/makeStyles';


import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'

//

const linkifyPlugin = createLinkifyPlugin( {
  target : '_blank',
  customExtractLinks : text => extractLinks( text )
} )
const plugins = [ linkifyPlugin ]

//

const useStyles = makeStyles( (theme) => ({
  draft_rw : {
    width: '100%',
    padding: '18.5px 14px',
    maxHeight: '20rem',
    overflowY: 'scroll',
  },
}))

//

function editorValue( value ) {
  if( value?.draftjs ) {
    return {
      text : value.text,
      draftjs : EditorState.createWithContent( convertFromRaw( value.draftjs ) ),
    }
  }
  else if( value?.text ) {
    return {
      text : value.text,
      draftjs :  EditorState.createWithContent( ContentState.createFromText( value.text ) ),
    }
  }
  else {
    return {
      text : '',
      draftjs :  EditorState.createWithContent( ContentState.createFromText( value ?? '' ) ),
    }
  }
}

function ALDraftEditor( { id, value, setState } ) {

  return (
    <>
      {   setState && <ALEditorDraftRW id={ id } value={ value } setState={ setState } /> }
      { ! setState && <ALEditorDraftRO id={ id } value={ value } /> }
    </>
  )
}

function ALEditor( { id, value, setState } ) {
  // TODO - disambiguate editor value.type
  return (
    <ALDraftEditor id={ id } value={ value } setState={ setState } />
  )
}

const ALEditorDraftRW = forwardRef( ( { value, setState, sid = null }, ref ) => {
  const [ ssid, setssid ] = useState( undefined )
  const [ editorState, setEditorState ] = useState( EditorState.createEmpty() )
  useEffect( () => {
    if( sid !== ssid ) {
      setEditorState( editorValue( value ).draftjs )
      setssid( sid )
    }
  }, [ sid, ssid, value ] )
  const classes = useStyles()

  const handleKeyCommand = ( command, editorState ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if( newState ) {
      setEditorState(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  const handleChange = (e) => {
    const content = e.getCurrentContent()
    setState( content.hasText()
      ? {
        text: content.getPlainText(),
        draftjs: convertToRaw( content ),
      }
      : null
    )
    setEditorState(e)
  }

  return (
    <div className={classes.draft_rw}>
      <Editor editorState={ editorState } onChange={ handleChange } handleKeyCommand={ handleKeyCommand } plugins={ plugins }  />
    </div>
  )
} )

function ALEditorDraftRO( { value } ) {
  const [ editorState, setEditorState ] = useState( editorValue( value ).draftjs )
  return (
    <Editor editorState={ editorState } onChange={ newState => setEditorState( newState ) } readOnly={ true } plugins={ plugins } />
  )
}

function ALEditorField( { id, name, label, value, sid, error, helperText, minRows, check, state, setState } ) {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        value={value}
        label={label}
        inputComponent={ALEditorDraftRW}
        inputProps={ {
            value,
            setState,
            sid,
          } }
      />
      <FormHelperText error={ error } >{helperText}</FormHelperText>
    </FormControl>
  )
}

//

function useFieldEditorMax( max ) {
  return ( state, id, setError, sub ) => {
    const ok = sub
    ? Object.keys( state.values[ id ] ?? {} ).reduce( ( acc, sub ) => acc && ( state.values[ id ][ sub ].text.length <= max ) , true )
    : state.values[ id ].text.length <= max

    if( ! ok && setError ) {
      if( sub ) state.errors[ id ] = [ 'err.avail_char', { count : 'TO BE FIXED' } ] // #259 - how to report multiple errors on a single line?
      else state.errors[ id ] = [ 'err.avail_char', { count: ( max - state.values[id].length ) } ]
    }

    return ok
  }
}


//

export {
  ALEditor,
  ALEditorField,
  useFieldEditorMax,
}
