//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import makeStyles from '@mui/styles/makeStyles';

 import {
  lightGreen,
} from '@mui/material/colors'


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { useColorModeID } from '../ALColorMode'

//

const useStyles = makeStyles((theme) => ({
  auth_card: {
    maxWidth: 400,
    width: '100%',
    marginTop: '1rem ',
    marginBottom: '1rem ',
  },
  auth_card_content: {
    padding: '1rem 1rem 0.5rem 1rem',
  },
  auth_card_actions : {
    padding: '0.5rem 1rem 0.5rem 1rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  }
}));


export function ALAuthCard (props) {
  const classes = useStyles()
    return (
      <Card className={classes.auth_card}>
        {props.children}
      </Card>
    )
}

export function ALAuthCardHead (props) {
  const mode = useColorModeID()

  return (
    <CardContent sx={ {
      padding: '1rem',
      backgroundColor: lightGreen[ mode === 'light' ? 50 : 600 ],
      textAlign: 'center',
      } }
    >
      {props.children}
    </CardContent>
  )
}

export function ALAuthCardContent (props) {
  const classes = useStyles()
    return (
      <CardContent className={classes.auth_card_content}>
        {props.children}
      </CardContent>
    )
}

export function ALAuthCardActions (props) {
  const classes = useStyles()
    return (
      <CardContent className={classes.auth_card_actions}>
        {props.children}
      </CardContent>
    )
}

