//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import { useTranslation } from 'react-i18next'

import {
  rsIsLoading,
} from '../../remote-state/core'

import {
  useUser,
  useUserReload,

  userID,

  userIdentityFirst,
  userIdentityLast,
} from '../../remote-state/users'

import {
  ALMain,
} from '../../components/ALMain'

import {
  fieldRequired,
  useFieldStringMax,
  ALFormTextField,
} from '../../components/form/ALForm'

import {
  ALSettings,
} from '../../components/ALSettings'

import ALLoading from '../../components/ALLoading'

//

export default function ALMainIdentity() {
  const { t } = useTranslation()
  const user = useUser()
  const reloadUser = useUserReload()
  const fieldMax64 = useFieldStringMax( 64 )
  const onSuccess = () => { reloadUser() }

  if( rsIsLoading( user ) ) {
    return (
      <ALMain title='Account - Identity' >
        <ALLoading />
      </ALMain>
    )
  }

  const settings = [
    {
      label : t( 'rec.user.first' ),
      margin: 'none',
      value : userIdentityFirst( user ),
      service : 'api/users',
      oid : userID( user ),
      op : 'identity_first',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'first', value: userIdentityFirst( user ), type: 'text', check: [ fieldRequired, fieldMax64 ] },
      ],
    },
    {
      label : t( 'rec.user.last' ),
      margin: 'none',
      value : userIdentityLast( user ),
      service : 'api/users',
      oid : userID( user ),
      op : 'identity_last',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'last', value: userIdentityLast( user ), type: 'text', check: [ fieldRequired, fieldMax64 ] },
      ],
    },
  ]

  return (
    <ALMain title='Account - Identity' >
      <ALSettings items={settings} />
    </ALMain>
  )
}