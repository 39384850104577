//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import { useTranslation } from 'react-i18next'

import { styled } from '@mui/system'

import {
  Box,
  CircularProgress,
} from '@mui/material'

import ALPage from './ALPage'

const LoadContainer = styled( Box )( {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1rem',
  flexDirection: 'column',
  padding: '0 1rem',
} )

function ALLoadingPlain() {
  return (
    <LoadContainer>
      <Box>
        <CircularProgress />
      </Box>
    </LoadContainer>
  )
}

function ALLoading( { label='loading' } ){
  const { t } = useTranslation()

  return (
    <LoadContainer>
      <Box>
        <CircularProgress />
      </Box>
      <Box>
        { t( `status.loading.${ label }` ) }
      </Box>
    </LoadContainer>
  )
}

function ALLoadingPage() {

  return (
    <ALPage>
      <ALLoading />
    </ALPage>
  )
}

export default ALLoading;

export {
  ALLoading,
  ALLoadingPlain,
  ALLoadingPage,
}
