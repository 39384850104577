//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useCallback,
} from 'react'

import {
  useQuery,
  useQueryClient,
  useInfiniteQuery,
  useMutation,
} from 'react-query'

import { paramsForServer } from 'feathers-hooks-common'

import {
  rsInit,
  rsCheckType,
  rsIsLoading,
  rsIsError,

  rsiInit,
  rsiCheckType,
} from '../remote-state/core'

import {
  useALService,
} from '../components/client'

import {
  useFacet,
  facetID,
} from './facets'

import {
  objGetLang,
} from '../components/ALI18N'

//

function useReply( pids, id ) {
  const service = useALService()
  const facet_id = facetID( useFacet() )

  const reply = useQuery( [ facet_id, 'reply', ...( pids || [] ), id, 'data' ], async () => {
    if( !id ) return {}
    return await service( 'api/replies', 'get', id, paramsForServer( { facet_id } ) )
  } )

  return rsInit( reply, 'reply' )
}

function ckReply( reply ) {
  rsCheckType( reply, 'reply')
}

function repliesReload( facet_id, queryClient, pids, id ) {
  return queryClient.invalidateQueries( [ facet_id, 'reply', ...( pids || [] ), id ], { refetchInactive: true, predicate: query  => { return true } } )
  //return queryClient.invalidateQueries( [ 'reply', ...( pids || [] ), id ] )
}

function useRepliesReload( pids, id ) {
  const queryClient = useQueryClient()
  const facet_id = facetID( useFacet() )

  return useCallback( () => {
    return repliesReload( facet_id, queryClient, pids, id )
  }, [ facet_id, queryClient, pids, id ] )
}

//

function usePatch( reply, onSettled, op ) {
  ckReply( reply )
  const service = useALService()
  const facet_id = facetID( useFacet() )

  return useMutation( async ( data ) => {
    return await service( 'api/replies', 'patch', replyID( reply ), { ...( data || {} ), op : op }, paramsForServer( { facet_id } ) )
  },
  {
    mutationKey: [ 'reply', ...( replyParents( reply ) || [] ), replyID( reply ), 'patch' ],
    onSettled: onSettled,
  } )
}

function useReplyEdit( reply, onSettled ) {
  return usePatch( reply, onSettled, 'edit' )
}

function useReplyReport( reply, onSettled ) {
  return usePatch( reply, onSettled, 'report' )
}

function useReplyDelete( reply, onSettled ) {
  return usePatch( reply, onSettled, 'delete' )
}

function useReplyUndelete( reply, onSettled ) {
  return usePatch( reply, onSettled, 'undelete' )
}

function useReplyLock( reply, onSettled ) {
  return usePatch( reply, onSettled, 'lock' )
}

function useReplyUnlock( reply, onSettled ) {
  return usePatch( reply, onSettled, 'unlock' )
}

function useReplyHide( reply, onSettled ) {
  return usePatch( reply, onSettled, 'hide' )
}

function useReplyUnhide( reply, onSettled ) {
  return usePatch( reply, onSettled, 'unhide' )
}

//

function replyID( reply ) {
  ckReply( reply )
  return reply.data?.id
}

function replySID( reply ) {
  ckReply( reply )
  return reply.data?.sid
}

function replyParents( reply ) {
  ckReply( reply )
  return reply.data?.parent_ids
}

function replyCommunityID( reply ) {
  ckReply( reply )
  return reply.data?.community_id
}

//

function replyCreatedAt( reply ) {
  ckReply( reply )
  return reply.data?.created?.at
}

function replyCreatedBy( reply ) {
  ckReply( reply )
  return reply.data?.created?.by
}

function replyHistory( reply ) {
  ckReply( reply )
  return reply.data?.history
}

//
function replyAuthz( reply ) {
  ckReply( reply )
  return reply.data?.authz ?? {}
}

//

function replyBody( reply ) {
  ckReply( reply )
  return objGetLang( reply.data?.body )
}

//

function replyBodies( reply ) {
  ckReply( reply )
  const bodies = reply?.data?.body ?? {}

  return Object.keys( bodies ).reduce( ( acc, key ) => { acc[ key ] = bodies[ key ].text; return acc } , {} )
}

function replyImages( reply ) {
  ckReply( reply )
  return reply.data?.images || []
}

//

function replyStatsReplies( reply ) {
  ckReply( reply )
  return reply.data?.stats?.replies || 0
}

// -----

function useRepliesTo( pids, id, sort ) {
  const service = useALService()
  const facet_id = facetID( useFacet() )
  const lim = undefined // TODO - consider allowing users to specify

  const results = useInfiniteQuery( [ facet_id, 'replies to', ...( pids || [] ), id, 'replies' ] , async ( { pageParam = {} } ) => {
    const result = await service( 'api/replies', 'find', paramsForServer( {
      query: pageParam.state
        ? { state : pageParam.state }
        : { parent_id: id, sort: sort, lim: lim },
      facet_id } ) )
    return result
  },
  {
    getNextPageParam: lastPage => lastPage.has_more
    ? ( { state: lastPage.state } )
      : undefined
  })
  return rsiInit( results, 'replies' )
}

function ckRepliesTo( reply ) {
  rsiCheckType( reply, 'replies')
}

function repliesToReload( facet_id, queryClient, pids, id ) {
  //return queryClient.removeQueries(      [ facet_id, 'replies to', ...( pids || [] ), id ], { refetchInactive: true, predicate: query  => { return true } } )
  return queryClient.removeQueries(      [ facet_id, 'replies to', ...( pids || [] ), id ] )
}

//

function useRepliesRemove( pids, id ) {
  const queryClient = useQueryClient()
  const facet_id = facetID( useFacet() )

  return useCallback( () => {
    return queryClient.removeQueries( [ facet_id, 'replies to', ...( pids || [] ), id ] )
  }, [ facet_id, queryClient, pids, id ] )
}

//

function useRepliesRefreshAll( pids, id ) {
  const queryClient = useQueryClient()
  const facet_id = facetID( useFacet() )

  return useCallback( () => {
    repliesToReload( facet_id, queryClient, pids, id )
    repliesReload( facet_id, queryClient, pids, id )
  }, [ facet_id, queryClient, pids, id ] )
}

//

function repliesIDs( replies ) {
  ckRepliesTo( replies )

  if( rsIsLoading( replies ) || rsIsError( replies ) ) return []

  const ids = ( replies.data.pages || [] ).reduce( ( acc, page ) => { return [ ...acc, ...( page.ids || [] ) ] }, [] )

  return ids
}

function repliesHasMore( replies ) {
  ckRepliesTo( replies )

  if( rsIsLoading( replies ) || rsIsError( replies ) ) return false

  return replies.data.pages[ replies.data.pages.length -1 ].has_more
}

//

export {
  useReply,
  useRepliesReload,

  useReplyEdit,
  useReplyReport,
  useReplyDelete,
  useReplyUndelete,
  useReplyLock,
  useReplyUnlock,
  useReplyHide,
  useReplyUnhide,

  replyID,
  replySID,
  replyParents,

  replyCommunityID,

  replyCreatedAt,
  replyCreatedBy,
  replyHistory,

  replyAuthz,

  replyBody,
  replyBodies,
  replyImages,

  replyStatsReplies,

  useRepliesTo,

  useRepliesRemove,
  useRepliesRefreshAll,

  repliesIDs,
  repliesHasMore,
}