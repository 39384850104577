//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import React from "react"
import {
  useState,
} from 'react'

import {
  Link as RouterLink,
  useLocation,
} from "react-router-dom"

import {
  useTranslation
} from 'react-i18next'

import makeStyles from '@mui/styles/makeStyles';

import {
  useMobile,
} from '../ALScreenSizing'

import {
  blueGrey,
} from '@mui/material/colors'

import Container from '@mui/material/Container'

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import Avatar from '@mui/material/Avatar'

import Divider from '@mui/material/Divider'

import {
  useImage,
  imageUrl,
} from '../../remote-state/images'

import {
  useUser,
  userFacetIDs,
} from '../../remote-state/users'

import {
  useFacetCurrentID,
  useFacetCurrentIDSet,
  useFacet,
  facetDescription,
  facetInitials_1,
  facetAvatar,
} from '../../remote-state/facets'

import {
  useIsAuthenticated,
  useLogout,
} from "../ALAuth"

import useNavItems from './useNavItems'

import i18n from '../../components/ALI18N'
import {
  useLangs,
  useLangCurrent,
} from '../../components/ALI18N'

import {
  setColorMode,
  useColorMode,
  useColorModes,
 } from '../ALColorMode'

//


function extractLinkProps(props) {
  let linkProps = {}

  switch (props.type) {
    case 'Link':
      linkProps.component = RouterLink
      linkProps.to = props.to
      break

    case 'Button':
      linkProps.onClick = props.onClick
      break

    default:
  }

  return linkProps
}

const useStylesNav = makeStyles({
  navTop: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    zIndex: '1100',
    borderBottom: '1px solid',
    borderColor: `${blueGrey[50]} !important` ,
  },
  navBottom: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '1100',
    borderTop: '1px solid',
    borderColor: `${blueGrey[50]} !important` ,
  },
  bottomNavigationAction: {
    minWidth: '4rem',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
});

//

function ALMenuLanguage( { ...props } ) {
  const { code } = useLangCurrent()
  const [ anchorEl, setAnchorEl ] = useState( null )
  const open = Boolean( anchorEl )

  const langs = useLangs()

  function handleOpen( event ) {
    setAnchorEl( event.currentTarget )
  }

  function handleClose( event ) {
    setAnchorEl( null )
  }

  function handleClick( lang ) {
    if( lang ) i18n.changeLanguage( lang )

    handleClose()
  }

  return (
    <>
      <BottomNavigationAction { ...props } onClick={ handleOpen } />
      <Menu
        id='menu-language'
        anchorEl={ anchorEl }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={ open }
        onClose={ handleClose }
      >
        { langs.map( (elem, idx) => ( <MenuItem selected={ code === elem.code } key={ idx } onClick={ () => handleClick( elem.code ) }>{ elem.nativeName }</MenuItem> ) ) }
      </Menu>
     </>
  )
}

//

function ALMenuColorMode( { ...props } ) {
  const mode = useColorMode()
  const [ anchorEl, setAnchorEl ] = useState( null )
  const open = Boolean( anchorEl )

  const modes = useColorModes()

  function handleOpen( event ) {
    setAnchorEl( event.currentTarget )
  }

  function handleClose( event ) {
    setAnchorEl( null )
  }

  function handleClick( mode ) {
    if( mode ) setColorMode( mode )

    handleClose()
  }

  return (
    <>
      <BottomNavigationAction { ...props } onClick={ handleOpen } />
      <Menu
        id='menu-theme'
        anchorEl={ anchorEl }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={ open }
        onClose={ handleClose }
      >
        { modes.map( ( elem, idx ) => ( <MenuItem selected={ mode.id === elem.id } key={ idx } onClick={ () => handleClick( elem.id ) }><ListItemIcon>{ elem.icon }</ListItemIcon>{ elem.name }</MenuItem> ) ) }
      </Menu>
     </>
  )
}

//


const useStyles = makeStyles({
  avatar_sm: {
    width: '1.5rem',
    height: '1.5rem',
    fontSize: '1rem',
  },
})

function MAAvatar( { facet_id } ){
  const classes = useStyles()
  const facet = useFacet( facet_id )
  const avatar = useImage( facetAvatar( facet ) )
  const initial = facetInitials_1( facet )

  return <Avatar variant='rounded' src={ imageUrl( avatar ) } className={ classes.avatar_sm } >{ initial } </Avatar>
}

function MADescription( { facet_id } ){
  const facet = useFacet( facet_id )
  const desc = facetDescription( facet )

  return <span>{ desc }</span>
}

function ALMenuAccount( { ...props } ) {
  const { t } = useTranslation()
  const user = useUser()
  const fids = userFacetIDs( user )
  const logout = useLogout()
  const facet = useFacetCurrentID( user )
  const setFacet = useFacetCurrentIDSet( user )

  const [ anchorEl, setAnchorEl ] = useState( null )
  const open = Boolean( anchorEl )

  function handleOpen( event ) {
    setAnchorEl( event.currentTarget )
  }

  function handleClose( event ) {
    setAnchorEl( null )
  }

  function handleClick( fid ) {
    if( fid ) setFacet( fid )
    handleClose()
  }

  return (
    <>
      <BottomNavigationAction { ...props } onClick={ handleOpen } />
      <Menu
        id='menu-account'
        anchorEl={ anchorEl }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={ open }
        onClose={ handleClose }
      >
        { fids.map ( ( fid,  idx ) => ( <MenuItem onClick={ () => handleClick( fid) } id={ fid } selected={ fid === facet } key={ idx }><ListItemIcon><MAAvatar facet_id={ fid } /></ListItemIcon><ListItemText><MADescription facet_id={ fid } /></ListItemText></MenuItem> ) ) }
        <Divider />
        <MenuItem onClick={ handleClose } component={ RouterLink } to='/account'>{ t( 'act.edit_settings' ) }</MenuItem>
        <MenuItem onClick={ logout } to='/account'>{ t( 'act.signout' ) }</MenuItem>
      </Menu>
     </>
  )
}

//

function ALNavTopBottom(props) {
  const auth = useIsAuthenticated()

  const [navValue, setNavValue] = React.useState('')

  const classes = useStylesNav()
  const [pathname, setPathname] = React.useState('')

  const mobile = useMobile()

  const location = useLocation()

  const alNavItems = useNavItems().filter( item => {
    if ( mobile && Boolean(item.bottom) !== Boolean(props.bottom) ) return false

    if ( item.auth > 0 && ! auth ) return false
    if ( item.auth < 0 &&   auth ) return false

    return true
  })

  if ( pathname !== location.pathname ) {
    setPathname(location.pathname)
    // TODO - Deal with /club/ID
    setNavValue('/'+ location.pathname.split('/')[1])
  }

  if ( ! mobile && props.bottom ) return ('')

  return (
    <BottomNavigation
    value={navValue}
    onChange={(event, newValue) => {
      if( newValue ) setNavValue(newValue);
    }}
    showLabels
    className={props.bottom ? classes.navBottom : classes.navTop}
    >
      { alNavItems.map( (elem, index) => {
        const linkProps = extractLinkProps({location, ...elem})
        const value = elem.to ? '/'+ elem.to.split('/')[1] : ''

        switch( elem.type ) {

          case 'Lang':
            return (
              <ALMenuLanguage {...linkProps} label={elem.primary} icon={elem.iconComponent} value='' key={index} className={classes.bottomNavigationAction} />
            )

          case 'ColorMode':
            return (
              <ALMenuColorMode { ...linkProps } label={ elem.primary } icon={ elem.iconComponent } value='' key={ index } className={ classes.bottomNavigationAction } />
            )

          case 'Account':
            return (
              <ALMenuAccount { ...linkProps } label={ elem.primary } icon={ elem.iconComponent } value='' key={ index } className={ classes.bottomNavigationAction } />
            )

          case 'Button':
            return (
              <BottomNavigationAction {...linkProps} label={elem.primary} disabled={ elem.disabled } icon={ elem.iconComponent}  value={ value } key={ index } className={classes.bottomNavigationAction} />
            )

          case 'Link':
          default:
            return (
              <BottomNavigationAction {...linkProps} label={elem.primary} disabled={ elem.disabled } icon={ elem.iconComponent } value={ value } key={ index}  className={classes.bottomNavigationAction} />
            )
        }
      } ) }
    </BottomNavigation>
  )
}

export default function ALNavbar() {
  return (
    <>
      <Container maxWidth='lg'>
        <ALNavTopBottom bottom={false} />
      </Container>
      <ALNavTopBottom bottom={true} />
    </>
  )
  //<ALNavBottom />
}
