//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useParams,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Typography,
  styled,
} from '@mui/material'

import {
  ExpandMore as ExpandMoreIcon,
  LockOutlined as LockedIcon,
  VisibilityOffOutlined as HiddenIcon,
} from '@mui/icons-material'

import {
  rsIsLoading,
  rsIsError,
} from '../../remote-state/core'

import {
  capabilities,
  checkCapabilities,
} from '../../remote-state/authorization'

import {
  useCommunity,
  communityName,
  communityHead,
  communityDesc,
  communityMemCnt,
  communityLeaCnt,
  communityAdmCnt,
  communityArchived,

  communityGroups,
  communityGroupID,
  communityGroupName,
  communityGroupHead,
  communityGroupDesc,
  communityGroupRoleCnt,
  communityGroupVisibility,

  useCommunityJoin,
  useCommunityLeave,
  communityAuthz,

  ROLE_MEM,
  ROLE_GRP,
  ROLE_LEA,
  ROLE_ADM,
} from '../../remote-state/communities'

import {
  ALEditor,
} from '../../components/ALDraftJS'

import {
  ALMain,
} from '../../components/ALMain'

import ALLoading from '../../components/ALLoading'

//

const ChipMbs = styled( Box ) ( ( {  color, theme } ) => ( {
  marginLeft : '0.25rem',
  borderRadius : '0.25rem',
  display : 'inline',
  padding : '0 0.25rem 0 0.25rem',
  color : theme.palette[ color ].contrastText,
  backgroundColor : theme.palette[ color ].light,
  whiteSpace : 'nowrap',
  fontWeight : 'normal',
} ) )

//

export default function ALMainCIDAbout() {
  const { t, i18n } = useTranslation()
  const { cid } = useParams()

  const langs = i18n.languages

  const cmty = useCommunity( cid )
  const cAuthz = communityAuthz( cmty )
  const mayJoin = checkCapabilities( cAuthz, capabilities.join )
  const mayLeave = checkCapabilities( cAuthz, capabilities.leave )

  const communityJoin = useCommunityJoin( cmty )
  const communityLeave = useCommunityLeave( cmty )

  const name = communityName( cmty, [ i18n.language ] )
  const head = communityHead( cmty, langs )
  const desc = communityDesc( cmty, langs )

  const mem_cnt = communityMemCnt( cmty )
  const lea_cnt = communityLeaCnt( cmty )
  const adm_cnt = communityAdmCnt( cmty )

  const groups = communityGroups( cmty )

  if( rsIsLoading( cmty ) ) return ( <ALMain><ALLoading /></ALMain>)
  if( rsIsError( cmty ) ) return ( <ALMain><p>t('err.error')</p></ALMain>)

  return (
    <ALMain title={ name } nocard alerts={ communityArchived( cmty ) ? [ { severity : 'warning', message: t( `mod.community.archived_${ communityArchived( cmty ) }` ) } ] : null } >
      <Box>
        <Accordion defaultExpanded={ Boolean( desc ) } >
          <AccordionSummary
            expandIcon={ Boolean( desc ) ? <ExpandMoreIcon /> : undefined }
            aria-controls="community-info"
            id={ cid }
          >
            <Box sx={{ width : '100%' }}>
              <Box>
                <Typography variant='h6'>{ name }</Typography>
              </Box>
              <Box>
                <Typography>{ head }</Typography>
              </Box>
              <Box>
                <ChipMbs color='info'>{ mem_cnt } { t( 'rec.community.mem', { count : mem_cnt } ) }</ChipMbs>
                <ChipMbs color='info'>{ lea_cnt } { t( 'rec.community.lea', { count : lea_cnt } ) }</ChipMbs>
                <ChipMbs color='info'>{ adm_cnt } { t( 'rec.community.adm', { count : adm_cnt } ) }</ChipMbs>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            { Boolean( desc ) && (
              <>
                <Typography sx={{ fontWeight: 'bold' }}>{ t('rec.community.desc' ) }</Typography>
                <ALEditor value={ desc } />
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box sx={{ mt: '1rem' }}>
        <Typography variant='h6'>{ t( 'mod.community.admin.group', { count: 2 } ) }</Typography>

        { groups.map( group => {
          const group_id = communityGroupID( group )
          const name = communityGroupName( group )
          const head = communityGroupHead( group )
          const desc = communityGroupDesc( group )
          const mem_cnt = communityGroupRoleCnt( group, ROLE_MEM )
          const lea_cnt = communityGroupRoleCnt( group, ROLE_LEA )
          const adm_cnt = communityGroupRoleCnt( group, ROLE_ADM )
          const vis = communityGroupVisibility( group )
          const icon = vis === 'hidden'
            ? <HiddenIcon fontSize='small' sx={{ ml : '0.5rem' }} />
            : vis === 'private'
              ? <LockedIcon fontSize='small' sx={{ ml : '0.5rem' }} />
              : <></>

          return(
            <Accordion key={ group_id } >
              <AccordionSummary
                expandIcon={ Boolean( desc ) ? <ExpandMoreIcon /> : undefined }
                aria-controls="community-info"
                id={ cid }
              >
                <Box sx={{ width : '100%' }}>
                  <Box display='flex' flexWrap='nowrap' alignItems='center'>
                    <Typography variant='h6'>{ name }</Typography>{ icon }
                  </Box>
                  <Box>
                    <Typography>{ head }</Typography>
                  </Box>
                  <Box>
                    { Boolean( mem_cnt ) && <ChipMbs color='secondary'>{ mem_cnt } { t( `role.${ ROLE_GRP }`, { count : mem_cnt } ) }</ChipMbs> }
                    { Boolean( lea_cnt ) && <ChipMbs color='secondary'>{ lea_cnt } { t( `role.g_${ ROLE_LEA }`, { count : lea_cnt } ) }</ChipMbs> }
                    { Boolean( adm_cnt ) && <ChipMbs color='secondary'>{ adm_cnt } { t( `role.g_${ ROLE_ADM }`, { count : adm_cnt } ) }</ChipMbs> }
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                { Boolean( desc ) && (
                  <>
                    <Typography sx={{ fontWeight: 'bold' }}>{ t('rec.community.desc' ) }</Typography>
                    <ALEditor value={ desc } />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          )
        } ) }
      </Box>

      { ( mayLeave || mayJoin ) &&
        <Box sx={{ mt: '1rem' }}>
          {   mayLeave && (
            <div>
              <Button
                size='small'
                color='primary'
                variant='outlined'
                onClick={ () => { communityLeave.mutate( {} ) } }
                >
                { t( 'act.leave' ) } { t( 'mod.community.name' ) }
              </Button>
            </div>
          ) }
          { mayJoin && (
            <div>
              <Button
                size='small'
                color='primary'
                variant='contained'
                onClick={ () => { communityJoin.mutate() } }
                >
                { t( 'act.join' ) } { t( 'mod.community.name' ) }
              </Button>
            </div>
          ) }
        </Box>
      }
    </ALMain>
  )
}
