//
// Copyright 2022 Avvia Life, All Rights Reserved
//

class ALError extends Error {
  constructor( message, data = {} ) {
    super( message )
    this.name = "ALError"
    this.code = 400
    this.data = data
  }
}

export {
  ALError,
}