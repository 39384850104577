//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useParams,
  Navigate,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'

import {
  useLangs,
  useLangCurrent,
} from '../../components/ALI18N'

import {
  rsIsError,
  rsIsLoading,
} from '../../remote-state/core'

import {
  useUserReload,
} from '../../remote-state/users'

import {
  useFacet,
  useFacetReload,

  facetID,
  facetName,
  facetNameR,
  facetDescription,
  facetVisibilityLevel,
  facetInitials_1,
  facetInitials_1R,
  facetInitials_2,
  facetInitials_2R,
  facetPronouns,
  facetPronounsR,
  facetAvatar,
} from '../../remote-state/facets'

import {
  useImage,
  imageUrl,
} from '../../remote-state/images'

import {
  ALMain,
} from '../../components/ALMain'

import {
  fieldRequired,
  fieldChanged,
  useFieldStringMax,
  ALFormTextField,
  ALFormImage,
} from '../../components/form/ALForm'

import {
  ALSettings,
} from '../../components/ALSettings'

import ALLoading from '../../components/ALLoading'

//

export default function ALMainFacet() {
  const { t } = useTranslation()
  const { f_id } = useParams()
  const facetCur = useFacet()
  const fid = f_id || facetID( facetCur )
  const facet = useFacet( fid )
  const avatarID_L = facetAvatar( facet, 'light' )
  const avatarUrl_L = imageUrl( useImage( avatarID_L ) )
  const avatarID_D = facetAvatar( facet, 'dark' )
  const avatarUrl_D = imageUrl( useImage( avatarID_D ) )
  const reloadUser = useUserReload()
  const reloadFacet = useFacetReload( fid )
  const fieldMax64 = useFieldStringMax( 64 )
  const fieldMax24 = useFieldStringMax( 24 )
  const fieldMax2 = useFieldStringMax( 2 )
  const fieldMax1 = useFieldStringMax( 1 )
  const onSuccess = () => { reloadUser(); reloadFacet() }
  const visOptions = t( 'rec.facet.visibility-options', { returnObjects: true } )
  const visLevel = facetVisibilityLevel( facet )
  const vis = visOptions.find( elem => elem.value === visLevel ) || {}

  const { code } = useLangCurrent()
  const langs = useLangs()
  const ls = langs.map( elem => ( { value: elem.code, label : elem.nativeName } ) )

  if( rsIsLoading( facetCur ) || rsIsLoading( facet ) ) {
    return (
      <ALMain title='Account - Facet' >
        <ALLoading />
      </ALMain>
    )
  }

  if( rsIsError( facet ) ) { return <Navigate to='/account/facets' /> }

  const settings = [
    {
      valueonly : true,
      label : t( 'rec.facet.id' ),
      margin: 'none',
      value : fid,
      elems : [],
    },

    {
      valueonly : true,
      margin: 'none',
      value: ( <Divider/>),
      elems : [],
    },

    {
      label : t( 'rec.facet.description' ),
      margin: 'none',
      value : facetDescription( facet ),
      service : 'api/facets',
      oid : fid,
      op : 'description',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'description', value: facetDescription( facet ), type: 'text', check: [ fieldRequired, fieldChanged, fieldMax24 ] },
      ],
    },

    {
      label : t( 'rec.facet.visibility' ),
      margin: 'none',
      value : vis.label,
      service : 'api/facets',
      oid : fid,
      op : 'visibility_level',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'level', value:  visLevel, select: true, check: [ fieldRequired, fieldChanged ], options : [ ...( visOptions.map( item => ( { value : item.value, label : item.label, disabled : item.disabled } ) ) ) ] },
      ],
    },

    {
      valueonly : true,
      margin: 'none',
      value: ( <Divider/>),
      elems : [],
    },

    {
      label : t( 'rec.facet.avatar' ),
      margin: 'none',
      value : <Avatar variant='rounded' src={ avatarUrl_L } />,
      service : 'api/facets',
      oid : fid,
      op : 'avatar',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormImage, id: 'avatar_id', value: avatarID_L, role: 'avatar', check : [ fieldChanged ] },
      ],
    },

    {
      label : t( 'rec.facet.avatar_dm' ),
      margin: 'none',
      value : <Avatar variant='rounded' src={ avatarUrl_D } />,
      service : 'api/facets',
      oid : fid,
      op : 'avatar_dark',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormImage, id: 'avatar_id', value: avatarID_D, role: 'avatar', check : [ fieldChanged ] },
      ],
    },

    {
      label : t( 'rec.facet.name' ),
      margin: 'none',
      value : facetName( facet, [ code ], true ),
      service : 'api/facets',
      oid : fid,
      op : 'name',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'language', local : true, name : t('rec.community.lang'), check: [ fieldRequired ], value: code, select: true, options : [ { value: '', label: t( 'act.sellang' ), disabled : true }, ...ls ] },
        { Comp: ALFormTextField, id: 'name', sub : 'language', value: facetNameR( facet ), type: 'text', check: [ fieldRequired, fieldMax64, fieldChanged ] },
      ],
    },

    {
      label : t( 'rec.facet.init' ),
      margin: 'none',
      value : facetInitials_1( facet, [ code ], true ),
      service : 'api/facets',
      oid : fid,
      op : 'initials_1',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'language', local : true, name : t('rec.community.lang'), check: [ fieldRequired ], value: code, select: true, options : [ { value: '', label: t( 'act.sellang' ), disabled : true }, ...ls ] },
        { Comp: ALFormTextField, id: 'initials_1', sub : 'language', value: facetInitials_1R( facet ), type: 'text', check: [ fieldRequired, fieldMax1, fieldChanged ] },
      ],
    },

    {
      label : t( 'rec.facet.inits' ),
      margin: 'none',
      value : facetInitials_2( facet, [ code ], true ),
      service : 'api/facets',
      oid : fid,
      op : 'initials_2',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'language', local : true, name : t('rec.community.lang'), check: [ fieldRequired ], value: code, select: true, options : [ { value: '', label: t( 'act.sellang' ), disabled : true }, ...ls ] },
        { Comp: ALFormTextField, id: 'initials_2', sub : 'language', value: facetInitials_2R( facet ), type: 'text', check: [ fieldRequired, fieldMax2, fieldChanged ] },
      ],
    },

    {
      label : t( 'rec.facet.pro' ),
      margin: 'none',
      value : facetPronouns( facet, [ code ], true ),
      service : 'api/facets',
      oid : fid,
      op : 'pronouns',
      onSuccess : onSuccess,
      elems : [
        { Comp: ALFormTextField, id: 'language', local : true, name : t('rec.community.lang'), check: [ fieldRequired ], value: code, select: true, options : [ { value: '', label: t( 'act.sellang' ), disabled : true }, ...ls ] },
        { Comp: ALFormTextField, id: 'pronouns', sub : 'language', value: facetPronounsR( facet ),  select: true, check: [ fieldChanged, fieldMax64 ], options : t( 'mod.account.pro_a', { returnObjects: true } ).map( item => ( { value : item.value, label : item.label, disabled : item.disabled } ) ) },

      ],
    },
  ]

  return (
    <ALMain title='Account - Facet' backTo='../..' >
      <ALSettings items={settings} />
    </ALMain>
  )
}