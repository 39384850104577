//
// Copyright 2022 Avvia Life, All Rights Reserved
//

import {
  useEffect,
} from 'react'

import {
  useStoreValue,
  useSetStoreValue,
  store,
} from 'react-context-hook'

// ----

const { sessionStorage } = global

// -----

function useSessionStoreValue( key, defaultvalue ) {
  const set = useSessionStoreSet( key )

  const value = useStoreValue( [ key ] )
  const stored = sessionStorage.getItem( key, defaultvalue )
  const result = value || stored || defaultvalue

  useEffect( () => {
    if( stored == null && result != null ) set( result )
  }, [ stored, result, set ] )

  return result
}

function useSessionStoreSet( key ) {
  const set = useSetStoreValue( [ key ] )
  return ( value ) => { sessionStorage.setItem( key, value ); set( value ) }
}

function sessionStoreDelete( key ) {
  store.delete( [ key ] )
  sessionStorage.removeItem( key )
}

export {
  useSessionStoreValue,
  useSessionStoreSet,
  sessionStoreDelete,
}